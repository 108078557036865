import {$http} from '@/utils/http'

import {
  DELIVERY_GROUP_CHANGE_LOADING
} from '../mutation-types';

import {
  DELIVERY_GROUP_REQUEST_UPDATE,
  DELIVERY_GROUP_REQUEST_DELETE,
} from '../action-types';

const state = {
  isDeliveryGroupChangeLoading: false
};

const getters = {
  isDeliveryGroupChangeLoading: state => state.isDeliveryGroupChangeLoading,
};

const actions = {
  [DELIVERY_GROUP_REQUEST_UPDATE]: async ({commit}, payload) => {
    try {
      commit(DELIVERY_GROUP_CHANGE_LOADING, true);
      return await $http.post(`delivery_groups`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_GROUP_CHANGE_LOADING, false);
    }
  },

  [DELIVERY_GROUP_REQUEST_DELETE]: async ({commit}, payload) => {
    try {
      commit(DELIVERY_GROUP_CHANGE_LOADING, true);
      return await $http.delete(`delivery_groups`, {
        headers: {
          'Accept': 'application/x-www-form-urlencoded'
        },
        data: payload
      });
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_GROUP_CHANGE_LOADING, false);
    }
  },
};

const mutations = {
  [DELIVERY_GROUP_CHANGE_LOADING](state, flag) {
    state.isDeliveryGroupChangeLoading = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
