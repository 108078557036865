import 'vuetify/dist/vuetify.min.css'
//---utils
import moment from 'moment';
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
import './utils/filters.js'
import {vuetifyOptions} from './utils/vuetify-options'

//---moment
Vue.prototype.moment = moment

import Vue from 'vue'
import Vuetify from 'vuetify'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//---set auth status
if (localStorage.getItem('access_token')) {
  store.commit(`auth/AUTH_SET_TOKEN`, localStorage.getItem('access_token'));
}

Vue.use(Vuetify);
let vuetify = new Vuetify(vuetifyOptions);

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});
