import {$http} from '@/utils/http'

import {findIndex, orderBy} from "lodash";

import {
  WEIGHTS_LIST,
  WEIGHTS_LIST_LOADING,

  WEIGHTS_DATA,
  WEIGHTS_DATA_LOADING,

  WEIGHTS_DATA_UPDATE_LOADING,
  WEIGHTS_DATA_UPDATE,
  WEIGHTS_DATA_CREATE_LOADING,
  WEIGHTS_DATA_CREATE,
  WEIGHTS_DATA_DELETE,
} from '../mutation-types';

import {
  WEIGHTS_REQUEST_LIST_GET,
  WEIGHTS_REQUEST_DATA_GET,
  WEIGHTS_REQUEST_UPDATE,
  WEIGHTS_REQUEST_CREATE,
  WEIGHTS_REQUEST_DELETE,
} from '../action-types';
import Vue from "vue";


const state = {
  weights: {
    list: [],
    isLoading: false,
  },
  weight: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  weights: state => state.weights.list,
  isWeightsLoading: state => state.weights.isLoading,

  weight: state => state.weight.data,
  isWeightLoading: state => state.weight.isLoading,
  isWeightUpdating: state => state.weight.isUpdating,
  isWeightCreating: state => state.weight.isCreating,
};

const actions = {
  [WEIGHTS_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(WEIGHTS_LIST_LOADING, true);
      const response = await $http.get(`weights`);
      commit(WEIGHTS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(WEIGHTS_LIST_LOADING, false);
    }
  },


  [WEIGHTS_REQUEST_DATA_GET]: async ({commit}, weightId) => {
    try {
      commit(WEIGHTS_DATA_LOADING, true);
      const response = await $http.get(`weights/${weightId}`);
      commit(WEIGHTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(WEIGHTS_DATA_LOADING, false);
    }
  },

  [WEIGHTS_REQUEST_UPDATE]: async ({commit}, {weightId, payload}) => {
    try {
      commit(WEIGHTS_DATA_UPDATE_LOADING, true);
      const response = await $http.put(`weights/${weightId}`, payload);
      commit(WEIGHTS_DATA_UPDATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(WEIGHTS_DATA_UPDATE_LOADING, false);
    }
  },

  [WEIGHTS_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(WEIGHTS_DATA_CREATE_LOADING, true);
      const response = await $http.post(`weights`, payload);
      commit(WEIGHTS_DATA_CREATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(WEIGHTS_DATA_CREATE_LOADING, false);
    }
  },

  [WEIGHTS_REQUEST_DELETE]: async ({commit}, weightId) => {
    try {
      await $http.delete(`weights/${weightId}`);
      commit(WEIGHTS_DATA_DELETE, weightId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [WEIGHTS_LIST](state, data) {
    state.weights.list = orderBy(data, ['value'], ['asc']);
  },
  [WEIGHTS_LIST_LOADING](state, flag) {
    state.weights.isLoading = flag;
  },

  [WEIGHTS_DATA](state, data) {
    state.weight.data = data;
  },
  [WEIGHTS_DATA_LOADING](state, flag) {
    state.weight.isLoading = flag;
  },
  [WEIGHTS_DATA_UPDATE_LOADING](state, flag) {
    state.weight.isUpdating = flag;
  },
  [WEIGHTS_DATA_UPDATE](state, data) {
    state.weight.data = data;
    let index = findIndex(state.weights.list, item => {return item.id === data.id;});
    Vue.set(state.weights.list, index, data);
  },
  [WEIGHTS_DATA_CREATE_LOADING](state, flag) {
    state.weight.isCreating = flag;
  },
  [WEIGHTS_DATA_CREATE](state, data) {
    let list = state.weights.list.push(data)
    state.weights.list = orderBy(list, ['value'], ['asc']);
  },
  [WEIGHTS_DATA_DELETE](state, id) {
    let index = findIndex(state.weights.list, item => {return item.id === id;});
    state.weights.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
