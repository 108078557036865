import {$http} from '@/utils/http'
import {isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  SETTINGS_LIST,
  SETTINGS_LIST_LOADING,
  SETTINGS_DATA,
  SETTINGS_DATA_LOADING,
  SETTINGS_DATA_UPDATE_LOADING,
} from '../mutation-types';

import {
  SETTINGS_REQUEST_LIST_GET,
  SETTINGS_REQUEST_DATA_GET,
  SETTINGS_REQUEST_UPDATE,
} from '../action-types';

const state = {
  settings: {
    list: null,
    isLoading: false
  },
  setting: {
    data: null,
    isLoading: false,
    isUpdating: false,
  },
};

const getters = {
  settings: state => state.settings.list,
  isSettingsLoading: state => state.settings.isLoading,
  setting: state => state.setting.data,
  isSettingLoading: state => state.setting.isLoading,
  isSettingUpdating: state => state.setting.isUpdating,
};

const actions = {
  [SETTINGS_REQUEST_LIST_GET]: async ({commit},params) => {
    try {
      commit(SETTINGS_LIST_LOADING, true);
      let include = 'translations';

      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`settings?include=${include}${query}`);
      commit(SETTINGS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SETTINGS_LIST_LOADING, false);
    }
  },

  [SETTINGS_REQUEST_DATA_GET]: async ({commit}, settingId) => {
    try {
      commit(SETTINGS_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`settings/${settingId}?include=${include}`);
      commit(SETTINGS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SETTINGS_DATA_LOADING, false);
    }
  },

  [SETTINGS_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(SETTINGS_DATA_UPDATE_LOADING, true);
      return await $http.put(`settings/${data.settingId}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SETTINGS_DATA_UPDATE_LOADING, false);
    }
  },
};

const mutations = {

  [SETTINGS_LIST](state, data) {
    state.settings.list = data;
  },
  [SETTINGS_LIST_LOADING](state, flag) {
    state.settings.isLoading = flag;
  },
  [SETTINGS_DATA](state, data) {
    state.setting.data = data;
  },
  [SETTINGS_DATA_LOADING](state, flag) {
    state.setting.isLoading = flag;
  },
  [SETTINGS_DATA_UPDATE_LOADING](state, flag) {
    state.setting.isUpdating = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
