import {$http} from '@/utils/http'
import moment from "moment";
import {CONSTANTS} from '@/utils/constants';

import {
  CONSIGNMENTS_EXPIRATING_LIST,
  CONSIGNMENTS_EXPIRATING_LIST_LOADING,
  CONSIGNMENTS_EXPIRATING_LIST_PAGE,
  CONSIGNMENTS_EXPIRATING_LIST_PAGE_LENGTH,

  CONSIGNMENTS_EXPIRATED_LIST,
  CONSIGNMENTS_EXPIRATED_LIST_LOADING,
  CONSIGNMENTS_EXPIRATED_LIST_PAGE,
  CONSIGNMENTS_EXPIRATED_LIST_PAGE_LENGTH,

  CONSIGNMENTS_DATA,
  CONSIGNMENTS_DATA_LOADING,
  CONSIGNMENTS_DATA_UPDATING,
} from '../mutation-types';

import {
  CONSIGNMENTS_REQUEST_EXPIRATING_LIST_GET,
  CONSIGNMENTS_REQUEST_EXPIRATED_LIST_GET,
  CONSIGNMENTS_REQUEST_DATA_GET,
  CONSIGNMENTS_REQUEST_DATA_UPDATE
} from '../action-types';
import Vue from "vue";

const state = {
  consignmentsExpirating: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
  },
  consignmentsExpirated: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
  },
  consignment: {
    data: null,
    isLoading: false,
    isUpdating: false
  },
};

const getters = {
  consignmentsExpirating: state => state.consignmentsExpirating.list,
  isConsignmentsExpiratingLoading: state => state.consignmentsExpirating.isLoading,
  consignmentsExpiratingPage: state => state.consignmentsExpirating.page,
  consignmentsExpiratingPageLength: state => state.consignmentsExpirating.pageLength,

  consignmentsExpirated: state => state.consignmentsExpirated.list,
  isConsignmentsExpiratedLoading: state => state.consignmentsExpirated.isLoading,
  consignmentsExpiratedPage: state => state.consignmentsExpirated.page,
  consignmentsExpiratedPageLength: state => state.consignmentsExpirated.pageLength,

  consignment: state => state.consignment.data,
  isConsignmentLoading: state => state.consignment.isLoading,
  isConsignmentUpdating: state => state.consignment.isUpdating,
};

const actions = {
  [CONSIGNMENTS_REQUEST_EXPIRATING_LIST_GET]: async ({commit}, params ) => {
    try {
      commit(CONSIGNMENTS_EXPIRATING_LIST_LOADING, true);
      commit(CONSIGNMENTS_EXPIRATING_LIST_PAGE, params.page);

      let include = 'places,products,vendors,categories';
      const response = await $http.get(`consignments?include=${include}&expiration_date=1&per_page=${state.consignmentsExpirating.per_page}&page=${state.consignmentsExpirating.page}`);

      commit(CONSIGNMENTS_EXPIRATING_LIST, response.data.data);
      commit(CONSIGNMENTS_EXPIRATING_LIST_PAGE_LENGTH, response.data.meta.last_page);

      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CONSIGNMENTS_EXPIRATING_LIST_LOADING, false);
    }
  },

  [CONSIGNMENTS_REQUEST_EXPIRATED_LIST_GET]: async ({commit}, params ) => {
    try {
      commit(CONSIGNMENTS_EXPIRATED_LIST_LOADING, true);
      commit(CONSIGNMENTS_EXPIRATED_LIST_PAGE, params.page);

      let include = 'places,products,vendors,categories';
      const response = await $http.get(`consignments?include=${include}&expiration_date_end=1&per_page=${state.consignmentsExpirated.per_page}&page=${state.consignmentsExpirated.page}`);

      commit(CONSIGNMENTS_EXPIRATED_LIST, response.data.data);
      commit(CONSIGNMENTS_EXPIRATED_LIST_PAGE_LENGTH, response.data.meta.last_page);

      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CONSIGNMENTS_EXPIRATED_LIST_LOADING, false);
    }
  },

  [CONSIGNMENTS_REQUEST_DATA_GET]: async ({commit}, consignmentId) => {
    try {
      commit(CONSIGNMENTS_DATA_LOADING, true);
      let include = 'places,products,categories';
      const response = await $http.get(`consignments/${consignmentId}?include=${include}`);
      commit(CONSIGNMENTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CONSIGNMENTS_DATA_LOADING, false);
    }
  },

  [CONSIGNMENTS_REQUEST_DATA_UPDATE]: async ({commit}, {consignmentId, payload}) => {
    try {
      commit(CONSIGNMENTS_DATA_LOADING, true);
      let include = 'categories,vendors,consignments,places';
      const response = await $http.put(`consignments/${consignmentId}?include=${include}`, payload);
      commit(CONSIGNMENTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CONSIGNMENTS_DATA_UPDATING, false);
    }
  },
};

const mutations = {
  [CONSIGNMENTS_EXPIRATING_LIST](state, list) {
    if (list.length > 0) {
      list.forEach((item, index) => {
        let expirationData = {
          expirationLeft: null,
          isExpirationWarning: null
        }
        if (item.expiration_date && item.expiration_date.length > 0) {
          expirationData.expirationLeft = moment(item.expiration_date).diff(moment(), 'days');
          expirationData.isExpirationWarning = item.expirationLeft <= CONSTANTS.APP_CONSIGNMENTS_EXPIRATION_LEFT;
        }
        Vue.set(list, index, Object.assign({}, item, expirationData));
      })
    }
    state.consignmentsExpirating.list = list;
  },
  [CONSIGNMENTS_EXPIRATING_LIST_LOADING](state, flag) {
    state.consignmentsExpirating.isLoading = flag;
  },
  [CONSIGNMENTS_EXPIRATING_LIST_PAGE](state, number) {
    state.consignmentsExpirating.page = parseInt(number);
  },
  [CONSIGNMENTS_EXPIRATING_LIST_PAGE_LENGTH](state, count) {
    state.consignmentsExpirating.pageLength = count;
  },

  [CONSIGNMENTS_EXPIRATED_LIST](state, list) {
    state.consignmentsExpirated.list = list;
  },
  [CONSIGNMENTS_EXPIRATED_LIST_LOADING](state, flag) {
    state.consignmentsExpirated.isLoading = flag;
  },
  [CONSIGNMENTS_EXPIRATED_LIST_PAGE](state, number) {
    state.consignmentsExpirated.page = parseInt(number);
  },
  [CONSIGNMENTS_EXPIRATED_LIST_PAGE_LENGTH](state, count) {
    state.consignmentsExpirated.pageLength = count;
  },

  [CONSIGNMENTS_DATA](state, data) {
    state.consignment.data = data;
  },
  [CONSIGNMENTS_DATA_LOADING](state, flag) {
    state.consignment.isLoading = flag;
  },

  [CONSIGNMENTS_DATA_UPDATING](state, flag) {
    state.consignment.isUpdating = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};