import {$http} from '@/utils/http'
import {findIndex} from "lodash";
import Vue from "vue";

import {
  COUNTRY_GROUP_LIST,
  COUNTRY_GROUP_LIST_LOADING,

  COUNTRY_GROUP_DATA,
  COUNTRY_GROUP_DATA_LOADING,

  COUNTRY_GROUP_DATA_UPDATE_LOADING,
  COUNTRY_GROUP_DATA_UPDATE,
  COUNTRY_GROUP_DATA_CREATE_LOADING,
  COUNTRY_GROUP_DATA_CREATE,
  COUNTRY_GROUP_DATA_DELETE,
} from '../mutation-types';

import {
  COUNTRY_GROUP_REQUEST_LIST_GET,
  COUNTRY_GROUP_REQUEST_DATA_GET,
  COUNTRY_GROUP_REQUEST_UPDATE,
  COUNTRY_GROUP_REQUEST_CREATE,
  COUNTRY_GROUP_REQUEST_DELETE,
} from '../action-types';


const state = {
  countryGroups: {
    list: [],
    isLoading: false,
  },

  countryGroup: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  countryGroups: state => state.countryGroups.list,
  isCountryGroupsLoading: state => state.countryGroups.isLoading,

  countryGroup: state => state.countryGroup.data,
  isCountryGroupLoading: state => state.countryGroup.isLoading,
  isCountryGroupUpdating: state => state.countryGroup.isUpdating,
  isCountryGroupCreating: state => state.countryGroup.isCreating,
};

const actions = {
  [COUNTRY_GROUP_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(COUNTRY_GROUP_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`country_groups?include=${include}`);
      commit(COUNTRY_GROUP_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_GROUP_LIST_LOADING, false);
    }
  },

  [COUNTRY_GROUP_REQUEST_DATA_GET]: async ({commit}, countryGroupId) => {
    try {
      commit(COUNTRY_GROUP_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`country_groups/${countryGroupId}?include=${include}`);
      commit(COUNTRY_GROUP_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_GROUP_DATA_LOADING, false);
    }
  },

  [COUNTRY_GROUP_REQUEST_UPDATE]: async ({commit}, {countryGroupId, payload}) => {
    try {
      commit(COUNTRY_GROUP_DATA_UPDATE_LOADING, true);
      let include = 'translations';
      const response = await $http.put(`country_groups/${countryGroupId}?include=${include}`, payload);
      commit(COUNTRY_GROUP_DATA_UPDATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_GROUP_DATA_UPDATE_LOADING, false);
    }
  },

  [COUNTRY_GROUP_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(COUNTRY_GROUP_DATA_CREATE_LOADING, true);
      let include = 'translations';
      const response = await $http.post(`country_groups?include=${include}`, payload);
      commit(COUNTRY_GROUP_DATA_CREATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_GROUP_DATA_CREATE_LOADING, false);
    }
  },

  [COUNTRY_GROUP_REQUEST_DELETE]: async ({commit}, countryGroupId) => {
    try {
      await $http.delete(`country_groups/${countryGroupId}`);
      commit(COUNTRY_GROUP_DATA_DELETE, countryGroupId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [COUNTRY_GROUP_LIST](state, data) {
    state.countryGroups.list = data;
  },
  [COUNTRY_GROUP_LIST_LOADING](state, flag) {
    state.countryGroups.isLoading = flag;
  },

  [COUNTRY_GROUP_DATA](state, data) {
    state.countryGroup.data = data;
  },
  [COUNTRY_GROUP_DATA_LOADING](state, flag) {
    state.countryGroup.isLoading = flag;
  },
  [COUNTRY_GROUP_DATA_UPDATE_LOADING](state, flag) {
    state.countryGroup.isUpdating = flag;
  },
  [COUNTRY_GROUP_DATA_UPDATE](state, data) {
    state.countryGroup.data = data;
    let index = findIndex(state.countryGroups.list, item => {return item.id === data.id;});
    Vue.set(state.countryGroups.list, index, data);
  },
  [COUNTRY_GROUP_DATA_CREATE_LOADING](state, flag) {
    state.countryGroup.isCreating = flag;
  },
  [COUNTRY_GROUP_DATA_CREATE](state, data) {
    state.countryGroups.list.push(data);
  },
  [COUNTRY_GROUP_DATA_DELETE](state, id) {
    let index = findIndex(state.countryGroups.list, item => {return item.id === id;});
    state.countryGroups.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
