import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from "@/utils/utils";
import {isEmpty} from "lodash";

import {
  SHELVES_LIST,
  SHELVES_DATA,
  SHELVES_DATA_LOADING,
  SHELVES_DATA_UPDATE_LOADING,
  SHELVES_CREATE_LOADING,
  SHELVES_CREATE_LIST,
} from '../mutation-types';

import {
  SHELVES_REQUEST_LIST_GET,
  SHELVES_REQUEST_DATA_GET,
  SHELVES_REQUEST_UPDATE,
  SHELVES_REQUEST_CREATE,
  SHELVES_REQUEST_DELETE
} from '../action-types';

const state = {
  shelves: {
    list: null,
    isCreating: false,
    skip: 10
  },
  shelf: {
    data: null,
    isLoading: false,
    isUpdating: false
  },
  shelvesCreate: {
    isLoading: false,
    list: null
  }
};

const getters = {
  shelves: state => state.shelves.list,
  shelvesSkip: state => state.shelves.skip,
  shelf: state => state.shelf.data,
  isShelfLoading: state => state.shelf.isLoading,
  isShelfUpdating: state => state.shelf.isUpdating,
  isShelvesCreateLoading: state => state.shelvesCreate.isLoading,
  shelvesCreated: state => state.shelvesCreate.list
};

const actions = {
  [SHELVES_REQUEST_LIST_GET]: async ({commit}, params) => {
    try {
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      let include = 'translations,shelf_places,child_count,rack,store';
      const response = await $http.get(`shelves?include=${include}${query}`);
      commit(SHELVES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [SHELVES_REQUEST_DATA_GET]: async ({commit}, shelfId) => {
    try {
      commit(SHELVES_DATA_LOADING, true);
      let include = 'translations,child_count,rack,store';
      const response = await $http.get(`shelves/${shelfId}?include=${include}`);
      commit(SHELVES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SHELVES_DATA_LOADING, false);
    }
  },

  [SHELVES_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(SHELVES_DATA_UPDATE_LOADING, true);
      let include = 'translations,child_count,rack,store';
      const response = await $http.put(`shelves/${data.shelfId}?include=${include}`, data.payload);
      commit(SHELVES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SHELVES_DATA_UPDATE_LOADING, false);
    }
  },

  [SHELVES_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(SHELVES_CREATE_LOADING, true);
      let include = 'translations,rack,store';
      const response = await $http.post(`shelves?include=${include}`, payload);
      commit(SHELVES_CREATE_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SHELVES_CREATE_LOADING, false);
    }
  },

  [SHELVES_REQUEST_DELETE]: async ({commit}, shelfId) => {
    try {
      return await $http.delete(`shelves/${shelfId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [SHELVES_LIST](state, data) {
    state.shelves.list = data;
  },
  [SHELVES_DATA](state, data) {
    state.shelf.data = data;
  },
  [SHELVES_DATA_LOADING](state, flag) {
    state.shelf.isLoading = flag;
  },
  [SHELVES_DATA_UPDATE_LOADING](state, flag) {
    state.shelf.isUpdating = flag;
  },
  [SHELVES_CREATE_LOADING](state, flag) {
    state.shelvesCreate.isLoading = flag;
  },
  [SHELVES_CREATE_LIST](state, list) {
    state.shelvesCreate.list = list;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
