import {$http} from '@/utils/http'

import {isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  LOGS_LIST,
  LOGS_LIST_LOADING,
  LOGS_FILTERS,
} from '../mutation-types';

import {
  LOGS_REQUEST_LIST_GET,
  LOGS_REQUEST_FILTERS_GET,
} from '../action-types';

const state = {
  logs: {
    list: [],
    per_page: 10,
    pageLength: 1,
    isLoading: false,
  },
  logsFilters: {
    data: null,
  },
};

const getters = {
  logs: state => state.logs.list,
  logsPageLength: state => state.logs.pageLength,
  isLogsLoading: state => state.logs.isLoading,
  logsFilters: state => state.logsFilters.data,
};

const actions = {
  [LOGS_REQUEST_LIST_GET]: async ({commit}, params) => {
    try {
      commit(LOGS_LIST_LOADING, true);
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`service_logs?per_page=${state.logs.per_page}${query}`);
      commit(LOGS_LIST, response.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOGS_LIST_LOADING, false);
    }
  },

  [LOGS_REQUEST_FILTERS_GET]: async ({commit}) => {
    try {
      const response = await $http.get(`service_logs/filter`);
      commit(LOGS_FILTERS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

};

const mutations = {
  [LOGS_LIST](state, data) {
    state.logs.list = data.data ? data.data : [];
    state.logs.pageLength = data.meta ? data.meta.last_page : 1;
  },

  [LOGS_LIST_LOADING](state, flag) {
    state.logs.isLoading = flag;
  },

  [LOGS_FILTERS](state, data) {
    state.logsFilters.data = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
