import {$http} from '@/utils/http'
import {findIndex, orderBy} from "lodash";

import {
  LEFTOVER_TYPES_LIST,
  LEFTOVER_TYPES_LIST_LOADING,

  LEFTOVER_TYPES_DATA,
  LEFTOVER_TYPES_DATA_LOADING,

  LEFTOVER_TYPES_DATA_UPDATE_LOADING,
  LEFTOVER_TYPES_DATA_CREATE_LOADING,
  LEFTOVER_TYPES_DATA_DELETE,
} from '../mutation-types';

import {
  LEFTOVER_TYPES_REQUEST_LIST_GET,
  LEFTOVER_TYPES_REQUEST_DATA_GET,
  LEFTOVER_TYPES_REQUEST_UPDATE,
  LEFTOVER_TYPES_REQUEST_CREATE,
  LEFTOVER_TYPES_REQUEST_DELETE,
} from '../action-types';

const state = {
  leftoverTypes: {
    list: null,
    isLoading: false,
  },
  leftoverType: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  leftoverTypes: state => state.leftoverTypes.list,
  isLeftoverTypesLoading: state => state.leftoverTypes.isLoading,

  leftoverType: state => state.leftoverType.data,
  isLeftoverTypeLoading: state => state.leftoverType.isLoading,
  isLeftoverTypeUpdating: state => state.leftoverType.isUpdating,
  isLeftoverTypeCreating: state => state.leftoverType.isCreating,
};

const actions = {
  [LEFTOVER_TYPES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(LEFTOVER_TYPES_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`leftover_types?include=${include}`);
      commit(LEFTOVER_TYPES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LEFTOVER_TYPES_LIST_LOADING, false);
    }
  },

  [LEFTOVER_TYPES_REQUEST_DATA_GET]: async ({commit}, leftoverTypeId) => {
    try {
      commit(LEFTOVER_TYPES_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`leftover_types/${leftoverTypeId}?include=${include}`);
      commit(LEFTOVER_TYPES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LEFTOVER_TYPES_DATA_LOADING, false);
    }
  },

  [LEFTOVER_TYPES_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(LEFTOVER_TYPES_DATA_UPDATE_LOADING, true);
      let include = 'translations';
      return await $http.put(`leftover_types/${data.leftoverTypeId}?include=${include}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LEFTOVER_TYPES_DATA_UPDATE_LOADING, false);
    }
  },

  [LEFTOVER_TYPES_REQUEST_CREATE]: async ({commit}, data) => {
    try {
      commit(LEFTOVER_TYPES_DATA_CREATE_LOADING, true);
      let include = 'translations';
      return await $http.post(`leftover_types?include=${include}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LEFTOVER_TYPES_DATA_CREATE_LOADING, false);
    }
  },

  [LEFTOVER_TYPES_REQUEST_DELETE]: async ({commit}, leftoverTypeId) => {
    try {
      await $http.delete(`leftover_types/${leftoverTypeId}`);
      commit(LEFTOVER_TYPES_DATA_DELETE, leftoverTypeId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [LEFTOVER_TYPES_LIST](state, data) {
    state.leftoverTypes.list = data.length ? orderBy(data, ['value'], ['asc']) : [];
  },
  [LEFTOVER_TYPES_LIST_LOADING](state, flag) {
    state.leftoverTypes.isLoading = flag;
  },

  [LEFTOVER_TYPES_DATA](state, data) {
    state.leftoverType.data = data;
  },
  [LEFTOVER_TYPES_DATA_LOADING](state, flag) {
    state.leftoverType.isLoading = flag;
  },
  [LEFTOVER_TYPES_DATA_UPDATE_LOADING](state, flag) {
    state.leftoverType.isUpdating = flag;
  },
  [LEFTOVER_TYPES_DATA_CREATE_LOADING](state, flag) {
    state.leftoverType.isCreating = flag;
  },
  [LEFTOVER_TYPES_DATA_DELETE](state, id) {
    let index = findIndex(state.leftoverTypes.list, item => {return item.id === id;});
    state.leftoverTypes.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
