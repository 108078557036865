import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

//---for multiple parallel requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Accept': 'application/json'
  }
});

const DISMISS_URL_TOKEN = 'auth/invite/password';

$http.interceptors.request.use((config) => {
  let access_token = localStorage.getItem('access_token')

  if (access_token && !config.headers.Authorization && config.url !== DISMISS_URL_TOKEN) {
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  return config;
});
$http.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const originalRequest = error.config;
    if (error.response) {
      switch (error.response.status) {
        case 500: {
          router.push({name: 'server-not-found'}).catch(() => {
            console.log()
          });
          break;
        }
        case 404: {
          if(router.currentRoute.name !== 'barcode' && router.currentRoute.name !== 'supplyOrders.show' && router.currentRoute.name !== 'orders.show') {
            router.push({name: 'not-found'}).catch(() => {
              console.log()
            });
          }
          break;
        }
        default: {
          break;
        }
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        if (isRefreshing) {
          return new Promise(((resolve, reject) => {
            failedQueue.push({resolve, reject});
          })).then((token) => {

            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return Vue.axios(originalRequest);
          }).catch(error => error);
        }

        originalRequest.retry = true;
        isRefreshing = true;

        return new Promise(((resolve, reject) => {
          $http.post('/auth/refresh')
            .then(response => {
              store.commit(`auth/AUTH_SET_TOKEN`, response.data.data.access_token);
              originalRequest.headers.Authorization = `Bearer ${response.data.data.access_token}`;
              processQueue(null, response.data.data.access_token);
              resolve(Vue.axios(originalRequest));
            })
            .catch((error) => {
              router.push({name: 'login'}).catch(() => {
                console.log()
              });
              store.commit(`auth/AUTH_REMOVE_TOKEN`);
              processQueue(error, null);
              reject(error);
            })
            .then(() => {
              isRefreshing = false;
            });
        }));
      }

      if ((error.response.status === 302 || error.response.status === 400) && error.config.url === '/auth/refresh') {
        router.push({name: 'login'}).catch(() => {
          console.log()
        });
        store.commit(`auth/AUTH_REMOVE_TOKEN`);
      }
    } else {
      router.push({name: 'login'}).catch(() => {
        console.log()
      });
      store.commit(`auth/AUTH_REMOVE_TOKEN`);
    }

    return Promise.reject(error);
  }
);

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
