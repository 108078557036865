import {$http} from '@/utils/http'
import {findIndex, orderBy} from "lodash";

import {
  BOX_TYPES_LIST,
  BOX_TYPES_LIST_LOADING,

  BOX_TYPES_DATA,
  BOX_TYPES_DATA_LOADING,

  BOX_TYPES_DATA_UPDATE_LOADING,
  BOX_TYPES_DATA_CREATE_LOADING,
  BOX_TYPES_DATA_DELETE,
} from '../mutation-types';

import {
  BOX_TYPES_REQUEST_LIST_GET,
  BOX_TYPES_REQUEST_DATA_GET,
  BOX_TYPES_REQUEST_UPDATE,
  BOX_TYPES_REQUEST_CREATE,
  BOX_TYPES_REQUEST_DELETE,
} from '../action-types';

const state = {
  boxTypes: {
    list: null,
    isLoading: false,
  },
  boxType: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  boxTypes: state => state.boxTypes.list,
  isBoxTypesLoading: state => state.boxTypes.isLoading,

  boxType: state => state.boxType.data,
  isBoxTypeLoading: state => state.boxType.isLoading,
  isBoxTypeUpdating: state => state.boxType.isUpdating,
  isBoxTypeCreating: state => state.boxType.isCreating,
};

const actions = {
  [BOX_TYPES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(BOX_TYPES_LIST_LOADING, true);
      let include = 'translations,pack_type,product,consignments,places,shelf,rack,store';
      const response = await $http.get(`box_types?include=${include}`);
      commit(BOX_TYPES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(BOX_TYPES_LIST_LOADING, false);
    }
  },

  [BOX_TYPES_REQUEST_DATA_GET]: async ({commit}, boxTypeId) => {
    try {
      commit(BOX_TYPES_DATA_LOADING, true);
      let include = 'translations,pack_type';
      const response = await $http.get(`box_types/${boxTypeId}?include=${include}`);
      commit(BOX_TYPES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(BOX_TYPES_DATA_LOADING, false);
    }
  },

  [BOX_TYPES_REQUEST_UPDATE]: async ({commit}, {boxTypeId, payload}) => {
    try {
      commit(BOX_TYPES_DATA_UPDATE_LOADING, true);
      let include = 'translations';
      return await $http.put(`box_types/${boxTypeId}?include=${include}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(BOX_TYPES_DATA_UPDATE_LOADING, false);
    }
  },

  [BOX_TYPES_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(BOX_TYPES_DATA_CREATE_LOADING, true);
      let include = 'translations';
      return await $http.post(`box_types?include=${include}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(BOX_TYPES_DATA_CREATE_LOADING, false);
    }
  },

  [BOX_TYPES_REQUEST_DELETE]: async ({commit}, boxTypeId) => {
    try {
      await $http.delete(`box_types/${boxTypeId}`);
      commit(BOX_TYPES_DATA_DELETE, boxTypeId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [BOX_TYPES_LIST](state, data) {
    let list = [];
    if (data.length) {
      list = orderBy(data, [function(item) { return item.pack_type.data.name }, 'length'], ['asc', 'asc']);
    }
    state.boxTypes.list = list;
  },
  [BOX_TYPES_LIST_LOADING](state, flag) {
    state.boxTypes.isLoading = flag;
  },

  [BOX_TYPES_DATA](state, data) {
    state.boxType.data = data;
  },
  [BOX_TYPES_DATA_LOADING](state, flag) {
    state.boxType.isLoading = flag;
  },
  [BOX_TYPES_DATA_UPDATE_LOADING](state, flag) {
    state.boxType.isUpdating = flag;
  },
  [BOX_TYPES_DATA_CREATE_LOADING](state, flag) {
    state.boxType.isCreating = flag;
  },
  [BOX_TYPES_DATA_DELETE](state, id) {
    let index = findIndex(state.boxTypes.list, item => {return item.id === id;});
    state.boxTypes.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
