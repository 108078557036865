import {$http} from '@/utils/http'
import {cloneDeep, find, findIndex, isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  PARCELS_NEW_LIST,
  PARCELS_NEW_LIST_LOADING,
  PARCELS_NEW_LIST_PAGE,
  PARCELS_NEW_LIST_PAGE_LENGTH,

  PARCELS_COLLECTING_LIST,
  PARCELS_COLLECTING_LIST_LOADING,
  PARCELS_COLLECTING_LIST_PAGE,
  PARCELS_COLLECTING_LIST_PAGE_LENGTH,

  PARCELS_READY_LIST,
  PARCELS_READY_LIST_LOADING,
  PARCELS_READY_LIST_PAGE,
  PARCELS_READY_LIST_PAGE_LENGTH,

  PARCELS_SENT_LIST,
  PARCELS_SENT_LIST_LOADING,
  PARCELS_SENT_LIST_PAGE,
  PARCELS_SENT_LIST_PAGE_LENGTH,

  PARCELS_DATA,
  PARCELS_DATA_LOADING,
  PARCELS_DATA_UPDATING,
  PARCELS_BOX_UPDATE_DATA,
} from '../mutation-types';

import {
  PARCELS_REQUEST_NEW_LIST_GET,
  PARCELS_REQUEST_COLLECTING_LIST_GET,
  PARCELS_REQUEST_READY_LIST_GET,
  PARCELS_REQUEST_SENT_LIST_GET,
  PARCELS_REQUEST_DATA_GET,
  PARCELS_REQUEST_DATA_UPDATE,
} from '../action-types';
import Vue from "vue";


const state = {
  parcelsNew: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
  },
  parcelsCollecting: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  parcelsReady: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  parcelsSent: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  parcel: {
    data: null,
    isLoading: false,
    isUpdating: false
  },
};

const getters = {
  parcelsNew: state => state.parcelsNew.list,
  isParcelsNewLoading: state => state.parcelsNew.isLoading,
  parcelsNewPage: state => state.parcelsNew.page,
  parcelsNewPageLength: state => state.parcelsNew.pageLength,

  parcelsCollecting: state => state.parcelsCollecting.list,
  isParcelsCollectingLoading: state => state.parcelsCollecting.isLoading,
  parcelsCollectingPage: state => state.parcelsCollecting.page,
  parcelsCollectingPageLength: state => state.parcelsCollecting.pageLength,

  parcelsReady: state => state.parcelsReady.list,
  isParcelsReadyLoading: state => state.parcelsReady.isLoading,
  parcelsReadyPage: state => state.parcelsReady.page,
  parcelsReadyPageLength: state => state.parcelsReady.pageLength,

  parcelsSent: state => state.parcelsSent.list,
  isParcelsSentLoading: state => state.parcelsSent.isLoading,
  parcelsSentPage: state => state.parcelsSent.page,
  parcelsSentPageLength: state => state.parcelsSent.pageLength,

  parcel: state => state.parcel.data,
  isParcelLoading: state => state.parcel.isLoading,
  isParcelUpdating: state => state.parcel.isUpdating,
};

const actions = {
  [PARCELS_REQUEST_NEW_LIST_GET]: async ({commit}, params) => {
    try {
      commit(PARCELS_NEW_LIST_LOADING, true);
      commit(PARCELS_NEW_LIST_PAGE, params.page);

      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`parcels?include=${include}&per_page=${state.parcelsNew.per_page}${query}`);

      commit(PARCELS_NEW_LIST, response.data.data);
      commit(PARCELS_NEW_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_NEW_LIST_LOADING, false);
    }
  },

  [PARCELS_REQUEST_COLLECTING_LIST_GET]: async ({commit}, params) => {
    try {
      commit(PARCELS_COLLECTING_LIST_LOADING, true);
      commit(PARCELS_COLLECTING_LIST_PAGE, params.page);

      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`parcels?include=${include}&per_page=${state.parcelsCollecting.per_page}${query}`);

      commit(PARCELS_COLLECTING_LIST, response.data.data);
      commit(PARCELS_COLLECTING_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_COLLECTING_LIST_LOADING, false);
    }
  },

  [PARCELS_REQUEST_READY_LIST_GET]: async ({commit}, params) => {
    try {
      commit(PARCELS_READY_LIST_LOADING, true);
      commit(PARCELS_READY_LIST_PAGE, params.page);

      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`parcels?include=${include}&per_page=${state.parcelsReady.per_page}${query}`);

      commit(PARCELS_READY_LIST, response.data.data);
      commit(PARCELS_READY_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_READY_LIST_LOADING, false);
    }
  },

  [PARCELS_REQUEST_SENT_LIST_GET]: async ({commit}, params) => {
    try {
      commit(PARCELS_SENT_LIST_LOADING, true);
      commit(PARCELS_SENT_LIST_PAGE, params.page);

      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`parcels?include=${include}&per_page=${state.parcelsReady.per_page}${query}`);

      commit(PARCELS_SENT_LIST, response.data.data);
      commit(PARCELS_SENT_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_SENT_LIST_LOADING, false);
    }
  },

  [PARCELS_REQUEST_DATA_GET]: async ({commit}, parcelId) => {
    try {
      commit(PARCELS_DATA_LOADING, true);
      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const response = await $http.get(`parcels/${parcelId}?include=${include}`);
      commit(PARCELS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_DATA_LOADING, false);
    }
  },

  [PARCELS_REQUEST_DATA_UPDATE]: async ({commit}, {parcelId, payload}) => {
    try {
      commit(PARCELS_DATA_UPDATING, true);
      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product,consignments,places';
      const response = await $http.put(`parcels/${parcelId}/update_status?include=${include}`, payload);
      commit(PARCELS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PARCELS_DATA_UPDATING, false);
    }
  },
};

const mutations = {
  [PARCELS_NEW_LIST](state, list) {
    list.forEach(item => {
      item.boxes.data.forEach(box => {
        box.storage = _prepareBoxStorage(box.place.data)
      });
    });
    state.parcelsNew.list = list;
  },
  [PARCELS_NEW_LIST_LOADING](state, flag) {
    state.parcelsNew.isLoading = flag;
  },
  [PARCELS_NEW_LIST_PAGE](state, number) {
    state.parcelsNew.page = number;
  },
  [PARCELS_NEW_LIST_PAGE_LENGTH](state, count) {
    state.parcelsNew.pageLength = count;
  },

  [PARCELS_COLLECTING_LIST](state, list) {
    list.forEach(item => {
      //---boxes
      item.boxes.data.forEach(box => {
        box.storage = _prepareBoxStorage(box.place.data)
      });
      //---picker
      item.picker = _getPicker(item);
    });
    state.parcelsCollecting.list = list;
  },
  [PARCELS_COLLECTING_LIST_LOADING](state, flag) {
    state.parcelsCollecting.isLoading = flag;
  },
  [PARCELS_COLLECTING_LIST_PAGE](state, number) {
    state.parcelsCollecting.page = number;
  },
  [PARCELS_COLLECTING_LIST_PAGE_LENGTH](state, count) {
    state.parcelsCollecting.pageLength = count;
  },

  [PARCELS_READY_LIST](state, list) {
    list.forEach(item => {
      //---boxes
      item.boxes.data.forEach(box => {
        box.storage = _prepareBoxStorage(box.place.data)
      });
      //---picker
      item.picker = _getPicker(item);
    });
    state.parcelsReady.list = list;
  },
  [PARCELS_READY_LIST_LOADING](state, flag) {
    state.parcelsReady.isLoading = flag;
  },
  [PARCELS_READY_LIST_PAGE](state, number) {
    state.parcelsReady.page = number;
  },
  [PARCELS_READY_LIST_PAGE_LENGTH](state, count) {
    state.parcelsReady.pageLength = count;
  },

  [PARCELS_SENT_LIST](state, list) {
    list.forEach(item => {
      //---boxes
      item.boxes.data.forEach(box => {
        box.storage = _prepareBoxStorage(box.place.data)
      });
      //---picker
      item.picker = _getPicker(item);
    });
    state.parcelsSent.list = list;
  },
  [PARCELS_SENT_LIST_LOADING](state, flag) {
    state.parcelsSent.isLoading = flag;
  },
  [PARCELS_SENT_LIST_PAGE](state, number) {
    state.parcelsSent.page = number;
  },
  [PARCELS_SENT_LIST_PAGE_LENGTH](state, count) {
    state.parcelsSent.pageLength = count;
  },

  [PARCELS_DATA](state, data) {
    if (data) {
      data.boxes.data.forEach(box => {
        box.storage = _prepareBoxStorage(box.place.data)
      });
    }
    state.parcel.data = data;
  },
  [PARCELS_DATA_LOADING](state, flag) {
    state.parcel.isLoading = flag;
  },
  [PARCELS_DATA_UPDATING](state, flag) {
    state.parcel.isUpdating = flag;
  },

  [PARCELS_BOX_UPDATE_DATA](state, data) {
    state.parcel.data.boxes.data.forEach(box => {
      box.storage = _prepareBoxStorage(box.place.data)
    });

    let index = findIndex(state.parcel.data.boxes.data, item => {return item.id === data.id;});
    if (index > -1) {
      data.storage = _prepareBoxStorage(data.place.data)
      Vue.set(state.parcel.data.boxes.data, index, data);
    }
  },
};

const _prepareBoxStorage = (data) => {
  if (data) {
    let storage = {};
    storage.store = cloneDeep(data.shelf.data.rack.data.store.data);
    storage.rack = cloneDeep(data.shelf.data.rack.data);
    delete storage.rack.store;
    storage.shelf = cloneDeep(data.shelf.data);
    delete storage.shelf.rack;
    storage.place = cloneDeep(data);
    delete storage.place.shelf;
    return storage
  } else {
    return null
  }
}

const _getPicker = (parcel) => `${parcel.administrator.data.name} ${parcel.administrator.data.last_name}`;

export default {
  state,
  getters,
  actions,
  mutations,
};