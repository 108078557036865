import Vue from 'vue';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';

import vueSmoothScroll from 'vue2-smooth-scroll';

// import 'moment/locale/ru'

//use
Vue.use(Toasted,{duration:5000});
Vue.use(Vuelidate);
Vue.use(vueSmoothScroll);


