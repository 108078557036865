import {
  BARCODE_ID
} from '../mutation-types';

const state = {
  barcodeId: 0,
};

const getters = {
  barcodeId: state => state.barcodeId
};

const mutations = {
  [BARCODE_ID](state, id) {
    state.barcodeId = id;
  },

};
export default {
  state,
  getters,
  mutations,
};
