import Vue from 'vue';

import {$http} from '@/utils/http'
import {uniqBy, cloneDeep, findIndex, find} from "lodash";

import {
  SUPPLY_ORDERS_NEW_LIST,
  SUPPLY_ORDERS_NEW_LIST_LOADING,
  SUPPLY_ORDERS_NEW_DATA,
  SUPPLY_ORDERS_NEW_PRODUCT_DELETE,
  SUPPLY_ORDERS_IN_PROGRESS_LIST,
  SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING,
  SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE,
  SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE_LENGTH,
  SUPPLY_ORDERS_FINISHED_LIST,
  SUPPLY_ORDERS_FINISHED_LIST_LOADING,
  SUPPLY_ORDERS_FINISHED_LIST_PAGE,
  SUPPLY_ORDERS_FINISHED_LIST_PAGE_LENGTH,
  SUPPLY_ORDERS_DATA,
  SUPPLY_ORDERS_DATA_LOADING,

  SUPPLY_ORDERS_UPDATE,
  SUPPLY_ORDERS_UPDATE_LOADING,
  SUPPLY_ORDERS_CONFIRM_LOADING,

  SUPPLY_ORDERS_PRODUCT,
  SUPPLY_ORDERS_PRODUCT_UPDATING,
  SUPPLY_ORDERS_PRODUCT_UPDATE_DATA,
  SUPPLY_ORDERS_PRODUCT_CREATING
} from '../mutation-types';

import {
  SUPPLY_ORDERS_REQUEST_NEW_LIST_GET,
  SUPPLY_ORDERS_REQUEST_IN_PROGRESS_LIST_GET,
  SUPPLY_ORDERS_REQUEST_FINISHED_LIST_GET,
  SUPPLY_ORDERS_REQUEST_DATA_GET,
  SUPPLY_ORDERS_REQUEST_CONFIRM,
  SUPPLY_ORDERS_REQUEST_UPDATE,

  SUPPLY_ORDERS_REQUEST_PRODUCT_UPDATE,
  SUPPLY_ORDERS_REQUEST_PRODUCT_CREATE,
  SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_UPDATE,
  SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_DELETE,
} from '../action-types';

const state = {
  supplyOrdersNew: {
    list: null,
    data: null,
    isLoading: false
  },
  supplyOrdersInProgress: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  supplyOrdersFinished: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  supplyOrder: {
    data: null,
    isLoading: false,
  },

  supplyProduct: {
    data: null,
    isCreating: false,
    isUpdating: false,
  },

  isSupplyOrderUpdating: false,
  isSupplyOrderConfirming: false,
  isSupplyProductCreating: false,
};
const getters = {
  supplyOrdersNewList: state => state.supplyOrdersNew.list,
  supplyOrderNew: state => state.supplyOrdersNew.data,
  isSupplyOrdersNewLoading: state => state.supplyOrdersNew.isLoading,

  supplyOrdersInProgressList: state => state.supplyOrdersInProgress.list,
  isSupplyOrdersInProgressLoading: state => state.supplyOrdersInProgress.isLoading,
  supplyOrdersInProgressPage: state => state.supplyOrdersInProgress.page,
  supplyOrdersInProgressPageLength: state => state.supplyOrdersInProgress.pageLength,

  supplyOrdersFinishedList: state => state.supplyOrdersFinished.list,
  isSupplyOrdersFinishedLoading: state => state.supplyOrdersFinished.isLoading,
  supplyOrdersFinishedPage: state => state.supplyOrdersFinished.page,
  supplyOrdersFinishedPageLength: state => state.supplyOrdersFinished.pageLength,

  supplyOrder: state => state.supplyOrder.data,
  isSupplyOrderLoading: state => state.supplyOrder.isLoading,

  isSupplyOrderUpdating: state => state.isSupplyOrderUpdating,
  isSupplyOrderConfirming: state => state.isSupplyOrderConfirming,

  supplyProduct: state => state.supplyProduct.data,
  isSupplyProductCreating: state => state.supplyProduct.isCreating,
  isSupplyProductUpdating: state => state.supplyProduct.isUpdating,
};

const actions = {
  [SUPPLY_ORDERS_REQUEST_NEW_LIST_GET]: async ({commit}) => {
    try {
      commit(SUPPLY_ORDERS_NEW_LIST_LOADING, true);
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      const response = await $http.get(`supply_orders?include=${include}&status=new&per_page=1&page=1`);
      commit(SUPPLY_ORDERS_NEW_DATA, response.data.data);
      commit(SUPPLY_ORDERS_NEW_LIST, response.data.data.supply_order_status.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_NEW_LIST_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_IN_PROGRESS_LIST_GET]: async ({commit}) => {
    try {
      commit(SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING, true);
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      const response = await $http.get(`supply_orders?include=${include}&status=in_progress&per_page=${state.supplyOrdersInProgress.per_page}&page=${state.supplyOrdersInProgress.page}`);
      commit(SUPPLY_ORDERS_IN_PROGRESS_LIST, response.data.data);
      commit(SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_FINISHED_LIST_GET]: async ({commit}) => {
    try {
      commit(SUPPLY_ORDERS_FINISHED_LIST_LOADING, true);
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      const response = await $http.get(`supply_orders?include=${include}&status=finished&per_page=${state.supplyOrdersFinished.per_page}&page=${state.supplyOrdersFinished.page}`);
      commit(SUPPLY_ORDERS_FINISHED_LIST, response.data.data);
      commit(SUPPLY_ORDERS_FINISHED_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_FINISHED_LIST_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_DATA_GET]: async ({commit}, supplyOrderId) => {
    try {
      commit(SUPPLY_ORDERS_DATA_LOADING, true);
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      const response = await $http.get(`supply_orders/${supplyOrderId}?include=${include}`);
      commit(SUPPLY_ORDERS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_DATA_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_CONFIRM]: async ({commit}, supplyOrderId) => {
    try {
      commit(SUPPLY_ORDERS_CONFIRM_LOADING, true);
      let response = await $http.post(`supply_orders/${supplyOrderId}/confirm`);
      commit(SUPPLY_ORDERS_NEW_LIST, null);
      commit(SUPPLY_ORDERS_DATA, null);
      return response
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_CONFIRM_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(SUPPLY_ORDERS_UPDATE_LOADING, true);
      let response = await $http.put(`supply_orders/${data.supplyOrderId}/status/update`,data.payload);
      commit(SUPPLY_ORDERS_UPDATE);
      return response
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_UPDATE_LOADING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_UPDATE]: async ({commit}, {supplyOrderId, supplyOrderProductId, payload}) => {
    try {
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      return await $http.put(`supply_orders/${supplyOrderId}/supply_order_statuses/${supplyOrderProductId}?include=${include}`, payload);
    } catch (e) {
      throw e;
    }
  },

  [SUPPLY_ORDERS_REQUEST_PRODUCT_UPDATE]: async ({commit}, {supplyOrderId, supplyOrderProductId, payload}) => {
    try {
      commit(SUPPLY_ORDERS_PRODUCT_UPDATING, true);
      let include = 'product,administrator,supply_order_status,supply_order_files,vendors';
      let response = await $http.put(`supply_orders/${supplyOrderId}/supply_order_statuses/${supplyOrderProductId}?include=${include}`, payload);

      commit(SUPPLY_ORDERS_PRODUCT_UPDATE_DATA, response.data.data);
      commit(SUPPLY_ORDERS_DATA, state.supplyOrder.data );
      return response
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_PRODUCT_UPDATING, false);
    }
  },

  [SUPPLY_ORDERS_REQUEST_PRODUCT_CREATE]: async ({commit}, payload) => {
    try {
      commit(SUPPLY_ORDERS_PRODUCT_CREATING, true);
      return await $http.post(`supply_orders/supply_order_statuses`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SUPPLY_ORDERS_PRODUCT_CREATING, false);
    }
  },
  [SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_DELETE]: async ({commit}, {supplyOrderId, supplyOrderProductId}) => {
    try {
      let response = await $http.delete(`supply_orders/${supplyOrderId}/supply_order_statuses/${supplyOrderProductId}`);
      commit(SUPPLY_ORDERS_NEW_PRODUCT_DELETE, supplyOrderProductId);
      return response
    } catch (e) {
      throw e;
    }
  },

};

const mutations = {
  [SUPPLY_ORDERS_NEW_LIST](state, list) {
    state.supplyOrdersNew.list = getDecoratedNewList(list);
  },
  [SUPPLY_ORDERS_NEW_LIST_LOADING](state, flag) {
    state.supplyOrdersNew.isLoading = flag;
  },

  [SUPPLY_ORDERS_NEW_DATA](state, data) {
    state.supplyOrdersNew.data = data;
  },

  [SUPPLY_ORDERS_IN_PROGRESS_LIST](state, list) {
    state.supplyOrdersInProgress.list = getDecoratedProgressList(list);
  },
  [SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING](state, flag) {
    state.supplyOrdersInProgress.isLoading = flag;
  },
  [SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE](state, number) {
    state.supplyOrdersInProgress.page = number;
  },
  [SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE_LENGTH](state, count) {
    state.supplyOrdersInProgress.pageLength = count;
  },

  [SUPPLY_ORDERS_FINISHED_LIST](state, list) {
    state.supplyOrdersFinished.list = getDecoratedFinishedList(list);
  },
  [SUPPLY_ORDERS_FINISHED_LIST_LOADING](state, flag) {
    state.supplyOrdersFinished.isLoading = flag;
  },
  [SUPPLY_ORDERS_FINISHED_LIST_PAGE](state, number) {
    state.supplyOrdersFinished.page = number;
  },
  [SUPPLY_ORDERS_FINISHED_LIST_PAGE_LENGTH](state, count) {
    state.supplyOrdersFinished.pageLength = count;
  },

  [SUPPLY_ORDERS_DATA](state, data) {
    if (data) {
      //--create vendor group
      let vendors = [];

      data.supply_order_status.data.forEach(item => {
        item.product = getOrderedProduct(item);
        vendors.push(item.vendor.data);
      });
      vendors = uniqBy(vendors, 'id');
      data.vendorsGroup = [];
      vendors.forEach(vendor => {
        let vendorsGroup = cloneDeep(vendor);
        //---products
        vendorsGroup.products = data.supply_order_status.data.filter( orderProduct=> {
          return orderProduct.vendor.data.id === vendorsGroup.id
        })

        vendorsGroup.products.forEach(item => {
          item.isLoading = false;
        })

        //---files
        vendorsGroup.files = data.supply_order_files.data.filter(file => {
          return file.vendor_id === vendor.id
        })

        data.vendorsGroup.push(vendorsGroup)
      })

      data.supply_order_status.data.forEach(item => {
        item.isLoading = false;
      })
    }
    state.supplyOrder.data = data;
  },

  [SUPPLY_ORDERS_DATA_LOADING](state, flag) {
    state.supplyOrder.isLoading = flag;
  },

  [SUPPLY_ORDERS_UPDATE](state) {
    Vue.set(state.supplyOrder.data, 'status', 'finished');
  },
  [SUPPLY_ORDERS_UPDATE_LOADING](state, flag) {
    state.isSupplyOrderUpdating = flag;
  },
  [SUPPLY_ORDERS_CONFIRM_LOADING](state, flag) {
    state.isSupplyOrderConfirming = flag;
  },

  [SUPPLY_ORDERS_PRODUCT](state, data) {
    state.supplyProduct.data = data;
  },
  [SUPPLY_ORDERS_PRODUCT_CREATING](state, flag) {
    state.supplyProduct.isCreating = flag;
  },
  [SUPPLY_ORDERS_PRODUCT_UPDATING](state, flag) {
    state.supplyProduct.isUpdating = flag;
  },
  [SUPPLY_ORDERS_PRODUCT_UPDATE_DATA](state, data) {
    let index = findIndex(state.supplyOrder.data.supply_order_status.data,
        item => {return item.id === data.id;});
    Vue.set(state.supplyOrder.data.supply_order_status.data, index, data);
    state.supplyProduct.data = data;
  },

  [SUPPLY_ORDERS_NEW_PRODUCT_DELETE](state, supplyOrderStatusId) {
    let list = cloneDeep(state.supplyOrdersNew.list)
    let index = findIndex(list, item => {return item.id === supplyOrderStatusId});
    list.splice(index, 1);

    state.supplyOrdersNew.list = getDecoratedNewList(list);
  },
};

const getOrderedProduct = (orderProduct) => {
  return orderProduct.product.data ? orderProduct.product : orderProduct.product_item
}

const getOrderedVendor = (product, vendorId) => {
  let orderedVendor = null;
  if (product.data.vendors.data.length) {
    orderedVendor = find(product.data.vendors.data, function(vendor) {
      return vendorId === vendor.id
    });
  }
  return orderedVendor
}

const getDecoratedNewList = (list) => {
  if (list && list.length > 0 ) {
    list.forEach((item, index) => {
      let ordered_product = getOrderedProduct(item);

      Vue.set(list, index, Object.assign({}, item, {
        product: ordered_product,
        ordered_count: item.expected_count,
        ordered_vendor: getOrderedVendor(ordered_product, item.vendor.data.id)
      }));
    })
  }
  return list
}

const getDecoratedProgressList = (list) => {
  if (list && list.length > 0 ) {
    list.forEach(item => {
      item.productsCount = item.supply_order_status.data.reduce((acum, current) => {
        return acum + current.expected_count
      }, 0)
      item.productsAmount = item.supply_order_status.data.reduce((acum, current) => {
        return acum + current.expected_count * current.vendor.data.vendor_price
      }, 0)
    })
  }
  return list
}

const getDecoratedFinishedList = (list) => {
  if (list && list.length > 0 ) {
    list.forEach(item => {
      item.productsCount = item.supply_order_status.data.reduce((acum, current) => {
        return acum + current.count
      }, 0)
      item.productsAmount = item.supply_order_status.data.reduce((acum, current) => {
        return acum + current.count * current.vendor.data.vendor_price
      }, 0)
    })
  }
  return list
}

export default {
  state,
  getters,
  actions,
  mutations,
};