import {$http} from '@/utils/http'

import {
  DELIVERY_LIST,
  DELIVERY_LIST_LOADING,
  DELIVERY_DATA,
  DELIVERY_DATA_UPDATING,
} from '../mutation-types';

import {
  DELIVERY_REQUEST_LIST_SIMPLE_GET,
  DELIVERY_REQUEST_DATA_GET,
  DELIVERY_REQUEST_DATA_UPDATE,
} from '../action-types';

const state = {
  deliveryList: {
    list: null,
    isLoading: false
  },
  delivery: {
    data: null,
    isLoading: false,
    isUpdating: false,
  },
};

const getters = {
  deliveryList: state => state.deliveryList.list,
  isDeliveryListLoading: state => state.deliveryList.isLoading,
  delivery: state => state.delivery.data,
  isDeliveryLoading: state => state.delivery.isLoading,
  isDeliveryUpdating: state => state.delivery.isUpdating,
};

const actions = {
  [DELIVERY_REQUEST_LIST_SIMPLE_GET]: async ({commit}) => {
    try {
      commit(DELIVERY_LIST_LOADING, true);
      // let include = 'country_groups,weights,delivery_regions,base_rates,delivery_groups';
      const response = await $http.get(`delivery_methods`);
      commit(DELIVERY_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_LIST_LOADING, false);
    }
  },

  [DELIVERY_REQUEST_DATA_GET]: async ({commit}, deliveryId) => {
    try {
      let include = 'delivery_group,base_rates';
      const response = await $http.get(`delivery_methods/${deliveryId}?include=${include}`);
      commit(DELIVERY_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [DELIVERY_REQUEST_DATA_UPDATE]: async ({commit}, {deliveryId, payload}) => {
    try {
      commit(DELIVERY_DATA_UPDATING, true);
      let include = 'delivery_group,base_rates';
      const response = await $http.put(`delivery_methods/${deliveryId}?include=${include}`, payload);
      commit(DELIVERY_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_DATA_UPDATING, false);
    }
  },
};

const mutations = {

  [DELIVERY_LIST](state, data) {
    state.deliveryList.list = data;
  },
  [DELIVERY_LIST_LOADING](state, flag) {
    state.deliveryList.isLoading = flag;
  },

  [DELIVERY_DATA](state, data) {
    state.delivery.data = data;
  },

  [DELIVERY_DATA_UPDATING](state, flag) {
    state.delivery.isUpdating = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
