import Vue from 'vue';

import {
  SYSTEM_DASHBOARD_SIDEBAR_TOGGLE,
  SYSTEM_RESIZE,
  SYSTEM_POPUP_OPEN,
  SYSTEM_POPUP_CLOSE,
} from '../mutation-types';

const state = {
  dashboardSidebarStatus: false,
  appSize: false,
  popupStore: {},
};

const getters = {
  dashboardSidebarStatus: state => state.dashboardSidebarStatus,
  appSize: state => state.appSize,
  popupStore: state => state.popupStore,
};

const mutations = {
  [SYSTEM_DASHBOARD_SIDEBAR_TOGGLE](state, flag) {
    state.dashboardSidebarStatus = flag;
  },

  [SYSTEM_RESIZE](state) {
    if (state.appSize.width !== window.innerWidth || state.appSize.height !== window.innerHeight) {
      state.appSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

  },

  [SYSTEM_POPUP_OPEN](state, name) {
    Vue.set(state.popupStore, name, true);
  },

  [SYSTEM_POPUP_CLOSE](state, name) {
    if (state.popupStore[name]) {
      Vue.set(state.popupStore, name, false);
    } else {
      console.error('POPUP CLOSE| not found popup ' + name)
    }
  }
};

export default {
  state,
  getters,
  mutations,
};
