<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<template>
  <v-app v-resize="onResize">
    <v-main>
      <div id="app" class="app">
        <div class="wrapper">
          <div class="wrapper-top">
            <main-header/>
          </div>
          <div class="wrapper-content">
            <transition name="component-fade" mode="out-in">
              <router-view/>
            </transition>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<!--// scripts-->
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import mainHeader from '@/components/features/header/index.vue';

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    mainHeader
  },
  mounted() {},
  created() {
    if (this.isAuthenticated) {
      this.fetchUser();
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      appSize: 'system/appSize',
    })
  },
  methods: {
    ...mapMutations({
      setAppSize: 'system/SYSTEM_RESIZE',
    }),
    ...mapActions({
      fetchUser: 'profile/PROFILE_REQUEST_USER_DATA_GET',
    }),
    onResize() {
      this.setAppSize();
    },
  }
}
</script>
