//---system
export const SYSTEM_DASHBOARD_SIDEBAR_TOGGLE = 'SYSTEM_DASHBOARD_SIDEBAR_TOGGLE';
export const SYSTEM_RESIZE = 'SYSTEM_RESIZE';
export const SYSTEM_POPUP_OPEN = 'SYSTEM_POPUP_OPEN';
export const SYSTEM_POPUP_CLOSE = 'SYSTEM_POPUP_CLOSE';

//---auth
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN';
export const AUTH_INVITE_LOADING = 'AUTH_INVITE_LOADING';
export const AUTH_PASSWORD_RESTORE_LOADING = 'AUTH_PASSWORD_RESTORE_LOADING';
export const AUTH_INVITE_PASSWORD_RESTORE_LOADING = 'AUTH_INVITE_PASSWORD_RESTORE_LOADING';

//---profile
export const PROFILE_LOADING_DATA = 'PROFILE_LOADING_DATA';
export const PROFILE_DATA_SET = 'PROFILE_DATA_SET';

//---settings
export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_LIST_LOADING = 'SETTINGS_LIST_LOADING';
export const SETTINGS_DATA = 'SETTINGS_DATA';
export const SETTINGS_DATA_LOADING = 'SETTINGS_DATA_LOADING';
export const SETTINGS_DATA_UPDATE_LOADING = 'SETTINGS_DATA_UPDATE_LOADING';

//---administration
export const ADMINISTRATION_WORKER_LIST_LOADING = 'ADMINISTRATION_WORKER_LIST_LOADING';
export const ADMINISTRATION_WORKER_LIST = 'ADMINISTRATION_WORKER_LIST';
export const ADMINISTRATION_WORKER_LOADING = 'ADMINISTRATION_WORKER_LOADING';
export const ADMINISTRATION_WORKER = 'ADMINISTRATION_WORKER';
export const ADMINISTRATION_WORKER_UPDATE_LOADING = 'ADMINISTRATION_WORKER_UPDATE_LOADING';
export const ADMINISTRATION_WORKER_CREATE_LOADING = 'ADMINISTRATION_WORKER_CREATE_LOADING';

//---permissions
export const PERMISSIONS_LIST = 'PERMISSIONS_LIST';
export const PERMISSIONS_LIST_LOADING = 'PERMISSIONS_LIST_LOADING';
export const PERMISSIONS_DATA = 'PERMISSIONS_DATA';
export const PERMISSIONS_DATA_LOADING = 'PERMISSIONS_DATA_LOADING';

//---roles
export const ROLES_LIST = 'ROLES_LIST';
export const ROLES_LIST_LOADING = 'ROLES_LIST_LOADING';
export const ROLES_DATA = 'ROLES_DATA';
export const ROLES_DATA_LOADING = 'ROLES_DATA_LOADING';
export const ROLES_DATA_UPDATE_LOADING = 'ROLES_DATA_UPDATE_LOADING';
export const ROLES_DATA_CREATE_LOADING = 'ROLES_DATA_CREATE_LOADING';
export const ROLES_DATA_DELETE = 'ROLES_DATA_DELETE';

//---store
export const STORE_LIST = 'STORE_LIST';
export const STORE_DATA = 'STORE_DATA';
export const STORE_DATA_LOADING = 'STORE_DATA_LOADING';
export const STORE_DATA_UPDATE_LOADING = 'STORE_DATA_UPDATE_LOADING';
export const STORE_DATA_CREATE_LOADING = 'STORE_DATA_CREATE_LOADING';

//---racks
export const RACKS_LIST = 'RACKS_LIST';
export const RACKS_DATA = 'RACKS_DATA';
export const RACKS_DATA_LOADING = 'RACKS_DATA_LOADING';
export const RACKS_DATA_UPDATE_LOADING = 'RACKS_DATA_UPDATE_LOADING';
export const RACKS_CREATE_LOADING = 'RACKS_CREATE_LOADING';
export const RACKS_CREATE_LIST = 'RACKS_CREATE_LIST';

//---shelves
export const SHELVES_LIST = 'SHELVES_LIST';
export const SHELVES_DATA = 'SHELVES_DATA';
export const SHELVES_DATA_LOADING = 'SHELVES_DATA_LOADING';
export const SHELVES_DATA_UPDATE_LOADING = 'SHELVES_DATA_UPDATE_LOADING';
export const SHELVES_CREATE_LOADING = 'SHELVES_CREATE_LOADING';
export const SHELVES_CREATE_LIST = 'SHELVES_CREATE_LIST';

//---places
export const PLACES_LIST = 'PLACES_LIST';
export const PLACES_DATA = 'PLACES_DATA';
export const PLACES_DATA_LOADING = 'PLACES_DATA_LOADING';
export const PLACES_DATA_UPDATE_LOADING = 'PLACES_DATA_UPDATE_LOADING';
export const PLACES_CREATE_LOADING = 'PLACES_CREATE_LOADING';
export const PLACES_CREATE_LIST = 'PLACES_CREATE_LIST';

//---orders
export const ORDERS_NEW_LIST = 'ORDERS_NEW_LIST';
export const ORDERS_NEW_LIST_LOADING = 'ORDERS_NEW_LIST_LOADING';
export const ORDERS_NEW_LIST_PAGE = 'ORDERS_NEW_LIST_PAGE';

export const ORDERS_COLLECTING_LIST = 'ORDERS_COLLECTING_LIST';
export const ORDERS_COLLECTING_LIST_LOADING = 'ORDERS_COLLECTING_LIST_LOADING';
export const ORDERS_COLLECTING_LIST_PAGE = 'ORDERS_COLLECTING_LIST_PAGE';
export const ORDERS_COLLECTING_LIST_PAGE_LENGTH = 'ORDERS_COLLECTING_LIST_PAGE_LENGTH';

export const ORDERS_AWAITING_LIST = 'ORDERS_AWAITING_LIST';
export const ORDERS_AWAITING_LIST_LOADING = 'ORDERS_AWAITING_LIST_LOADING';
export const ORDERS_AWAITING_LIST_PAGE = 'ORDERS_AWAITING_LIST_PAGE';
export const ORDERS_AWAITING_LIST_PAGE_LENGTH = 'ORDERS_AWAITING_LIST_PAGE_LENGTH';

export const ORDERS_FINISHED_LIST = 'ORDERS_FINISHED_LIST';
export const ORDERS_FINISHED_LIST_LOADING = 'ORDERS_FINISHED_LIST_LOADING';
export const ORDERS_FINISHED_LIST_PAGE = 'ORDERS_FINISHED_LIST_PAGE';
export const ORDERS_FINISHED_LIST_PAGE_LENGTH = 'ORDERS_FINISHED_LIST_PAGE_LENGTH';

export const ORDERS_DATA = 'ORDERS_DATA';
export const ORDERS_DATA_LOADING = 'ORDERS_DATA_LOADING';

export const ORDERS_CONFIRM_DATA_LOADING = 'ORDERS_CONFIRM_DATA_LOADING';
export const ORDERS_PARCELS_CREATE_LOADING = 'ORDERS_PARCELS_CREATE_LOADING';


//---supply-orders
export const SUPPLY_ORDERS_NEW_LIST = 'SUPPLY_ORDERS_NEW_LIST';
export const SUPPLY_ORDERS_NEW_LIST_LOADING = 'SUPPLY_ORDERS_NEW_LIST_LOADING';
export const SUPPLY_ORDERS_NEW_DATA = 'SUPPLY_ORDERS_NEW_DATA';
export const SUPPLY_ORDERS_NEW_PRODUCT_DELETE = 'SUPPLY_ORDERS_NEW_PRODUCT_DELETE';

export const SUPPLY_ORDERS_IN_PROGRESS_LIST = 'SUPPLY_ORDERS_IN_PROGRESS_LIST';
export const SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING = 'SUPPLY_ORDERS_IN_PROGRESS_LIST_LOADING';
export const SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE = 'SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE';
export const SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE_LENGTH = 'SUPPLY_ORDERS_IN_PROGRESS_LIST_PAGE_LENGTH';

export const SUPPLY_ORDERS_FINISHED_LIST = 'SUPPLY_ORDERS_FINISHED_LIST';
export const SUPPLY_ORDERS_FINISHED_LIST_LOADING = 'SUPPLY_ORDERS_FINISHED_LIST_LOADING';
export const SUPPLY_ORDERS_FINISHED_LIST_PAGE = 'SUPPLY_ORDERS_FINISHED_LIST_PAGE';
export const SUPPLY_ORDERS_FINISHED_LIST_PAGE_LENGTH = 'SUPPLY_ORDERS_FINISHED_LIST_PAGE_LENGTH';

export const SUPPLY_ORDERS_DATA = 'SUPPLY_ORDERS_DATA';
export const SUPPLY_ORDERS_DATA_LOADING = 'SUPPLY_ORDERS_DATA_LOADING';

export const SUPPLY_ORDERS_UPDATE = 'SUPPLY_ORDERS_UPDATE';
export const SUPPLY_ORDERS_UPDATE_LOADING = 'SUPPLY_ORDERS_UPDATE_LOADING';
export const SUPPLY_ORDERS_CONFIRM_LOADING = 'SUPPLY_ORDERS_CONFIRM_LOADING';

export const SUPPLY_ORDERS_PRODUCT = 'SUPPLY_ORDERS_PRODUCT';
export const SUPPLY_ORDERS_PRODUCT_CREATING = 'SUPPLY_ORDERS_PRODUCT_CREATING';
export const SUPPLY_ORDERS_PRODUCT_UPDATING = 'SUPPLY_ORDERS_PRODUCT_UPDATING';
export const SUPPLY_ORDERS_PRODUCT_UPDATE_DATA = 'SUPPLY_ORDERS_PRODUCT_UPDATE_DATA';

//---vendors
export const VENDORS_LIST = 'VENDORS_LIST';
export const VENDORS_LIST_LOADING = 'VENDORS_LIST_LOADING';
export const VENDORS_ALL_LIST = 'VENDORS_ALL_LIST';
export const VENDORS_ALL_LIST_LOADING = 'VENDORS_ALL_LIST_LOADING';
export const VENDORS_ALL_LIST_DELETE = 'VENDORS_ALL_LIST_DELETE';
export const VENDORS_LIST_PAGE = 'VENDORS_LIST_PAGE';
export const VENDORS_LIST_PAGE_LENGTH = 'VENDORS_LIST_PAGE_LENGTH';

export const VENDORS_PRODUCT_LIST = 'VENDORS_PRODUCT_LIST';
export const VENDORS_PRODUCT_LIST_LOADING = 'VENDORS_PRODUCT_LIST_LOADING';

export const VENDORS_DATA = 'VENDORS_DATA';
export const VENDORS_DATA_LOADING = 'VENDORS_DATA_LOADING';
export const VENDORS_DATA_UPDATE_LOADING = 'VENDORS_DATA_UPDATE_LOADING';
export const VENDORS_DATA_CREATE_LOADING = 'VENDORS_DATA_CREATE_LOADING';

//---categories
export const CATEGORIES_LIST = 'CATEGORIES_LIST';
export const CATEGORIES_LIST_LOADING = 'CATEGORIES_LIST_LOADING';
export const CATEGORIES_DATA = 'CATEGORIES_DATA';
export const CATEGORIES_DATA_LOADING = 'CATEGORIES_DATA_LOADING';

//---products
export const PRODUCTS_ALL_LIST = 'PRODUCTS_ALL_LIST';
export const PRODUCTS_ALL_LIST_LOADING = 'PRODUCTS_ALL_LIST_LOADING';
export const PRODUCTS_ALL_LIST_PAGE = 'PRODUCTS_ALL_LIST_PAGE';
export const PRODUCTS_ALL_LIST_PAGE_LENGTH = 'PRODUCTS_ALL_LIST_PAGE_LENGTH';

export const PRODUCTS_LEFTOVER_LIST = 'PRODUCTS_LEFTOVER_LIST';
export const PRODUCTS_LEFTOVER_LIST_LOADING = 'PRODUCTS_LEFTOVER_LIST_LOADING';
export const PRODUCTS_LEFTOVER_LIST_PAGE = 'PRODUCTS_LEFTOVER_LIST_PAGE';
export const PRODUCTS_LEFTOVER_LIST_PAGE_LENGTH = 'PRODUCTS_LEFTOVER_LIST_PAGE_LENGTH';

export const PRODUCTS_OVER_LIST = 'PRODUCTS_OVER_LIST';
export const PRODUCTS_OVER_LIST_LOADING = 'PRODUCTS_OVER_LIST_LOADING';
export const PRODUCTS_OVER_LIST_PAGE = 'PRODUCTS_OVER_LIST_PAGE';
export const PRODUCTS_OVER_LIST_PAGE_LENGTH = 'PRODUCTS_OVER_LIST_PAGE_LENGTH';

export const PRODUCTS_DATA = 'PRODUCTS_DATA';
export const PRODUCTS_DATA_LOADING = 'PRODUCTS_DATA_LOADING';
export const PRODUCTS_CONSIGNMENT_UPDATING = 'PRODUCTS_CONSIGNMENT_UPDATING';
export const PRODUCTS_PLACE_DATA = 'PRODUCTS_PLACE_DATA';
export const PRODUCTS_DELETING = 'PRODUCTS_DELETING';

//---consignments
export const CONSIGNMENTS_EXPIRATING_LIST = 'CONSIGNMENTS_EXPIRATING_LIST';
export const CONSIGNMENTS_EXPIRATING_LIST_LOADING = 'CONSIGNMENTS_EXPIRATING_LIST_LOADING';
export const CONSIGNMENTS_EXPIRATING_LIST_PAGE = 'CONSIGNMENTS_EXPIRATING_LIST_PAGE';
export const CONSIGNMENTS_EXPIRATING_LIST_PAGE_LENGTH = 'CONSIGNMENTS_EXPIRATING_LIST_PAGE_LENGTH';

export const CONSIGNMENTS_EXPIRATED_LIST = 'CONSIGNMENTS_EXPIRATED_LIST';
export const CONSIGNMENTS_EXPIRATED_LIST_LOADING = 'CONSIGNMENTS_EXPIRATED_LIST_LOADING';
export const CONSIGNMENTS_EXPIRATED_LIST_PAGE = 'CONSIGNMENTS_EXPIRATED_LIST_PAGE';
export const CONSIGNMENTS_EXPIRATED_LIST_PAGE_LENGTH = 'CONSIGNMENTS_EXPIRATED_LIST_PAGE_LENGTH';

export const CONSIGNMENTS_DATA = 'CONSIGNMENTS_DATA';
export const CONSIGNMENTS_DATA_LOADING = 'CONSIGNMENTS_DATA_LOADING';
export const CONSIGNMENTS_DATA_UPDATING = 'CONSIGNMENTS_DATA_UPDATING';

//---leftover-types
export const LEFTOVER_TYPES_LIST = 'LEFTOVER_TYPES_LIST';
export const LEFTOVER_TYPES_LIST_LOADING = 'LEFTOVER_TYPES_LIST_LOADING';
export const LEFTOVER_TYPES_DATA = 'LEFTOVER_TYPES_DATA';
export const LEFTOVER_TYPES_DATA_LOADING = 'LEFTOVER_TYPES_DATA_LOADING';
export const LEFTOVER_TYPES_DATA_UPDATE_LOADING = 'LEFTOVER_TYPES_DATA_UPDATE_LOADING';
export const LEFTOVER_TYPES_DATA_CREATE_LOADING = 'LEFTOVER_TYPES_DATA_CREATE_LOADING';
export const LEFTOVER_TYPES_DATA_DELETE = 'LEFTOVER_TYPES_DATA_DELETE';

//---pack-types
export const PACK_TYPES_LIST = 'PACK_TYPES_LIST';
export const PACK_TYPES_LIST_LOADING = 'PACK_TYPES_LIST_LOADING';

//---box-types
export const BOX_TYPES_LIST = 'BOX_TYPES_LIST';
export const BOX_TYPES_LIST_LOADING = 'BOX_TYPES_LIST_LOADING';
export const BOX_TYPES_DATA = 'BOX_TYPES_DATA';
export const BOX_TYPES_DATA_LOADING = 'BOX_TYPES_DATA_LOADING';
export const BOX_TYPES_DATA_UPDATE_LOADING = 'BOX_TYPES_DATA_UPDATE_LOADING';
export const BOX_TYPES_DATA_CREATE_LOADING = 'BOX_TYPES_DATA_CREATE_LOADING';
export const BOX_TYPES_DATA_DELETE = 'BOX_TYPES_DATA_DELETE';

//---box
export const BOX_DATA = 'BOX_DATA';
export const BOX_DATA_UPDATING = 'BOX_DATA_UPDATING';

//---parcels
export const PARCELS_NEW_LIST = 'PARCELS_NEW_LIST';
export const PARCELS_NEW_LIST_LOADING = 'PARCELS_NEW_LIST_LOADING';
export const PARCELS_NEW_LIST_PAGE = 'PARCELS_NEW_LIST_PAGE';
export const PARCELS_NEW_LIST_PAGE_LENGTH = 'PARCELS_NEW_LIST_PAGE_LENGTH';

export const PARCELS_COLLECTING_LIST = 'PARCELS_COLLECTING_LIST';
export const PARCELS_COLLECTING_LIST_LOADING = 'PARCELS_COLLECTING_LIST_LOADING';
export const PARCELS_COLLECTING_LIST_PAGE = 'PARCELS_COLLECTING_LIST_PAGE';
export const PARCELS_COLLECTING_LIST_PAGE_LENGTH = 'PARCELS_COLLECTING_LIST_PAGE_LENGTH';

export const PARCELS_READY_LIST = 'PARCELS_READY_LIST';
export const PARCELS_READY_LIST_LOADING = 'PARCELS_READY_LIST_LOADING';
export const PARCELS_READY_LIST_PAGE = 'PARCELS_READY_LIST_PAGE';
export const PARCELS_READY_LIST_PAGE_LENGTH = 'PARCELS_READY_LIST_PAGE_LENGTH';

export const PARCELS_SENT_LIST = 'PARCELS_SENT_LIST';
export const PARCELS_SENT_LIST_LOADING = 'PARCELS_SENT_LIST_LOADING';
export const PARCELS_SENT_LIST_PAGE = 'PARCELS_SENT_LIST_PAGE';
export const PARCELS_SENT_LIST_PAGE_LENGTH = 'PARCELS_SENT_LIST_PAGE_LENGTH';

export const PARCELS_DATA = 'PARCELS_DATA';
export const PARCELS_BOX_UPDATE_DATA = 'PARCELS_BOX_UPDATE_DATA';
export const PARCELS_DATA_LOADING = 'PARCELS_DATA_LOADING';
export const PARCELS_DATA_UPDATING = 'PARCELS_DATA_UPDATING';

//---delivery
export const DELIVERY_LIST = 'DELIVERY_LIST';
export const DELIVERY_LIST_LOADING = 'DELIVERY_LIST_LOADING';
export const DELIVERY_DATA = 'DELIVERY_DATA';
export const DELIVERY_DATA_UPDATING = 'DELIVERY_DATA_UPDATING';

//---fuel-surcharge
export const FUEL_SURCHARGE_LIST = 'FUEL_SURCHARGE_LIST';
export const FUEL_SURCHARGE_LIST_LOADING = 'FUEL_SURCHARGE_LIST_LOADING';
export const FUEL_SURCHARGE_DATA = 'FUEL_SURCHARGE_DATA';
export const FUEL_SURCHARGE_DATA_LOADING = 'FUEL_SURCHARGE_DATA_LOADING';
export const FUEL_SURCHARGE_DATA_UPDATE_LOADING = 'FUEL_SURCHARGE_DATA_UPDATE_LOADING';

//---country-group
export const COUNTRY_GROUP_LIST = 'COUNTRY_GROUP_LIST';
export const COUNTRY_GROUP_LIST_LOADING = 'COUNTRY_GROUP_LIST_LOADING';
export const COUNTRY_GROUP_DATA = 'COUNTRY_GROUP_DATA';
export const COUNTRY_GROUP_DATA_LOADING = 'COUNTRY_GROUP_DATA_LOADING';
export const COUNTRY_GROUP_DATA_UPDATE = 'COUNTRY_GROUP_DATA_UPDATE';
export const COUNTRY_GROUP_DATA_UPDATE_LOADING = 'COUNTRY_GROUP_DATA_UPDATE_LOADING';
export const COUNTRY_GROUP_DATA_CREATE = 'COUNTRY_GROUP_DATA_CREATE';
export const COUNTRY_GROUP_DATA_CREATE_LOADING = 'COUNTRY_GROUP_DATA_CREATE_LOADING';
export const COUNTRY_GROUP_DATA_DELETE = 'COUNTRY_GROUP_DATA_DELETE';

//---countries
export const COUNTRIES = 'COUNTRIES';
export const COUNTRIES_LOADING = 'COUNTRIES_LOADING';
export const COUNTRY = 'COUNTRY';
export const COUNTRY_LOADING = 'COUNTRY_LOADING';
export const COUNTRY_UPDATE = 'COUNTRY_UPDATE';
export const COUNTRY_UPDATING = 'COUNTRY_UPDATING';
export const COUNTRY_CREATE = 'COUNTRY_CREATE';
export const COUNTRY_CREATING = 'COUNTRY_CREATING';
export const COUNTRY_DELETE = 'COUNTRY_DELETE';

//---delivery-regions
export const DELIVERY_REGIONS_LIST = 'DELIVERY_REGIONS_LIST';
export const DELIVERY_REGIONS_LIST_LOADING = 'DELIVERY_REGIONS_LIST_LOADING';
export const DELIVERY_REGIONS_DATA = 'DELIVERY_REGIONS_DATA';
export const DELIVERY_REGIONS_DATA_LOADING = 'DELIVERY_REGIONS_DATA_LOADING';
export const DELIVERY_REGIONS_DATA_UPDATE = 'DELIVERY_REGIONS_DATA_UPDATE';
export const DELIVERY_REGIONS_DATA_UPDATE_LOADING = 'DELIVERY_REGIONS_DATA_UPDATE_LOADING';
export const DELIVERY_REGIONS_DATA_CREATE = 'DELIVERY_REGIONS_DATA_CREATE';
export const DELIVERY_REGIONS_DATA_CREATE_LOADING = 'DELIVERY_REGIONS_DATA_CREATE_LOADING';
export const DELIVERY_REGIONS_DATA_DELETE = 'DELIVERY_REGIONS_DATA_DELETE';

//---weights
export const WEIGHTS_LIST = 'WEIGHTS_LIST';
export const WEIGHTS_LIST_LOADING = 'WEIGHTS_LIST_LOADING';
export const WEIGHTS_DATA = 'WEIGHTS_DATA';
export const WEIGHTS_DATA_LOADING = 'WEIGHTS_DATA_LOADING';
export const WEIGHTS_DATA_UPDATE = 'WEIGHTS_DATA_UPDATE';
export const WEIGHTS_DATA_UPDATE_LOADING = 'WEIGHTS_DATA_UPDATE_LOADING';
export const WEIGHTS_DATA_CREATE = 'WEIGHTS_DATA_CREATE';
export const WEIGHTS_DATA_CREATE_LOADING = 'WEIGHTS_DATA_CREATE_LOADING';
export const WEIGHTS_DATA_DELETE = 'WEIGHTS_DATA_DELETE';

//---base-rate
export const BASE_RATE_CHANGE_LOADING = 'BASE_RATE_CHANGE_LOADING';

//---delivery-group
export const DELIVERY_GROUP_CHANGE_LOADING = 'DELIVERY_GROUP_CHANGE_LOADING';

//---notifications
export const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';
export const NOTIFICATIONS_LIST_LOADING = 'NOTIFICATIONS_LIST_LOADING';
export const NOTIFICATIONS_LIST_NEWEST = 'NOTIFICATIONS_LIST_NEWEST';
export const NOTIFICATIONS_LIST_NEWEST_LOADING = 'NOTIFICATIONS_LIST_NEWEST_LOADING';
export const NOTIFICATIONS_DATA = 'NOTIFICATIONS_DATA';
export const NOTIFICATIONS_DATA_LOADING = 'NOTIFICATIONS_DATA_LOADING';
export const NOTIFICATIONS_DATA_UPDATE = 'NOTIFICATIONS_DATA_UPDATE';
export const NOTIFICATIONS_DATA_UPDATE_LOADING = 'NOTIFICATIONS_DATA_UPDATE_LOADING';
export const NOTIFICATIONS_DATA_CREATE = 'NOTIFICATIONS_DATA_CREATE';
export const NOTIFICATIONS_DATA_CREATE_LOADING = 'NOTIFICATIONS_DATA_CREATE_LOADING';
export const NOTIFICATIONS_LIST_CUSTOM_CHANGED = 'NOTIFICATIONS_LIST_CUSTOM_CHANGED';

//---logs
export const LOGS_LIST = 'LOGS_LIST';
export const LOGS_LIST_LOADING = 'LOGS_LIST_LOADING';
export const LOGS_FILTERS = 'LOGS_FILTERS';
export const LOGS_FILTERS_LOADING = 'LOGS_FILTERS_LOADING';

//---stats
export const STATS_ORDER_DATA = 'STATS_ORDER_DATA';
export const STATS_ORDER_LOADING = 'STATS_ORDER_LOADING';
export const STATS_ORDER_FETCHED = 'STATS_ORDER_FETCHED';

export const STATS_DELIVERY_DATA = 'STATS_DELIVERY_DATA';
export const STATS_DELIVERY_LOADING = 'STATS_DELIVERY_LOADING';
export const STATS_DELIVERY_FETCHED = 'STATS_DELIVERY_FETCHED';

export const STATS_PRODUCT_OVER_DATA = 'STATS_PRODUCT_OVER_DATA';
export const STATS_PRODUCT_OVER_LOADING = 'STATS_PRODUCT_OVER_LOADING';
export const STATS_PRODUCT_OVER_FETCHED = 'STATS_PRODUCT_OVER_FETCHED';

export const STATS_PRODUCT_PAY_DATA = 'STATS_PRODUCT_PAY_DATA';
export const STATS_PRODUCT_PAY_LOADING = 'STATS_PRODUCT_PAY_LOADING';
export const STATS_PRODUCT_PAY_FETCHED = 'STATS_PRODUCT_PAY_FETCHED';

export const STATS_SUPPLY_ORDER_DATA = 'STATS_SUPPLY_ORDER_DATA';
export const STATS_SUPPLY_ORDER_LOADING = 'STATS_SUPPLY_ORDER_LOADING';
export const STATS_SUPPLY_ORDER_FETCHED = 'STATS_SUPPLY_ORDER_FETCHED';

export const STATS_PACKER_LIST = 'STATS_PACKER_LIST';
export const STATS_PACKER_LIST_LOADING = 'STATS_PACKER_LIST_LOADING';
export const STATS_PACKER_DATA = 'STATS_PACKER_DATA';
export const STATS_PACKER_LOADING = 'STATS_PACKER_LOADING';
export const STATS_PACKER_FETCHED = 'STATS_PACKER_FETCHED';
//---barcodes
export const BARCODE_ID = 'BARCODE_ID';

