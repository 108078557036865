import {$http} from '@/utils/http'
import {union} from "lodash";

import {
  PROFILE_DATA_SET,
  PROFILE_LOADING_DATA
} from '../mutation-types';

import {
  PROFILE_REQUEST_USER_DATA_GET,
} from '../action-types';


const state = {
  user: null,
  isLoadingUser: false,
};

const getters = {
  user: state => state.user,
  isLoadingUser: state => state.isLoadingUser,
};

const actions = {
  [PROFILE_REQUEST_USER_DATA_GET]: async ({commit}) => {
    try {
      commit(PROFILE_LOADING_DATA, true);
      let include = 'roles,permissions';
      const response = await $http.get(`auth/me?include=${include}`);
      commit(PROFILE_DATA_SET, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PROFILE_LOADING_DATA, false);
    }
  },
};

const mutations = {
  [PROFILE_DATA_SET](state, data) {
    state.user = data;
    if (data) {
      let permissionsArrays = [];
      data.roles.data.forEach(role=>{
        permissionsArrays.push(role.permissions.data)
      })
      localStorage.setItem('user_permissions', JSON.stringify(union(...permissionsArrays)));
    }
  },
  [PROFILE_LOADING_DATA](state, status) {
    state.isLoadingUser = status;
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
