import {$http} from '@/utils/http'
import {
  ROLES_LIST,
  ROLES_LIST_LOADING,
  ROLES_DATA,
  ROLES_DATA_LOADING,
  ROLES_DATA_UPDATE_LOADING,
  ROLES_DATA_CREATE_LOADING,
  ROLES_DATA_DELETE,
} from '../mutation-types';

import {
  ROLES_REQUEST_LIST_GET,
  ROLES_REQUEST_DATA_GET,
  ROLES_REQUEST_UPDATE,
  ROLES_REQUEST_CREATE,
  ROLES_REQUEST_DELETE,
} from '../action-types';
import {findIndex} from "lodash";

const state = {
  roles: {
    list: null,
    isLoading: false
  },
  role: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  },
};

const getters = {
  roles: state => state.roles.list,
  isRolesLoading: state => state.roles.isLoading,
  role: state => state.role.data,
  isRoleLoading: state => state.role.isLoading,
  isRoleUpdating: state => state.role.isUpdating,
  isRoleCreating: state => state.role.isCreating,
};

const actions = {
  [ROLES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(ROLES_LIST_LOADING, true);
      let include = 'translations,permissions';
      const response = await $http.get(`roles?include=${include}`);
      commit(ROLES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ROLES_LIST_LOADING, false);
    }
  },

  [ROLES_REQUEST_DATA_GET]: async ({commit}, roleId) => {
    try {
      commit(ROLES_DATA_LOADING, true);
      let include = 'translations,permissions';
      const response = await $http.get(`roles/${roleId}?include=${include}`);
      commit(ROLES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ROLES_DATA_LOADING, false);
    }
  },

  [ROLES_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(ROLES_DATA_UPDATE_LOADING, true);
      return await $http.put(`roles/${data.roleId}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ROLES_DATA_UPDATE_LOADING, false);
    }
  },

  [ROLES_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(ROLES_DATA_CREATE_LOADING, true);
      return await $http.post(`roles`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ROLES_DATA_CREATE_LOADING, false);
    }
  },

  [ROLES_REQUEST_DELETE]: async ({commit}, roleId) => {
    try {
      await $http.delete(`roles/${roleId}`);
      commit(ROLES_DATA_DELETE, roleId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [ROLES_LIST](state, data) {
    state.roles.list = data;
  },
  [ROLES_LIST_LOADING](state, flag) {
    state.roles.isLoading = flag;
  },
  [ROLES_DATA](state, data) {
    state.role.data = data;
  },
  [ROLES_DATA_LOADING](state, flag) {
    state.role.isLoading = flag;
  },
  [ROLES_DATA_UPDATE_LOADING](state, flag) {
    state.role.isUpdating = flag;
  },
  [ROLES_DATA_CREATE_LOADING](state, flag) {
    state.role.isCreating = flag;
  },
  [ROLES_DATA_DELETE](state, id) {
    let index = findIndex(state.roles.list, item => {return item.id === id;});
    state.roles.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
