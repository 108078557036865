import {$http} from '@/utils/http'

import {
  PACKING_REQUEST_UPDATE,
} from '../action-types';

const actions = {
  [PACKING_REQUEST_UPDATE]: async ({commit},packingRequestId) => {
    try {
      let include = 'place,administrator,status,order,boxes,box_type,box_product,packing_requests,shelf,rack,store,product';

      const response = await $http.put(`packing_requests/${packingRequestId}?include=${include}`);
      commit('parcels/PARCELS_BOX_UPDATE_DATA', response.data.data, { root: true })
      return response;
    } catch (e) {
      throw e;
    }
  },
};

export default {
  actions
};
