import loaderComponent from '@/components/misc/loader-component/index.vue'

export default {
  name: 'appImg',
  //---component
  components: {
    loaderComponent
  },
  props:['src', 'ratio', 'sizeLoader'],
  //---data
  data () {
    return {
      isImageLoaded: false,
      isImageFailed: false,
    }
  },
  //---hooks
  created: function () {
    if (!this.src) {
      this.imageFailed();
    }
  },
  //---methods
  methods: {
    imageLoad(){
      this.isImageLoaded = true;
    },
    imageFailed(){
      this.isImageFailed = true;
      this.isImageLoaded = true;
    }
  }
}