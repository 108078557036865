import Vue from "vue";
import moment from "moment";

Vue.filter('number', function (value, fractionDigits = 0) {
  if (isNaN(parseFloat(value))) {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits
  });
  return formatter.format(value);
});

Vue.filter('price', function (value, fractionDigits = 0) {
  if (isNaN(parseFloat(value))) {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits
  });
  return formatter.format(value).replace(/,/g, ' ');
});

Vue.filter('uppercase', function (value, fractionDigits = 0) {
  if (isNaN(parseFloat(value))) {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits
  });
  return formatter.format(value);
});


Vue.filter('date', function (date, format = null) {
  let formatDate = ''
  if (date) {
    if (format) {
      formatDate = moment(date).format(format);
    } else {
      formatDate = moment(date).format('DD/MM/YYYY');
    }
  }
  return formatDate;
});