import {$http} from '@/utils/http'
import {
  STORE_LIST,
  STORE_DATA,
  STORE_DATA_LOADING,
  STORE_DATA_UPDATE_LOADING,
  STORE_DATA_CREATE_LOADING,
} from '../mutation-types';

import {
  STORE_REQUEST_LIST_GET,
  STORE_REQUEST_DATA_GET,
  STORE_REQUEST_UPDATE,
  STORE_REQUEST_CREATE,
  STORE_REQUEST_DELETE
} from '../action-types';

const state = {
  stores: {
    list: [],
    skip: 10,
  },
  store: {
    data: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
  },
};

const getters = {
  stores: state => state.stores.list,
  storesSkip: state => state.stores.skip,
  store: state => state.store.data,
  isStoreLoading: state => state.store.isLoading,
  isStoreCreating: state => state.store.isCreating,
  isStoreUpdating: state => state.store.isUpdating
};

const actions = {
  [STORE_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      let include = 'translations,child_count';
      const response = await $http.get(`stores?include=${include}`);
      commit(STORE_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [STORE_REQUEST_DATA_GET]: async ({commit}, storeId) => {
    try {
      commit(STORE_DATA_LOADING, true);
      let include = 'translations, racks_count';
      const response = await $http.get(`stores/${storeId}?include=${include}`);
      commit(STORE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STORE_DATA_LOADING, false);
    }
  },

  [STORE_REQUEST_UPDATE]: async ({commit}, {storeId, payload}) => {
    try {
      commit(STORE_DATA_UPDATE_LOADING, true);
      let include = 'translations, racks_count';
      const response = await $http.put(`stores/${storeId}?include=${include}`, payload);
      commit(STORE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STORE_DATA_UPDATE_LOADING, false);
    }
  },

  [STORE_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(STORE_DATA_CREATE_LOADING, true);
      let include = 'translations, racks_count';
      const response = await $http.post(`stores?include=${include}`, payload);
      commit(STORE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STORE_DATA_CREATE_LOADING, false);
    }
  },

  [STORE_REQUEST_DELETE]: async ({commit}, storeId) => {
    try {
      return await $http.delete(`stores/${storeId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [STORE_LIST](state, data) {
    state.stores.list = data;
  },
  [STORE_DATA](state, data) {
    state.store.data = data;
  },
  [STORE_DATA_LOADING](state, flag) {
    state.store.isLoading = flag;
  },
  [STORE_DATA_UPDATE_LOADING](state, flag) {
    state.store.isUpdating = flag;
  },
  [STORE_DATA_CREATE_LOADING](state, flag) {
    state.store.isCreating = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
