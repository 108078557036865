export default {
  toMain: 'Главная',
  toEnter: 'Войти',
  toExit: 'Выйти',
  //---auth
  authorization: 'Авторизация',
  successAuth: 'Авторизация успешна',
  resetPassword: 'Востановление пароля',
  successMessageAfterResetPassword: 'Ссылка на восстановление отправлена на указаную электронную почту',
  restorePassword: 'Изменить пароль',
  restorePasswordNote: 'вы можете изменить свой пароль для входа в личный кабинет',
  successMessageAfterRestorePassword: 'Пароль успешно изменен',
  successInviteAuthTitle: 'Вы успешно авторизованы',
  successInviteAuthText: 'Установите пароль для дальнейшей работы',
  successInvitePasswordTitle: 'Пароль успешно установлен',
  failInviteAuthTitle: 'Авторизация неуспешна',
  failInviteQueryText: 'Используйте ссылку в письме',
  failInviteAuthText: 'Свяжитесь с администратором',
  forgotPassword: 'Забыли пароль?',
  //---dashboard nav
  administration: 'Администрирование',
  workers: 'Пользователи',
  roles: 'Роли',
  permissions: 'Права доступа',
  logs: 'Логи',
  type: 'Тип',
  order: 'Заказ',
  orders: 'Заказы',
  products: 'Товары',
  client: 'Клиент',
  recipient: 'Получатель',
  suppliersRequests: 'Заказы поставщикам',
  parcels: 'Посылки',
  statistics: 'Статистика',
  settings: 'Настройки',
  leftoverType: 'Минимальный запас',
  accessDenied: 'Нужна авторизация',
  //---workers
  worker: 'Пользователь',
  workerListInfo: 'Телефон/E-mail',
  workerDeleted: 'Пользователь удален',
  workerCreated: 'Пользователь создан',
  workerUpdated: 'Данные пользователя обновлены',
  workerCreateTitle: 'Создать пользователя',
  workerUpdateTitle: 'Редактировать данные пользователя',
  workerSendInviteTitle: 'Пригласить пользователя',
  workerSendInviteText: 'Отправить приглашение на почту',
  workerInviteSendSuccess: 'Пригалашение отправлено',
  //---permissions
  permissionShowTitle: 'Просмотр данных',
  //---roles
  rolesTitle: 'Название',
  rolesDescription: 'Описание',
  rolesPermissions: 'Права доступа',
  rolesCreateTitle: 'Создать роль',
  rolesUpdateTitle: 'Редактировать роль',
  rolesDeleted: 'Роль удалена',
  rolesNotFoundText: 'Нет ролей, создайте новую роль',
  rolesNotFoundLink: 'роль',
  //---settings
  settingUpdateTitle: 'Редактировать данные',
  //---stores
  storeDeleted: 'Склад удален',
  racksCount: 'Количество стелажей',
  storeCreateTitle: 'Добавить склад',
  storeUpdateTitle: 'Редактировать склад',
  //---racks
  rackDeleted: 'Стелаж удален',
  shelfCount: 'Количество полок',
  rackCreateTitle: 'Создать стелажи',
  rackCreateSuccessMessage: 'Стелажы созданы',
  rackUpdateTitle: 'Редактировать стелаж',
  //---shelves
  shelfDeleted: 'Полка удалена',
  placeCount: 'Количество мест',
  shelfCreateTitle: 'Создать полки',
  shelfCreateSuccessMessage: 'Полки созданы',
  shelfUpdateTitle: 'Редактировать полку',
  //---places
  placeDeleted: 'Место удалено',
  placeCreateTitle: 'Создать места',
  placeCreateSuccessMessage: 'Места созданы',
  placeUpdateTitle: 'Редактировать место',
  placeEmpty: 'Место пустое',
  //---orders
  orderPrice: 'Стоимость заказа',
  orderDate: 'Дата заказа',
  orderPaymentStatus: 'Статус оплаты',
  orderAvailabilityStatus: 'Наличие товаров',
  orderAll: 'Все заказы',
  orderAvailabilityReady: 'Есть все товары',
  orderAvailabilityNeedOrder: 'Товаров недостаточно',
  orderAwaiting: 'Ожидают поставки',
  orderAwaitingPayment: 'Ожидание оплаты',
  orderPaid: 'Оплачен',
  orderNumber: '№ Заказа',
  orderShopNumber: '№ заказа в Магазине',
  orderShopNumberShort: '№ в Магазине',
  orderProductReserved: 'Зарезервирован',
  orderProductEmpty: 'Нет в наличии',
  orderProductOrdered: 'Заказан',
  orderProductReady: 'Готово',
  orderProductAmount: 'Сумма товара',
  orderConfirmSuccessMessage: 'Заказ оформлен',
  orderCreateParcelsSuccessMessage: 'Посылки для заказа созданы',
  orderDeleted: 'Заказ удален',
  orderParcelAmount: 'Стоимость посылки',
  orderSaveAddress: 'Сохранить адрес',
  orderSaveProducts: 'Сохранить список товаров',
  orderEditDeliveryCode: 'Присвоить почтовый номер',
  orderDeliveryCodeSuccessMessage: 'Почтовый номер обновлен',
  //---supplyOrders
  supplierRequest: 'Заявки',
  supplyOrderConfirmSuccessMessage: 'Заказы поставщикам созданы',
  supplyOrderArchivedSuccessMessage: 'Заказ отправлен в архив',
  supplyOrderCreate: 'Создать заказ',
  supplyOrderArrange: 'Оформить заказ',
  placeProducts: 'Разместить товар',
  //---products
  product: 'Товар',
  productsAll: 'Все товары',
  productsLeftovered: 'Заканчиваются',
  productsOvered: 'Закончились',
  productsConsignmentsExpirating: 'По сроку хранения',
  productsConsignmentsExpirated: 'Просрочены',
  productSupplyTitle: 'Заказать товар у поставщика',
  productSupplyTitleNotFoundText: 'Добавьте поставщика к текущему',
  productSupplyTitleNotFoundLink: 'товару',
  productSupplyAddSuccessMessage: 'Товар добавлен в заказ',
  productSupplyDeleteSuccessMessage: 'Товар удален из заказа',
  productVendorAddTitle: 'Добавить поставщика',
  productVendorAddSuccessMessage: 'Поставщик добавлен',
  productVendorEditTitle: 'Изменить цену поставщика',
  productVendorDeleteSuccessMessage: 'Поставщик удален',
  productVendorDefault: 'По умолчанию',
  productLeftOverTypeUndefined: 'Выберите тип остатков',
  productLeftOverTypeEditTitle: 'Изменить минимальный запас',
  productOvering: 'Товар заканчивается',
  productStatusEditTitle: 'Изменить статус товара',
  productOvered: 'Товар закончился',
  productDeletedInStore: 'Товар удален из магазина',
  productStatus: 'Статус товара',
  productStatusNew: 'Не отображать в магазине',
  productStatusVerified: 'Отображать в магазине',
  productStatusSale: 'Не заказывать у поставщика',
  productDeleteTitle: 'Удалить товар',
  productDeleteText: 'Товар будет удален из системы учета',
  productDeleteSuccessMessage: 'Товар удален',
  productEmptyVendorTitle: 'Нет поставщиков',
  productEmptyVendorText: 'Добавьте поставщиков для следующих товаров',
  productUnavailableTitle: 'Недоступные товары',
  productUnavailableText: 'Невозможно собрать посылку из-за недостачи следующих товаров на складе',
  //---consignments
  consignmentDeleteSuccessMessage: 'Партия удалена',
  //---leftoverTypes
  leftoverTypes: 'Минимальный запас',
  leftoverTypesCreateTitle: 'Создать минимальный запас',
  leftoverTypesUpdateTitle: 'Редактировать минимальный запас',
  leftoverTypesDeleted: 'Минимальный запас удален',
  //---box
  boxEditType: 'Выберите тип изменения',
  boxReplace: 'Переместить коробки',
  boxAdd: 'Добавить коробки',
  boxDelete: 'Удалить коробки',
  //---boxTypes
  boxTypes: 'Тип упаковки',
  boxTypesCreateTitle: 'Создать упаковку',
  boxTypesUpdateTitle: 'Редактировать упаковку',
  boxTypesDeleted: 'Упаковка удалена',
  //---parcel
  parcelGetInWork: 'Посылка взята на сборку',
  parcelCollected: 'Посылка собрана',
  parcelSent: 'Посылка отправлена',
  parcelDeliveryCodeTitle: 'Введите почтовый номер',
  parcelDeliveryCodeMessage: 'Почтовые индексы коробок обновлены',
  parcel: 'Посылка',
  parcelBoxCount: 'Количество коробок',
  parcelProductCount: 'Количество товара',
  parcelBoxesPlaceBtn: 'Разместить на складе',
  parcelBoxesPlaceTitle: 'Разместить коробки',
  parcelBoxesPlaceMessageTitle: 'Коробка размещена',
  parcelBoxesPlaceMessageBtn: 'Разместить еще',
  parcelPicker: 'Упаковщик',
  parcelProductAdded: 'Добавлено в коробку',
  parcelBoxType: 'Тип коробки',
  parcelBoxes: 'Коробки',
  parcelProduct: 'Товар',
  parcelBoxCollected: 'Коробка собрана',
  parcelBoxNotCollected: 'Ожидает сборки',
  parcelOrderNum: '№ Заказа',
  parcelOrderDate: 'Дата заказа',
  parcelSentDate: 'Дата отправки',
  parcelNum: '№ Посылки',
  parcelAmount: 'Стоимость посылки',
  parcelDeliveryAmount: 'Стоимость доставки',
  parcelDelivery: 'Доставка',
  parcelStatusSent: 'Отправлена',
  parcelStatusReady: 'К отправке',
  parcelBarcodeLabel: 'Просканируйте коробки посылки №:',
  parcelSomeBoxesNoDeliveryCode: 'Некоторые коробки не имеют кода доставки',
  parcelSomeBoxesNoPlace: 'Некоторые коробки не размещены на складе',
  //---vendors
  vendorsDeleted: 'Поставщик удален',
  vendorsCreated: 'Поставщик создан',
  vendorsUpdated: 'Данные поставщика обновлены',
  vendorsCreateTitle: 'Создать поставщика',
  vendorsUpdateTitle: 'Редактировать данные поставщика',
  //---barcode
  barcodeScan: 'Сканировать',
  barcodeNotRecognized: 'Штрих-код не распознан',
  barcodeRecognized: 'Штрих-код распознан',
  barcodeInvalidType: 'Не верный тип штрих-кода',
  barcodeProductLabel: 'Просканируйте товар',
  barcodeBoxLabel: 'Просканируйте коробку',
  barcodePlace: 'Просканируйте место',
  barcodeNewPlace: 'Новое место',
  barcodeProductNotFoundInSystem: 'Товар не найден в системе',
  barcodeProductNotFoundInOrder: 'Товар не найден в заказе',
  barcodeProductFoundInOrder: 'Товар найден в заказе',
  barcodePlaceAlready: 'Товар уже размещен',
  barcodePlaced: 'Товар размещен',
  barcodeAllProductsPlaced: 'Все товары размещены',
  barcodePlaceCancel: 'Размещенение товара отменено',
  barcodePlaceNotMatched: 'Место размещение не совпадает',
  barcodePlaceNotFound: 'Место не найдено',
  barcodeBoxPlaceNotFoundInParcel: 'На месте нет коробок из посылки',
  barcodeBoxNotFoundInParcel: 'Коробка не найдена в посылке',
  barcodeBoxFoundInParcel: 'Коробка найдена в посылке',
  barcodeAllBoxesPlaced: 'Все коробки размещены',
  barcodeAllParcelSent: 'Все посылки отправлены',
  barcodeBoxPlace: 'Штрих-код места',
  barcodeBoxParcel: 'Штрих-код коробки',
  //---parcelCollect
  parcelCollectTitle: 'Собрать посылку',
  parcelCollectTemplateTitle: 'Хранение упаковки',
  parcelCollectScanPlace: 'Сканировать место',
  parcelCollectScanProduct: 'Сканировать упаковку',
  parcelCollectConfirmProduct: 'Потвердить упаковку',
  parcelCollectBarcodeNotMatched: 'Штрих-коды не совпадают',
  parcelCollectPackInvalid: 'Неверный тип упаковки',
  parcelCollectPlaceBarcode: 'Штрих-код места',
  parcelCollectProductBarcode: 'Штрих-код продукта',
  parcelCollectProductInvalid: 'Неверный товар',
  parcelCollectPlaceInvalid: 'Неверно место для продукта',
  parcelCollectProductAdded: 'Товар добавлен',
  parcelCollectBox: 'Коробка собрана',
  //---delivery
  deliveryGeneral: 'Общие настройки',
  deliveryEmptyWeights: 'Добавьте вес',
  deliveryEmptyCountryGroup: 'Добавьте группу стран',
  deliveryEmptyDeliveryRegions: 'Добавьте регионы доставки',
  //---fuelSurcharge
  fuelSurcharge: 'Топливной сбор',
  fuelSurchargeUpdateTitle: 'Редактировать топливной сбор',
  //base-rate
  baseRate: 'Базовая ставка',
  baseRateCreateTitle: 'Создать базовую ставку',
  baseRateEditTitle: 'Изменить базовую ставку',
  baseRateCreateSuccessMessage: 'Базовая ставка создана',
  baseRateDeleteSuccessMessage: 'Базовая ставка удалена',
  //---weights
  weights: 'Типы масс',
  weightsCreateTitle: 'Создать массу',
  weightsUpdateTitle: 'Редактировать массу',
  weightsDeleted: 'Масса удалена',
  //delivery-groups
  deliveryGroups: 'Группы доставки',
  //country-groups
  countryGroups: 'Группа стран',
  countryGroupsCreateTitle: 'Создать группу стран',
  countryGroupsUpdateTitle: 'Редактировать группу стран',
  countryGroupsDeleted: 'Группа стран удалена',
  //countries
  country: 'Страна',
  countries: 'Страны',
  countryCode: 'Код страны',
  countryCreateTitle: 'Создать страну',
  countryUpdateTitle: 'Редактировать страну',
  countryDeleted: 'Страна удалена',
  //delivery-regions
  deliveryRegions: 'Регионы доставки',
  deliveryRegionsCreateTitle: 'Создать регион доставки',
  deliveryRegionsUpdateTitle: 'Редактировать регионы доставки',
  deliveryRegionsDeleted: 'Регион доставки удалена',
  //notifications
  notifications: 'Уведомления',
  notificationsCreateTitle: 'Создать уведомление',
  notificationsUpdateTitle: 'Редактировать уведомление',
  notificationsCreated: 'Уведомление создано',
  notificationsDeleted: 'Уведомление удалено',
  notificationsConfirmed: 'Уведомление подтверждено',
  notificationsFullList: 'Все уведомления',
  //---stats
  statsProductOver: 'Списанные товары',
  statsProductBuy: 'Сумма оплаченных заказы',
  //---btns
  btnSend: 'Отправить',
  btnBackToMain: 'Вернутся на главную',
  btnBackToList: 'Вернутся к списку',
  btnBack: 'Назад',
  btnRoleCreate: 'Создать роль',
  btnWorkerCreate: 'Создать пользователя',
  btnVendorCreate: 'Создать поставщика',
  btnStoreCreate: 'Создать склад',
  btnRacksCreate: 'Создать стелажи',
  btnShelvesCreate: 'Создать полки',
  btnPlacesCreate: 'Создать места',
  btnCreate: 'Создать',
  btnSave: 'Сохранить',
  btnUpdate: 'Обновить',
  btnArrange: 'Заказать товары',
  btnOrder: 'Заказать',
  btnAdd: 'Добавить',
  btnCancel: 'Отменить',
  btnClose: 'Закрыть',
  btnDelete: 'Удалить',
  btnCollectOrder: 'Собрать заказ',
  btnSendToArchive: 'Отправить в архив',
  btnCollectParcel: 'Собрать посылку',
  btnShowParcel: 'Показать посылку',
  btnSentParcels: 'Отправить посылки',
  btnPlace: 'Разместить',
  btnEdit: 'Редактировать',
  btnAddProduct: 'Добавить товар',
  btnMore: 'Загрузить еще',
  btnPrint: 'Распечатать',
  //---system
  noData: 'Нет данных',
  dataUpdated: 'Данные обновлены',
  pageNotFound: 'Страница не найдена',
  serverNotFound: 'Ошибка сервера',
  priceSymbol: '¥',
  weightSymbol: 'гр',
  sizeSymbol: 'см',
  sizeSymbolSmall: 'мм',
  //---simple
  fullName: 'Имя',
  name: 'Имя',
  lastName: 'Фамилия',
  password: 'Пароль',
  passwordConfirmation: 'Подтвердите пароль',
  email: 'Email',
  phone: 'Телефон',
  value: 'Значение',
  prefix: 'Префикс',
  title: 'Название',
  description: 'Описание',
  from: 'От',
  to: 'До',
  date: 'Дата',
  dueDate: 'Дата окончания',
  status: 'Статус',
  count: 'Количество',
  expectedCount: 'Ожидаемое количество',
  productsCount: 'Количество товара',
  packedCount: 'Количество упакованных',
  orderCount: 'Количество для заказа',
  orderAvailabilityCount: 'Доступно для заказа',
  availability: 'Наличие',
  amount: 'Сумма',
  price: 'Цена',
  vendor: 'Поставщик',
  vendors: 'Поставщики',
  new: 'Новые',
  collecting: 'Собираются',
  awaiting: 'Ожидаются',
  finished: 'Выполнены',
  ready: 'Готово',
  readyMany: 'Готовые',
  sentMany: 'Отправленые',
  inProgress: 'В обработке',
  administrator: 'Администратор',
  dataChanged: 'Даные изменены',
  characteristics: 'Характеристики',
  consignments: 'Партии',
  categories: 'Категории',
  priority: 'Минимальный запас',
  width: 'Ширина',
  height: 'Высота',
  length: 'Длина',
  weight: 'Масса',
  capacity: 'Вместимость',
  thickness: 'Толщина',
  place: 'Место',
  places: 'Места',
  shelf: 'Полка',
  shelves: 'Полки',
  rack: 'Стелаж',
  racks: 'Стелажи',
  store: 'Склад',
  stores: 'Склады',
  storage: 'Хранение',
  placement: 'Размещение',
  onStoreCount: 'На складе',
  reservedCount: 'Зарезервировано',
  address: 'Адресс',
  search: 'Искать',
  delivered: 'Доставлено',
  delivery: 'Доставка',
  all: 'Все',
  yes: 'Да',
  no: 'Нет',
  percent: 'Процент',
  priceRange: 'Цена (мин - макс)',
  packType: 'Упаковка',
  deliveryCode: 'Почтовый номер',
  showInStore: 'Отображать в магазине',
  expirationDate: 'Срок годности',
  expirationLeft: 'Дней осталось',
  expirationMessageLeft: 'Дней до окончания строка годности:',
  expirationMessageOver: 'Строк годности истек',
  addressesCSV: 'Адреса',
  productsCSV: 'Товары',
  changeStorage: 'Изменить хранение',
  diffExpirationDate: 'Нельзя переместить товар. Даты хранения не совпадают',
  placeStorage: 'Разместить товар',
  //---tooltip
  delete: 'Удалить',
  sendInvite: 'Отправить инвайт',
  edit: 'Редактировать',
  getBarcode: 'Получить штрих-код',
  show: 'Показать',
  orderProduct: 'Заказать продукт',
  confirm: 'Потвердить',
  showParcel: 'Показать посылку',
  getInWork: 'Взять в работу',
  add: 'Добавить',
  update: 'Обновить',
  box: 'Коробка',
  //---validation
  validationRequired: 'обязательное поле пустое',
  validationLogin: 'неправильный email или пароль',
  validationEmail: 'неправильный email',
  validationSameAs: 'поля не совпадают',
  validationMin: 'минимальное количество "{count}"',
  validationMax: 'максимальное количество "{count}"',
  validationPhone: 'неправильный номер',
  validationDate: 'неправильная дата',
  validationNumeric: 'введите количество',
  validationMinValue: 'минимальное допустимое число "{count}"',
  validationMaxValue: 'максимальное допустимое число "{count}"',
  validationInteger: 'введите целое число',
  validationDecimal: 'введите число',
  validationCurrency: 'введите валюту',
  validationAlpha: 'введите буквы',
}