export default {
  name: 'paginatorRequest',
  //---components
  components: {},
  props: {
    length: {
      type: Number,
      default: 0,
    },
    current: {
      default: 1,
    },
  },
  //---data
  data () {
    return {
      currentPageId: 1,
    }
  },
  //---hooks
  created() {},
  watch: {
    current: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.currentPageId = parseInt(val);
        }
      }
    },
  },
  //---methods
  methods: {
    changeCurrentPage(){
      this.$emit('page-id', this.currentPageId)
    }
  }
}