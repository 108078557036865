import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from "@/utils/utils";
import {
  PLACES_LIST,
  PLACES_DATA,
  PLACES_DATA_LOADING,
  PLACES_DATA_UPDATE_LOADING,
  PLACES_CREATE_LOADING,
  PLACES_CREATE_LIST,
} from '../mutation-types';

import {
  PLACES_REQUEST_LIST_GET,
  PLACES_REQUEST_DATA_GET,
  PLACES_REQUEST_UPDATE,
  PLACES_REQUEST_CREATE,
  PLACES_REQUEST_DELETE
} from '../action-types';
import {isEmpty} from "lodash";

const state = {
  places: {
    list: null,
    isCreating: false,
    skip: 10,
  },
  place: {
    data: null,
    isLoading: false,
    isUpdating: false,
  },
  placesCreate: {
    isLoading: false,
    list: null
  }
};

const getters = {
  places: state => state.places.list,
  placesSkip: state => state.places.skip,
  place: state => state.place.data,
  isPlaceLoading: state => state.place.isLoading,
  isPlaceUpdating: state => state.place.isUpdating,
  isPlacesCreateLoading: state => state.placesCreate.isLoading,
  placesCreated: state => state.placesCreate.list
};

const actions = {
  [PLACES_REQUEST_LIST_GET]: async ({commit}, params) => {
    try {
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      let include = 'translations,consignments_places,boxes_places,shelf,rack,store,type';
      const response = await $http.get(`places?include=${include}${query}`);
      commit(PLACES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [PLACES_REQUEST_DATA_GET]: async ({commit}, placeId) => {
    try {
      commit(PLACES_DATA_LOADING, true);
      let include = 'translations,shelf,rack';
      const response = await $http.get(`places/${placeId}?include=${include}`);
      commit(PLACES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PLACES_DATA_LOADING, false);
    }
  },

  [PLACES_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(PLACES_DATA_UPDATE_LOADING, true);
      let include = 'translations,shelf,rack';
      const response = await $http.put(`places/${data.placeId}?include=${include}`, data.payload);
      commit(PLACES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PLACES_DATA_UPDATE_LOADING, false);
    }
  },

  [PLACES_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(PLACES_CREATE_LOADING, true);
      let include = 'translations,shelf,rack';
      const response = await $http.post(`places?include=${include}`, payload);
      commit(PLACES_CREATE_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PLACES_CREATE_LOADING, false);
    }
  },

  [PLACES_REQUEST_DELETE]: async ({commit}, placeId) => {
    try {
      return await $http.delete(`places/${placeId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [PLACES_LIST](state, data) {
    state.places.list = data;
  },
  [PLACES_DATA](state, data) {
    state.place.data = data;
  },
  [PLACES_DATA_LOADING](state, flag) {
    state.place.isLoading = flag;
  },
  [PLACES_DATA_UPDATE_LOADING](state, flag) {
    state.place.isUpdating = flag;
  },
  [PLACES_CREATE_LOADING](state, flag) {
    state.placesCreate.isLoading = flag;
  },
  [PLACES_CREATE_LIST](state, list) {
    state.placesCreate.list = list;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
