// import components
import Vue from 'vue';

import LoaderComponent from '@/components/misc/loader-component/index.vue';
import PaginationServer from '@/components/misc/pagination-server/index.vue';
import PaginationFront from '@/components/misc/pagination-front/index.vue';
import AppImg from '@/components/misc/app-img/index.vue';

import VueSmoothScroll from 'vue2-smooth-scroll'

// use global components
Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
});
Vue.component('loader-component', LoaderComponent);
Vue.component('pagination-server', PaginationServer);
Vue.component('pagination-front', PaginationFront);
Vue.component('app-img', AppImg);