import Vue from 'vue'
import Router from 'vue-router';
import store from '@/store'

Vue.use(Router);

import {isHasPermission} from '@/utils/utils';

const isAuthenticated = (to, from, next) => {
  store.state.auth.isAuthenticated ? next() : next({name: 'login' });
};

const redirectIfAuthenticated = (to, from, next) => {
  store.state.auth.isAuthenticated ? next({ name: 'dashboard' }) : next();
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?/',
      name: 'login',
      component: () => import('@/modules/auth/login/index.vue'),
      beforeEnter: redirectIfAuthenticated
    },
    {
      path: '/:lang?/password-reset',
      name: 'password-reset',
      component: () => import('@/modules/auth/password-reset/index.vue'),
    },
    {
      path: '/:lang?/password-restore',
      name: 'password-restore',
      component: () => import('@/modules/auth/password-restore/index.vue'),
    },
    {
      path: '/:lang?/invite-login',
      name: 'invite-login',
      component: () => import('@/modules/auth/invite-login/index.vue'),
      beforeEnter: redirectIfAuthenticated
    },
    {
      path: '/:lang?/barcodes',
      name: 'barcodes',
      component: () => import('@/modules/barcodes/index.vue'),
    },
    {
      path: '/:lang?/dashboard',
      redirect: {name: 'barcode'},
      name: 'dashboard',
      component: () => import('@/modules/dashboard/index.vue'),
      beforeEnter: isAuthenticated,
      children: [
        {
          path: 'barcode',
          name: 'barcode',
          component: () => import('@/modules/dashboard/pages/barcode/index.vue'),
        },
        {
          path: 'admin/workers',
          name: 'workers.list',
          component: () => import('@/modules/dashboard/pages/administration/workers/list/index.vue'),
          meta: { permission: 'administrators_access' }
        },
        {
          path: 'admin/workers/create',
          name: 'workers.create',
          component: () => import('@/modules/dashboard/pages/administration/workers/create/index.vue'),
          meta: { permission: 'administrators_edit' }
        },
        {
          path: 'admin/workers/edit/:workerId',
          name: 'workers.edit',
          component: () => import('@/modules/dashboard/pages/administration/workers/edit/index.vue'),
          meta: { permission: 'administrators_edit' }
        },
        {
          path: 'admin/vendors',
          name: 'vendors.list',
          component: () => import('@/modules/dashboard/pages/vendors/list/index.vue'),
          meta: { permission: 'vendors_access' }
        },
        {
          path: 'admin/vendors/create',
          name: 'vendors.create',
          component: () => import('@/modules/dashboard/pages/vendors/create/index.vue'),
          meta: { permission: 'vendors_edit' }
        },
        {
          path: 'admin/vendors/edit/:vendorId',
          name: 'vendors.edit',
          component: () => import('@/modules/dashboard/pages/vendors/edit/index.vue'),
          meta: { permission: 'vendors_edit' }
        },
        {
          path: 'admin/roles',
          name: 'roles.list',
          component: () => import('@/modules/dashboard/pages/administration/roles/list/index.vue'),
          meta: { permission: 'roles_access' }
        },
        {
          path: 'admin/roles/create',
          name: 'roles.create',
          component: () => import('@/modules/dashboard/pages/administration/roles/create/index.vue'),
          meta: { permission: 'roles_edit' }
        },
        {
          path: 'admin/roles/edit/:roleId',
          name: 'roles.edit',
          component: () => import('@/modules/dashboard/pages/administration/roles/edit/index.vue'),
          meta: { permission: 'roles_edit' }
        },
        {
          path: 'admin/permissions',
          name: 'permissions.list',
          component: () => import('@/modules/dashboard/pages/administration/permissions/list/index.vue'),
          meta: { permission: 'permissions_access' }
        },
        {
          path: 'admin/logs',
          name: 'logs.list',
          component: () => import('@/modules/dashboard/pages/administration/logs/index.vue'),
        },
        {
          path: 'notifications',
          name: 'notifications.list',
          component: () => import('@/modules/dashboard/pages/notifications/list/index.vue'),
          meta: { permission: 'notifications_access' }
        },
        {
          path: 'notifications/create',
          name: 'notifications.create',
          component: () => import('@/modules/dashboard/pages/notifications/create/index.vue'),
          meta: { permission: 'notifications_edit' }
        },
        {
          path: 'notifications/edit/:notificationId',
          name: 'notifications.edit',
          component: () => import('@/modules/dashboard/pages/notifications/edit/index.vue'),
          meta: { permission: 'notifications_edit' }
        },
        {
          path: 'orders',
          name: 'orders.list',
          component: () => import('@/modules/dashboard/pages/orders/list/index.vue'),
          meta: { permission: 'orders_access' }
        },
        {
          path: 'orders/:orderId',
          name: 'orders.show',
          component: () => import('@/modules/dashboard/pages/orders/show/index.vue'),
          meta: { permission: 'orders_access' }
        },
        {
          path: 'supply-orders',
          name: 'supplyOrders.list',
          component: () => import('@/modules/dashboard/pages/supply-orders/list/index.vue'),
          meta: { permission: 'supply_orders_access' }
        },
        {
          path: 'supply-orders/:supplyOrderId',
          name: 'supplyOrders.show',
          component: () => import('@/modules/dashboard/pages/supply-orders/show/index.vue'),
          meta: { permission: 'supply_orders_access' }
        },
        {
          path: 'products',
          name: 'products.list',
          component: () => import('@/modules/dashboard/pages/products/list/index.vue'),
          meta: { permission: 'products_access' }
        },
        {
          path: 'products/:productId',
          name: 'products.show',
          component: () => import('@/modules/dashboard/pages/products/show/index.vue'),
          meta: { permission: 'products_access' }
        },
        {
          path: 'parcels',
          name: 'parcels.list',
          component: () => import('@/modules/dashboard/pages/parcels/list/index.vue'),
          meta: { permission: 'parcels_access' }
        },
        {
          path: 'storage/stores',
          name: 'stores.list',
          component: () => import('@/modules/dashboard/pages/storage/stores/list/index.vue'),
          meta: { permission: 'stores_access' }
        },
        {
          path: 'storage/stores/edit/:storeId',
          name: 'stores.edit',
          component: () => import('@/modules/dashboard/pages/storage/stores/edit/index.vue'),
          meta: { permission: 'stores_edit' }
        },
        {
          path: 'storage/stores/create',
          name: 'stores.create',
          component: () => import('@/modules/dashboard/pages/storage/stores/create/index.vue'),
          meta: { permission: 'stores_edit' }
        },
        {
          path: 'storage/racks',
          name: 'racks.list',
          component: () => import('@/modules/dashboard/pages/storage/racks/list/index.vue'),
          meta: { permission: 'racks_access' }
        },
        {
          path: 'storage/racks/edit/:storeId',
          name: 'racks.edit',
          component: () => import('@/modules/dashboard/pages/storage/racks/edit/index.vue'),
          meta: { permission: 'racks_edit' }
        },
        {
          path: 'storage/shelves',
          name: 'shelves.list',
          component: () => import('@/modules/dashboard/pages/storage/shelves/list/index.vue'),
          meta: { permission: 'shelves_access' }
        },
        {
          path: 'storage/shelves/edit/:shelfId',
          name: 'shelves.edit',
          component: () => import('@/modules/dashboard/pages/storage/shelves/edit/index.vue'),
          meta: { permission: 'shelves_edit' }
        },
        {
          path: 'storage/places',
          name: 'places.list',
          component: () => import('@/modules/dashboard/pages/storage/places/list/index.vue'),
          meta: { permission: 'places_access' }
        },
        {
          path: 'storage/places/edit/:placeId',
          name: 'places.edit',
          component: () => import('@/modules/dashboard/pages/storage/places/edit/index.vue'),
          meta: { permission: 'places_edit' }
        },
        {
          path: 'delivery/:deliveryId',
          name: 'delivery.show',
          component: () => import('@/modules/dashboard/pages/delivery/show/index.vue'),
          meta: { permission: 'delivery_methods_access' }
        },
        {
          path: 'settings/leftovertypes',
          name: 'leftoverTypes.list',
          component: () => import('@/modules/dashboard/pages/settings/leftover-types/list/index.vue'),
          meta: { permission: 'leftover_types_access' }
        },
        {
          path: 'settings/leftovertypes/create',
          name: 'leftoverTypes.create',
          component: () => import('@/modules/dashboard/pages/settings/leftover-types/create/index.vue'),
          meta: { permission: 'leftover_types_edit' }
        },
        {
          path: 'settings/leftovertypes/edit/:leftoverTypeId',
          name: 'leftoverTypes.edit',
          component: () => import('@/modules/dashboard/pages/settings/leftover-types/edit/index.vue'),
          meta: { permission: 'leftover_types_edit' }
        },
        {
          path: 'settings/box-types',
          name: 'boxTypes.list',
          component: () => import('@/modules/dashboard/pages/settings/box-types/list/index.vue'),
          meta: { permission: 'box_types_access' }
        },
        {
          path: 'settings/box-types/create',
          name: 'boxTypes.create',
          component: () => import('@/modules/dashboard/pages/settings/box-types/create/index.vue'),
          meta: { permission: 'box_types_edit' }
        },
        {
          path: 'settings/box-types/edit/:boxTypeId',
          name: 'boxTypes.edit',
          component: () => import('@/modules/dashboard/pages/settings/box-types/edit/index.vue'),
          meta: { permission: 'box_types_edit' }
        },
        {
          path: 'settings/fuel-surcharge',
          name: 'fuelSurcharge.list',
          component: () => import('@/modules/dashboard/pages/settings/fuel-surcharge/list/index.vue'),
          meta: { permission: 'fuel_surcharges_access' }
        },
        {
          path: 'settings/fuel-surcharge/edit/:fuelSurchargeId',
          name: 'fuelSurcharge.edit',
          component: () => import('@/modules/dashboard/pages/settings/fuel-surcharge/edit/index.vue'),
          meta: { permission: 'fuel_surcharges_edit' }
        },
        {
          path: 'adjustments',
          name: 'adjustments.list',
          component: () => import('@/modules/dashboard/pages/adjustments/list/index.vue'),
        },
        {
          path: 'adjustments/edit/:adjustmentId',
          name: 'adjustments.edit',
          component: () => import('@/modules/dashboard/pages/adjustments/edit/index.vue'),
        },
        {
          path: 'settings/weights',
          name: 'weights.list',
          component: () => import('@/modules/dashboard/pages/settings/weights/list/index.vue'),
          meta: { permission: 'weights_access' }
        },
        {
          path: 'settings/weights/create',
          name: 'weights.create',
          component: () => import('@/modules/dashboard/pages/settings/weights/create/index.vue'),
          meta: { permission: 'weights_edit' }
        },
        {
          path: 'settings/weights/edit/:weightId',
          name: 'weights.edit',
          component: () => import('@/modules/dashboard/pages/settings/weights/edit/index.vue'),
          meta: { permission: 'weights_edit' }
        },
        {
          path: 'settings/country-groups',
          name: 'countryGroups.list',
          component: () => import('@/modules/dashboard/pages/settings/country-groups/list/index.vue'),
          meta: { permission: 'country_groups_access' }
        },
        {
          path: 'settings/country-groups/create',
          name: 'countryGroups.create',
          component: () => import('@/modules/dashboard/pages/settings/country-groups/create/index.vue'),
          meta: { permission: 'country_groups_edit' }
        },
        {
          path: 'settings/country-groups/edit/:countryGroupId',
          name: 'countryGroups.edit',
          component: () => import('@/modules/dashboard/pages/settings/country-groups/edit/index.vue'),
          meta: { permission: 'country_groups_edit' }
        },
        {
          path: 'settings/countries',
          name: 'countries.list',
          component: () => import('@/modules/dashboard/pages/settings/countries/list/index.vue'),
          meta: { permission: 'countries_access' }
        },
        {
          path: 'settings/countries/create',
          name: 'countries.create',
          component: () => import('@/modules/dashboard/pages/settings/countries/create/index.vue'),
          meta: { permission: 'countries_edit' }
        },
        {
          path: 'settings/countries/edit/:countryId',
          name: 'countries.edit',
          component: () => import('@/modules/dashboard/pages/settings/countries/edit/index.vue'),
          meta: { permission: 'countries_edit' }
        },
        {
          path: 'settings/delivery-regions',
          name: 'deliveryRegions.list',
          component: () => import('@/modules/dashboard/pages/settings/delivery-regions/list/index.vue'),
          meta: { permission: 'delivery_regions_access' }
        },
        {
          path: 'settings/delivery-regions/create',
          name: 'deliveryRegions.create',
          component: () => import('@/modules/dashboard/pages/settings/delivery-regions/create/index.vue'),
          meta: { permission: 'delivery_regions_edit' }
        },
        {
          path: 'settings/delivery-regions/edit/:deliveryRegionId',
          name: 'deliveryRegions.edit',
          component: () => import('@/modules/dashboard/pages/settings/delivery-regions/edit/index.vue'),
          meta: { permission: 'delivery_regions_edit' }
        },
        {
          path: 'statistics',
          name: 'statistics',
          component: () => import('@/modules/dashboard/pages/statistics/index.vue'),
        },
      ]
    },
    {
      path: '*',
      redirect: {name: 'not-found'}
    },
    {
      path: '/:lang?/page-not-found', //---404
      name: 'not-found',
      component: () => import('@/modules/system/page-not-found/index.vue'),
    },
    {
      path: '/:lang?/server-not-found', //---500
      name: 'server-not-found',
      component: () => import('@/modules/system/server-not-found/index.vue'),
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollTop = 0;
        return {x: 0, y: 0}
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  }
  if (to.meta && to.meta.permission) {
    if (store.state.auth.isAuthenticated && !isHasPermission(to.meta.permission)) {
      next('/barcode');
    }
  }
  next();
});

export default router;
