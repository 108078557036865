import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'notifications',
  data() {
    return {
      interval: null,
      intervalTime: 600000//10 min
    }
  },
  components: {},
  created() {
    this.initFetching();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      notificationsNewest: 'notifications/notificationsNewest',
      notificationsNewestMeta: 'notifications/notificationsNewestMeta',
      isNotificationsNewestLoading: 'notifications/isNotificationsNewestLoading',
      isNotificationCustomChanged: 'notifications/isNotificationCustomChanged',
    }),
  },
  watch: {
    isNotificationCustomChanged (val) {
      if (val) {
        clearInterval(this.interval);
        this.initFetching();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchNotificationsNewest: 'notifications/NOTIFICATIONS_REQUEST_LIST_NEWEST_GET',
      confirmNotification: 'notifications/NOTIFICATIONS_REQUEST_CONFIRM'
    }),
    initFetching() {
      this.fetchNotificationsNewest();
      this.interval = setInterval(() => {
        this.fetchNotificationsNewest();
      }, this.intervalTime);
    },
    confirm(item) {
      this.$set(item, 'isLoading', true);
      this.confirmNotification({
        notificationId: item.id,
        payload: {
          status: 1
        }
      }).then(() => {
        this.$toasted.success(this.$t('notificationsConfirmed'));
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
        this.$set(item, 'isLoading', false);
      });
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
}
