import {$http} from '@/utils/http'
import {
  PERMISSIONS_LIST,
  PERMISSIONS_LIST_LOADING,
  PERMISSIONS_DATA,
  PERMISSIONS_DATA_LOADING,
} from '../mutation-types';

import {
  PERMISSIONS_REQUEST_LIST_GET,
  PERMISSIONS_REQUEST_DATA_GET,
} from '../action-types';

const state = {
  permissions: {
    list: null,
    isLoading: false
  },
  permission: {
    data: null,
    isLoading: false
  },
};

const getters = {
  permissions: state => state.permissions.list,
  isPermissionsLoading: state => state.permissions.isLoading,
  permission: state => state.permission.data,
  isPermissionLoading: state => state.permission.isLoading,
};

const actions = {
  [PERMISSIONS_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(PERMISSIONS_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`permissions?include=${include}`);
      commit(PERMISSIONS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PERMISSIONS_LIST_LOADING, false);
    }
  },

  [PERMISSIONS_REQUEST_DATA_GET]: async ({commit}, permissionId) => {
    try {
      commit(PERMISSIONS_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`permissions/${permissionId}?include=${include}`);
      commit(PERMISSIONS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PERMISSIONS_DATA_LOADING, false);
    }
  },
};

const mutations = {

  [PERMISSIONS_LIST](state, data) {
    state.permissions.list = data;
  },
  [PERMISSIONS_LIST_LOADING](state, flag) {
    state.permissions.isLoading = flag;
  },
  [PERMISSIONS_DATA](state, data) {
    state.permission.data = data;
  },
  [PERMISSIONS_DATA_LOADING](state, flag) {
    state.permission.isLoading = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
