import {$http} from '@/utils/http'
import {isEmpty,cloneDeep} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";
import moment from "moment";
import {CONSTANTS} from '@/utils/constants';

import {
  PRODUCTS_ALL_LIST,
  PRODUCTS_ALL_LIST_LOADING,
  PRODUCTS_ALL_LIST_PAGE,
  PRODUCTS_ALL_LIST_PAGE_LENGTH,

  PRODUCTS_LEFTOVER_LIST,
  PRODUCTS_LEFTOVER_LIST_LOADING,
  PRODUCTS_LEFTOVER_LIST_PAGE,
  PRODUCTS_LEFTOVER_LIST_PAGE_LENGTH,

  PRODUCTS_OVER_LIST,
  PRODUCTS_OVER_LIST_LOADING,
  PRODUCTS_OVER_LIST_PAGE,
  PRODUCTS_OVER_LIST_PAGE_LENGTH,

  PRODUCTS_DATA,
  PRODUCTS_DATA_LOADING,
  PRODUCTS_CONSIGNMENT_UPDATING,
  PRODUCTS_PLACE_DATA,
  PRODUCTS_DELETING
} from '../mutation-types';

import {
  PRODUCTS_REQUEST_ALL_LIST_GET,
  PRODUCTS_REQUEST_LEFTOVER_LIST_GET,
  PRODUCTS_REQUEST_OVER_LIST_GET,
  PRODUCTS_REQUEST_DATA_GET,
  PRODUCTS_REQUEST_DATA_UPDATE,
  PRODUCTS_REQUEST_CONSIGNMENT_UPDATE,
  PRODUCTS_REQUEST_PLACE_DATA_GET,
  PRODUCTS_REQUEST_DATA_DELETE,
} from '../action-types';

const state = {
  productsAll: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
    search: '',
    categoryId: null
  },
  productsLeftover: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
    search: '',
  },
  productsOver: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
    search: '',
  },
  product: {
    data: null,
    isLoading: false,
    isDeleting: false,
    isConsignmentUpdating: false,
    statuses: [
      {
        name: 'productStatusNew',
        value: 'new'
      },
      {
        name: 'productStatusVerified',
        value: 'verified'
      },
      {
        name: 'productStatusSale',
        value: 'sale'
      }
    ]
  },
  productPlace: {
    data: null,
    isLoading: false
  },
};

const getters = {
  productsAll: state => state.productsAll.list,
  isProductsAllLoading: state => state.productsAll.isLoading,
  productsAllPage: state => state.productsAll.page,
  productsAllPageLength: state => state.productsAll.pageLength,

  productsLeftover: state => state.productsLeftover.list,
  isProductsLeftoverLoading: state => state.productsLeftover.isLoading,
  productsLeftoverPage: state => state.productsLeftover.page,
  productsLeftoverPageLength: state => state.productsLeftover.pageLength,

  productsOver: state => state.productsOver.list,
  isProductsOverLoading: state => state.productsOver.isLoading,
  productsOverPage: state => state.productsOver.page,
  productsOverPageLength: state => state.productsOver.pageLength,

  product: state => state.product.data,
  isProductLoading: state => state.product.isLoading,
  isProductDeleting: state => state.product.isDeleting,
  isProductConsignmentUpdating: state => state.product.isConsignmentUpdating,
  productStatuses: state => state.product.statuses,

  productPlace: state => state.productPlace.data,

};

const actions = {
  [PRODUCTS_REQUEST_ALL_LIST_GET]: async ({commit}, params ) => {
    try {
      commit(PRODUCTS_ALL_LIST_LOADING, true);
      commit(PRODUCTS_ALL_LIST_PAGE, params.page);

      let include = 'include=categories,vendors,consignments,places,leftover_type';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';

      const response = await $http.get(`products?${include}&per_page=${state.productsAll.per_page}${query}`);

      commit(PRODUCTS_ALL_LIST, response.data.data);
      commit(PRODUCTS_ALL_LIST_PAGE_LENGTH, response.data.meta.last_page);

      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PRODUCTS_ALL_LIST_LOADING, false);
    }
  },

  [PRODUCTS_REQUEST_LEFTOVER_LIST_GET]: async ({commit}, params ) => {
    try {
      commit(PRODUCTS_LEFTOVER_LIST_LOADING, true);
      commit(PRODUCTS_LEFTOVER_LIST_PAGE, params.page);

      let include = 'include=categories,vendors,consignments,places,leftover_type';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';

      const response = await $http.get(`products?${include}&per_page=${state.productsLeftover.per_page}&leftover_sort=1${query}`);

      commit(PRODUCTS_LEFTOVER_LIST, response.data.data);
      commit(PRODUCTS_LEFTOVER_LIST_PAGE_LENGTH, response.data.meta.last_page);

      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PRODUCTS_LEFTOVER_LIST_LOADING, false);
    }
  },

  [PRODUCTS_REQUEST_OVER_LIST_GET]: async ({commit}, params ) => {
    try {
      commit(PRODUCTS_OVER_LIST_LOADING, true);
      commit(PRODUCTS_OVER_LIST_PAGE, params.page);

      let include = 'include=categories,vendors,consignments,places,leftover_type';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';

      const response = await $http.get(`products?${include}&per_page=${state.productsOver.per_page}&are_over=1${query}`);

      commit(PRODUCTS_OVER_LIST, response.data.data);
      commit(PRODUCTS_OVER_LIST_PAGE_LENGTH, response.data.meta.last_page);

      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PRODUCTS_OVER_LIST_LOADING, false);
    }
  },


  [PRODUCTS_REQUEST_DATA_GET]: async ({commit}, productId) => {
    try {
      commit(PRODUCTS_DATA_LOADING, true);
      let include = 'categories,vendors,consignments,leftover_type,places,rack,shelf,store,pack_type,box_types';
      const response = await $http.get(`products/${productId}?include=${include}`);
      commit(PRODUCTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PRODUCTS_DATA_LOADING, false);
    }
  },

  [PRODUCTS_REQUEST_DATA_UPDATE]: async ({commit}, {productId, payload}) => {
    try {
      let include = 'categories,vendors,consignments,leftover_type,places,rack,shelf,store,pack_type,box_types';
      const response = await $http.put(`products/${productId}?include=${include}`, payload);
      commit(PRODUCTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [PRODUCTS_REQUEST_CONSIGNMENT_UPDATE]: async ({commit, dispatch}, {productId, consignmentId, payload}) => {
    commit(PRODUCTS_CONSIGNMENT_UPDATING, true);
    await dispatch('consignments/CONSIGNMENTS_REQUEST_DATA_UPDATE',{consignmentId, payload},{ root: true } );

    try {
      let include = 'categories,vendors,consignments,leftover_type,places,rack,shelf,store,pack_type,box_types';
      const response = await $http.get(`products/${productId}?include=${include}`);
      commit(PRODUCTS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PRODUCTS_CONSIGNMENT_UPDATING, false);
    }
  },

  [PRODUCTS_REQUEST_PLACE_DATA_GET]: async ({commit}, placeId) => {
    try {
      let include = 'categories,vendors,consignments,leftover_type,places,rack,shelf,store,pack_type,box_types';
      const response = await $http.get(`products?place_id=${placeId}&include=${include}`);
      commit(PRODUCTS_PLACE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [PRODUCTS_REQUEST_DATA_DELETE]: async ({commit}, productId) => {
    try {
      return await $http.delete(`products/${productId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [PRODUCTS_ALL_LIST](state, list) {
    state.productsAll.list = list;
  },
  [PRODUCTS_ALL_LIST_LOADING](state, flag) {
    state.productsAll.isLoading = flag;
  },
  [PRODUCTS_ALL_LIST_PAGE](state, number) {
    state.productsAll.page = parseInt(number);
  },
  [PRODUCTS_ALL_LIST_PAGE_LENGTH](state, count) {
    state.productsAll.pageLength = count;
  },

  [PRODUCTS_LEFTOVER_LIST](state, list) {
    state.productsLeftover.list = list;
  },
  [PRODUCTS_LEFTOVER_LIST_LOADING](state, flag) {
    state.productsLeftover.isLoading = flag;
  },
  [PRODUCTS_LEFTOVER_LIST_PAGE](state, number) {
    state.productsLeftover.page = parseInt(number);
  },
  [PRODUCTS_LEFTOVER_LIST_PAGE_LENGTH](state, count) {
    state.productsLeftover.pageLength = count;
  },

  [PRODUCTS_OVER_LIST](state, list) {
    state.productsOver.list = list;
  },
  [PRODUCTS_OVER_LIST_LOADING](state, flag) {
    state.productsOver.isLoading = flag;
  },
  [PRODUCTS_OVER_LIST_PAGE](state, number) {
    state.productsOver.page = parseInt(number);
  },
  [PRODUCTS_OVER_LIST_PAGE_LENGTH](state, count) {
    state.productsOver.pageLength = count;
  },

  [PRODUCTS_DATA](state, data) {
    if (data.consignments.data.length > 0) {
      data.consignments.data = data.consignments.data.filter( item => {return item.places.data.length})
      data.consignments.data.forEach(item => {
        if (item.expiration_date && item.expiration_date.length > 0) {
          item.expirationLeft = moment(item.expiration_date).diff(moment(), 'days');
          item.isExpirationWarning = item.expirationLeft <= CONSTANTS.APP_CONSIGNMENTS_EXPIRATION_LEFT;
        } else {
          item.expirationLeft = null;
          item.isExpirationWarning = null
        }
        item.storage = _prepareProductStorage(item.places.data[0])
      })
    }
    state.product.data = data;
  },
  [PRODUCTS_DATA_LOADING](state, flag) {
    state.product.isLoading = flag;
  },
  [PRODUCTS_CONSIGNMENT_UPDATING](state, flag) {
    state.product.isConsignmentUpdating = flag;
  },

  [PRODUCTS_PLACE_DATA](state, data) {
    state.productPlace.data = data.length > 0 ? data[0] : null;
  },
  [PRODUCTS_DELETING](state, flag) {
    state.product.isDeleting = flag;
  },
};

const _prepareProductStorage = (data) => {
  if (data) {
    let storage = {};
    storage.store = cloneDeep(data.shelf.data.rack.data.store.data);
    storage.rack = cloneDeep(data.shelf.data.rack.data);
    delete storage.rack.store;
    storage.shelf = cloneDeep(data.shelf.data);
    delete storage.shelf.rack;
    storage.place = cloneDeep(data);
    delete storage.place.shelf;
    return storage
  } else {
    return null
  }
}


export default {
  state,
  getters,
  actions,
  mutations,
};