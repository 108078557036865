import {$http} from '@/utils/http';
import {
  AUTH_SET_TOKEN,
  AUTH_REMOVE_TOKEN,
  AUTH_INVITE_LOADING,
  AUTH_PASSWORD_RESTORE_LOADING,
  AUTH_INVITE_PASSWORD_RESTORE_LOADING,
} from '../mutation-types';

import {
  AUTH_REQUEST_LOGIN,
  AUTH_REQUEST_LOGOUT,
  AUTH_REQUEST_PASSWORD_RESET,
  AUTH_REQUEST_PASSWORD_RESTORE,
  AUTH_REQUEST_INVITE,
  AUTH_REQUEST_INVITE_LOGIN,
  AUTH_REQUEST_INVITE_PASSWORD_SET,
} from '../action-types';

const state = {
  isAuthenticated: false,
  isInviteLoading: false,
  isPasswordRestoreLoading: false,
  isInvitePasswordRestoreLoading: false,
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  isInviteLoading: state => state.isInviteLoading,
  isPasswordRestoreLoading: state => state.isPasswordRestoreLoading,
  isInvitePasswordRestoreLoading: state => state.isInvitePasswordRestoreLoading,
};

const actions = {
  [AUTH_REQUEST_LOGIN]: async ({commit}, payload) => {
    try {
      const result = await $http.post('auth/login', payload);
      commit(AUTH_SET_TOKEN, result.data.data.access_token);
    } catch (e) {
      throw e;
    }
  },
  [AUTH_REQUEST_LOGOUT]: async ({commit}) => {
    try {
      return await $http.post('auth/logout');
    }catch (e) {
      throw e;
    }finally {
      commit(AUTH_REMOVE_TOKEN);
    }
  },
  [AUTH_REQUEST_PASSWORD_RESET]: async ({commit}, payload) => {
    try {
      return await $http.post('auth/password/reset', payload);
    }catch (e) {
      throw e;
    }
  },
  [AUTH_REQUEST_PASSWORD_RESTORE]: async ({commit}, payload) => {
    try {
      commit(AUTH_PASSWORD_RESTORE_LOADING, true);
      return await $http.post('auth/password/restore', payload);
    }catch (e) {
      throw e;
    }finally {
      commit(AUTH_PASSWORD_RESTORE_LOADING, false);
    }
  },
  [AUTH_REQUEST_INVITE]: async ({commit}, payload) => {
    try {
      commit(AUTH_INVITE_LOADING, true);
      return await $http.post('auth/invite', payload);
    }catch (e) {
      throw e;
    }finally {
      commit(AUTH_INVITE_LOADING, false);
    }
  },
  [AUTH_REQUEST_INVITE_LOGIN]: async ({commit}, payload) => {
    try {
      const response = await $http.post('auth/invite/login', payload);
      commit(AUTH_SET_TOKEN, response.data.data.access_token);
      return response
    } catch (e) {
      throw e;
    }
  },
  [AUTH_REQUEST_INVITE_PASSWORD_SET]: async ({commit}, payload) => {
    try {
      commit(AUTH_INVITE_PASSWORD_RESTORE_LOADING, true);
      const response = await $http.post('auth/invite/password', payload);
      return response
    }catch (e) {
      throw e;
    }finally {
      commit(AUTH_INVITE_PASSWORD_RESTORE_LOADING, false);
    }
  },
};

const mutations = {
  [AUTH_SET_TOKEN](state, token) {
    localStorage.setItem('access_token', token);
    state.isAuthenticated = true;
  },
  [AUTH_REMOVE_TOKEN](state) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_permissions');
    state.isAuthenticated = false;
  },
  [AUTH_PASSWORD_RESTORE_LOADING](state, flag) {
    state.isPasswordRestoreLoading = flag;
  },
  [AUTH_INVITE_LOADING](state, flag) {
    state.isInviteLoading = flag;
  },
  [AUTH_INVITE_PASSWORD_RESTORE_LOADING](state, flag) {
    state.isInvitePasswordRestoreLoading = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
