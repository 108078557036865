import {$http} from '@/utils/http'
import {findIndex, orderBy} from "lodash";
import Vue from "vue";

import {
  COUNTRIES,
  COUNTRIES_LOADING,

  COUNTRY,
  COUNTRY_LOADING,

  COUNTRY_UPDATING,
  COUNTRY_UPDATE,
  COUNTRY_CREATING,
  COUNTRY_CREATE,
  COUNTRY_DELETE,
} from '../mutation-types';

import {
  COUNTRIES_REQUEST_LIST_GET,
  COUNTRIES_REQUEST_DATA_GET,
  COUNTRIES_REQUEST_UPDATE,
  COUNTRIES_REQUEST_CREATE,
  COUNTRIES_REQUEST_DELETE,
} from '../action-types';


const state = {
  countries: {
    list: [],
    isLoading: false,
  },

  country: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  countries: state => state.countries.list,
  isCountriesLoading: state => state.countries.isLoading,

  country: state => state.country.data,
  isCountryLoading: state => state.country.isLoading,
  isCountryUpdating: state => state.country.isUpdating,
  isCountryCreating: state => state.country.isCreating,
};

const actions = {
  [COUNTRIES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(COUNTRIES_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`countries?include=${include}`);
      commit(COUNTRIES, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRIES_LOADING, false);
    }
  },

  [COUNTRIES_REQUEST_DATA_GET]: async ({commit}, countryId) => {
    try {
      commit(COUNTRY_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`countries/${countryId}?include=${include}`);
      commit(COUNTRY, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_LOADING, false);
    }
  },

  [COUNTRIES_REQUEST_UPDATE]: async ({commit}, {countryId, payload}) => {
    try {
      commit(COUNTRY_UPDATING, true);
      let include = 'translations';
      const response = await $http.put(`countries/${countryId}?include=${include}`, payload);
      commit(COUNTRY_UPDATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_UPDATING, false);
    }
  },

  [COUNTRIES_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(COUNTRY_CREATING, true);
      let include = 'translations';
      const response = await $http.post(`countries?include=${include}`, payload);
      commit(COUNTRY_CREATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(COUNTRY_CREATING, false);
    }
  },

  [COUNTRIES_REQUEST_DELETE]: async ({commit}, countryId) => {
    try {
      await $http.delete(`countries/${countryId}`);
      commit(COUNTRY_DELETE, countryId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [COUNTRIES](state, data) {
    state.countries.list = data.length ? orderBy(data, [function(item) { return item.title.toLowerCase() }], ['asc']) : [];
  },
  [COUNTRIES_LOADING](state, flag) {
    state.countries.isLoading = flag;
  },

  [COUNTRY](state, data) {
    state.country.data = data;
  },
  [COUNTRY_LOADING](state, flag) {
    state.country.isLoading = flag;
  },
  [COUNTRY_UPDATING](state, flag) {
    state.country.isUpdating = flag;
  },
  [COUNTRY_UPDATE](state, data) {
    state.country.data = data;
    let index = findIndex(state.countries.list, item => {return item.id === data.id;});
    Vue.set(state.countries.list, index, data);
  },
  [COUNTRY_CREATING](state, flag) {
    state.country.isCreating = flag;
  },
  [COUNTRY_CREATE](state, data) {
    state.countries.list.push(data);
  },
  [COUNTRY_DELETE](state, id) {
    let index = findIndex(state.countries.list, item => {return item.id === id;});
    state.countries.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
