import {helpers} from "vuelidate/lib/validators";
import store from '../store'

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const isHasPermission = (name) => {
  let flag = false,
    isAuthenticated = store.state.auth.isAuthenticated;

  if (isAuthenticated) {
    let permissions = JSON.parse(localStorage.getItem('user_permissions'))
    flag = permissions.some(item => {return item.name === name})
  }
  return flag
}

function objectIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const objectToQueryString = (params) =>
  Object.keys(params).map(key => key + '=' + params[key]).join('&');

const objectToEncodedQueryString = (params) =>
  Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

//---eslint-disable-next-line no-useless-escape
const emailRegex = helpers.regex('email',/^[a-zA-Z0-9\._+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/);
//---eslint-disable-next-line no-useless-escape
const currencyRegex = helpers.regex('currency',/^(0|[1-9]\d{0,})(\.\d{1,2})?$/);

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) ? true : false;
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i) ? true : false;
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) ? true : false;
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
  }
};

const isIPad = {
  iOS: function () {
    return navigator.userAgent.match(/iPad|iPod/i) ? true : false;
  }
};

export {
  isHasPermission,
  getRandomIntInclusive,
  objectIsEmpty,
  objectToQueryString,
  objectToEncodedQueryString,
  emailRegex,
  currencyRegex,
  isMobile,
  isIPad
}