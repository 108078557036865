import {$http} from '@/utils/http'
import {isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  ORDERS_NEW_LIST,
  ORDERS_NEW_LIST_LOADING,
  ORDERS_NEW_LIST_PAGE,

  ORDERS_COLLECTING_LIST,
  ORDERS_COLLECTING_LIST_LOADING,
  ORDERS_COLLECTING_LIST_PAGE,
  ORDERS_COLLECTING_LIST_PAGE_LENGTH,

  ORDERS_AWAITING_LIST,
  ORDERS_AWAITING_LIST_LOADING,
  ORDERS_AWAITING_LIST_PAGE,
  ORDERS_AWAITING_LIST_PAGE_LENGTH,

  ORDERS_FINISHED_LIST,
  ORDERS_FINISHED_LIST_LOADING,
  ORDERS_FINISHED_LIST_PAGE,
  ORDERS_FINISHED_LIST_PAGE_LENGTH,

  ORDERS_DATA,
  ORDERS_DATA_LOADING,

  ORDERS_CONFIRM_DATA_LOADING,
  ORDERS_PARCELS_CREATE_LOADING,
} from '../mutation-types';

import {
  ORDERS_REQUEST_NEW_LIST_GET,
  ORDERS_REQUEST_NEW_ITEM_DELETE,
  ORDERS_REQUEST_COLLECTING_LIST_GET,
  ORDERS_REQUEST_AWAITING_LIST_GET,
  ORDERS_REQUEST_FINISHED_LIST_GET,
  ORDERS_REQUEST_DATA_GET,
  ORDERS_REQUEST_DATA_CONFIRM,
  ORDERS_REQUEST_PARCELS_CREATE,
} from '../action-types';

const state = {
  ordersNew: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
    availabilityStatuses: [
      {
        title: 'orderAll',
        value: ''
      },
      {
        title: 'orderAvailabilityReady',
        value: 'ready'
      },
      {
        title: 'orderAvailabilityNeedOrder',
        value: 'need_order'
      }
    ]
  },
  ordersCollecting: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  ordersAwaiting: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  ordersFinished: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1
  },
  order: {
    data: null,
    isLoading: false,
    isConfirmLoading: false,
    isParcelsCreateLoading: false,
  },
};

const getters = {
  ordersNewList: state => state.ordersNew.list,
  isOrdersNewLoading: state => state.ordersNew.isLoading,
  ordersNewPage: state => state.ordersNew.page,
  ordersNewPageLength: state => state.ordersNew.pageLength,
  ordersNewAvailabilityStatuses: state => state.ordersNew.availabilityStatuses,

  ordersCollectingList: state => state.ordersCollecting.list,
  isOrdersCollectingLoading: state => state.ordersCollecting.isLoading,
  ordersCollectingPage: state => state.ordersCollecting.page,
  ordersCollectingPageLength: state => state.ordersCollecting.pageLength,

  ordersAwaitingList: state => state.ordersAwaiting.list,
  isOrdersAwaitingLoading: state => state.ordersAwaiting.isLoading,
  ordersAwaitingPage: state => state.ordersAwaiting.page,
  ordersAwaitingPageLength: state => state.ordersAwaiting.pageLength,

  ordersFinishedList: state => state.ordersFinished.list,
  isOrdersFinishedLoading: state => state.ordersFinished.isLoading,
  ordersFinishedPage: state => state.ordersFinished.page,
  ordersFinishedPageLength: state => state.ordersFinished.pageLength,

  order: state => state.order.data,
  isOrderLoading: state => state.order.isLoading,
  isOrderConfirmLoading: state => state.order.isConfirmLoading,
  isOrderParcelsCreateLoading: state => state.order.isParcelsCreateLoading,
};

const actions = {
  [ORDERS_REQUEST_NEW_LIST_GET]: async ({commit},params) => {
    try {
      commit(ORDERS_NEW_LIST_LOADING, true);
      commit(ORDERS_NEW_LIST_PAGE, params.page);

      let include = 'order_status,order_products,parcels';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`orders?include=${include}&per_page=${state.ordersNew.per_page}${query}`);

      commit(ORDERS_NEW_LIST, response.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_NEW_LIST_LOADING, false);
    }
  },

  [ORDERS_REQUEST_NEW_ITEM_DELETE]: async ({commit},orderId) => {
    try {
      return await $http.delete(`orders/${orderId}`);
    } catch (e) {
      throw e;
    }
  },

  [ORDERS_REQUEST_COLLECTING_LIST_GET]: async ({commit}, params) => {
    try {
      commit(ORDERS_COLLECTING_LIST_LOADING, true);
      commit(ORDERS_COLLECTING_LIST_PAGE, params.page);

      let include = 'order_status,order_products,parcels';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`orders?include=${include}&per_page=${state.ordersCollecting.per_page}${query}`);

      commit(ORDERS_COLLECTING_LIST, response.data.data);
      commit(ORDERS_COLLECTING_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_COLLECTING_LIST_LOADING, false);
    }
  },

  [ORDERS_REQUEST_AWAITING_LIST_GET]: async ({commit}, params) => {
    try {
      commit(ORDERS_AWAITING_LIST_LOADING, true);
      commit(ORDERS_AWAITING_LIST_PAGE, params.page);

      let include = 'order_status,order_products,parcels';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`orders?include=${include}&per_page=${state.ordersAwaiting.per_page}${query}`);

      commit(ORDERS_AWAITING_LIST, response.data.data);
      commit(ORDERS_AWAITING_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_AWAITING_LIST_LOADING, false);
    }
  },

  [ORDERS_REQUEST_FINISHED_LIST_GET]: async ({commit}, params) => {
    try {
      commit(ORDERS_FINISHED_LIST_LOADING, true);
      commit(ORDERS_FINISHED_LIST_PAGE, params.page);

      let include = 'order_status,order_products,parcels';
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`orders?include=${include}&per_page=${state.ordersFinished.per_page}${query}`);

      commit(ORDERS_FINISHED_LIST, response.data.data);
      commit(ORDERS_FINISHED_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_FINISHED_LIST_LOADING, false);
    }
  },

  [ORDERS_REQUEST_DATA_GET]: async ({commit, dispatch}, orderId) => {
    try {
      commit(ORDERS_DATA_LOADING, true);
      let include = 'order_status,order_products,parcels';
      const orderResponse = await $http.get(`orders/${orderId}?include=${include}`);

      let orderData = orderResponse.data.data;
      if (orderData.parcels.data.length) {
        let parcelId = orderData.parcels.data[0].id
        await dispatch('parcels/PARCELS_REQUEST_DATA_GET', parcelId, { root: true } );
      } else {
        commit('parcels/PARCELS_DATA', null, { root: true });
      }

      commit(ORDERS_DATA, orderData);
      return orderResponse;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_DATA_LOADING, false);
    }
  },

  [ORDERS_REQUEST_DATA_CONFIRM]: async ({commit}, orderId) => {
    try {
      commit(ORDERS_CONFIRM_DATA_LOADING, true);
      let include = 'order_status,order_products,parcels';
      const response = await $http.post(`orders/${orderId}/confirm?include=${include}`);
      commit(ORDERS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_CONFIRM_DATA_LOADING, false);
    }
  },

  [ORDERS_REQUEST_PARCELS_CREATE]: async ({commit, dispatch}, orderId) => {
    try {
      commit(ORDERS_PARCELS_CREATE_LOADING, true);
      let include = 'order_status,order_products,parcels';
      const parcelCreateResponse = await $http.post(`orders/${orderId}/create_parcels?include=${include}`);
      let parcelCreateData = parcelCreateResponse.data.data;

      if (parcelCreateData.parcels.data.length) {
        let parcelId = parcelCreateData.parcels.data[0].id
        await dispatch('parcels/PARCELS_REQUEST_DATA_GET', parcelId, { root: true } );
      } else {
        commit('parcels/PARCELS_DATA', null, { root: true });
      }
      commit(ORDERS_DATA, parcelCreateData);
      return parcelCreateResponse;
    } catch (e) {
      throw e;
    } finally {
      commit(ORDERS_PARCELS_CREATE_LOADING, false);
    }
  },
};

const mutations = {
  [ORDERS_NEW_LIST](state, data) {
    state.ordersNew.list = data.data ? data.data : [];
    state.ordersNew.pageLength = data.meta ? data.meta.last_page : 1;
  },
  [ORDERS_NEW_LIST_LOADING](state, flag) {
    state.ordersNew.isLoading = flag;
  },
  [ORDERS_NEW_LIST_PAGE](state, number) {
    state.ordersNew.page = number;
  },

  [ORDERS_COLLECTING_LIST](state, list) {
    state.ordersCollecting.list = list;
  },
  [ORDERS_COLLECTING_LIST_LOADING](state, flag) {
    state.ordersCollecting.isLoading = flag;
  },
  [ORDERS_COLLECTING_LIST_PAGE](state, number) {
    state.ordersCollecting.page = number;
  },
  [ORDERS_COLLECTING_LIST_PAGE_LENGTH](state, count) {
    state.ordersCollecting.pageLength = count;
  },

  [ORDERS_AWAITING_LIST](state, list) {
    state.ordersAwaiting.list = list;
  },
  [ORDERS_AWAITING_LIST_LOADING](state, flag) {
    state.ordersAwaiting.isLoading = flag;
  },
  [ORDERS_AWAITING_LIST_PAGE](state, number) {
    state.ordersAwaiting.page = number;
  },
  [ORDERS_AWAITING_LIST_PAGE_LENGTH](state, count) {
    state.ordersAwaiting.pageLength = count;
  },

  [ORDERS_FINISHED_LIST](state, list) {
    state.ordersFinished.list = list;
  },
  [ORDERS_FINISHED_LIST_LOADING](state, flag) {
    state.ordersFinished.isLoading = flag;
  },
  [ORDERS_FINISHED_LIST_PAGE](state, number) {
    state.ordersFinished.page = number;
  },
  [ORDERS_FINISHED_LIST_PAGE_LENGTH](state, count) {
    state.ordersFinished.pageLength = count;
  },

  [ORDERS_DATA](state, data) {
    state.order.data = data;
  },
  [ORDERS_DATA_LOADING](state, flag) {
    state.order.isLoading = flag;
  },

  [ORDERS_CONFIRM_DATA_LOADING](state, flag) {
    state.order.isConfirmLoading = flag;
  },

  [ORDERS_PARCELS_CREATE_LOADING](state, flag) {
    state.order.isParcelsCreateLoading = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};