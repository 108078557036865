import {$http} from '@/utils/http'
import {
  ADMINISTRATION_WORKER_LIST_LOADING,
  ADMINISTRATION_WORKER_LIST,
  ADMINISTRATION_WORKER_LOADING,
  ADMINISTRATION_WORKER,
  ADMINISTRATION_WORKER_UPDATE_LOADING,
  ADMINISTRATION_WORKER_CREATE_LOADING,
} from '../mutation-types';

import {
  ADMINISTRATION_REQUEST_WORKER_LIST_GET,
  ADMINISTRATION_REQUEST_WORKER_GET,
  ADMINISTRATION_REQUEST_WORKER_UPDATE,
  ADMINISTRATION_REQUEST_WORKER_CREATE,
  ADMINISTRATION_REQUEST_WORKER_DELETE
} from '../action-types';

const state = {
  workers: {
    list: null,
    isLoading: false
  },
  worker: {
    data: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
  },
};

const getters = {
  workersList: state => state.workers.list,
  isWorkerListLoading: state => state.workers.isLoading,
  worker: state => state.worker.data,
  isWorkerLoading: state => state.worker.isLoading,
  isWorkerCreating: state => state.worker.isCreating,
  isWorkerUpdating: state => state.worker.isUpdating
};

const actions = {
  [ADMINISTRATION_REQUEST_WORKER_LIST_GET]: async ({commit}) => {
    try {
      commit(ADMINISTRATION_WORKER_LIST_LOADING, true);
      let include = 'roles,permissions';
      const response = await $http.get(`administrators?include=${include}`);
      commit(ADMINISTRATION_WORKER_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ADMINISTRATION_WORKER_LIST_LOADING, false);
    }
  },

  [ADMINISTRATION_REQUEST_WORKER_GET]: async ({commit}, workerId) => {
    try {
      commit(ADMINISTRATION_WORKER_LOADING, true);
      let include = 'roles,permissions';
      const response = await $http.get(`administrators/${workerId}?include=${include}`);
      commit(ADMINISTRATION_WORKER, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ADMINISTRATION_WORKER_LOADING, false);
    }
  },

  [ADMINISTRATION_REQUEST_WORKER_UPDATE]: async ({commit}, data) => {
    try {
      commit(ADMINISTRATION_WORKER_UPDATE_LOADING, true);
      const response = await $http.put(`administrators/${data.workerId}?include=roles`, data.payload);
      commit(ADMINISTRATION_WORKER, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ADMINISTRATION_WORKER_UPDATE_LOADING, false);
    }
  },

  [ADMINISTRATION_REQUEST_WORKER_CREATE]: async ({commit}, payload) => {
    try {
      commit(ADMINISTRATION_WORKER_CREATE_LOADING, true);
      const response = await $http.post(`administrators`, payload);
      commit(ADMINISTRATION_WORKER, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(ADMINISTRATION_WORKER_CREATE_LOADING, false);
    }
  },

  [ADMINISTRATION_REQUEST_WORKER_DELETE]: async ({commit}, workerId) => {
    try {
      return await $http.delete(`administrators/${workerId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [ADMINISTRATION_WORKER_LIST_LOADING](state, flag) {
    state.workers.isLoading = flag;
  },
  [ADMINISTRATION_WORKER_LIST](state, data) {
    state.workers.list = data;
  },
  [ADMINISTRATION_WORKER](state, data) {
    state.worker.data = data;
  },
  [ADMINISTRATION_WORKER_LOADING](state, flag) {
    state.worker.isLoading = flag;
  },
  [ADMINISTRATION_WORKER_UPDATE_LOADING](state, flag) {
    state.worker.isUpdating = flag;
  },
  [ADMINISTRATION_WORKER_CREATE_LOADING](state, flag) {
    state.worker.isCreating = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
