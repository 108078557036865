import {$http} from '@/utils/http'

import {isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  NOTIFICATIONS_LIST,
  NOTIFICATIONS_LIST_LOADING,
  NOTIFICATIONS_LIST_NEWEST,
  NOTIFICATIONS_LIST_NEWEST_LOADING,
  NOTIFICATIONS_LIST_CUSTOM_CHANGED,
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_DATA_LOADING,
  NOTIFICATIONS_DATA_UPDATE,
  NOTIFICATIONS_DATA_UPDATE_LOADING,
  NOTIFICATIONS_DATA_CREATE,
  NOTIFICATIONS_DATA_CREATE_LOADING,
} from '../mutation-types';

import {
  NOTIFICATIONS_REQUEST_LIST_GET,
  NOTIFICATIONS_REQUEST_LIST_NEWEST_GET,
  NOTIFICATIONS_REQUEST_DATA_GET,
  NOTIFICATIONS_REQUEST_UPDATE,
  NOTIFICATIONS_REQUEST_CREATE,
  NOTIFICATIONS_REQUEST_DELETE,
  NOTIFICATIONS_REQUEST_CONFIRM,
} from '../action-types';

const state = {
  notifications: {
    list: [],
    per_page: 10,
    pageLength: 1,
    isLoading: false,
    isCustomChanged: false,
  },
  notificationsNewest: {
    list: [],
    per_page: 10,
    page: 1,
    meta: null,
    isLoading: false
  },
  notification: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  },
};

const getters = {
  notifications: state => state.notifications.list,
  notificationsPageLength: state => state.notifications.pageLength,
  notificationsNewest: state => state.notificationsNewest.list,
  notificationsNewestMeta: state => state.notificationsNewest.meta,
  isNotificationsNewestLoading: state => state.notificationsNewest.isLoading,
  isNotificationsLoading: state => state.notifications.isLoading,
  isNotificationCustomChanged: state => state.notifications.isCustomChanged,
  notification: state => state.notification.data,
  isNotificationLoading: state => state.notification.isLoading,
  isNotificationUpdating: state => state.notification.isUpdating,
  isNotificationCreating: state => state.notification.isCreating,
};

const actions = {
  [NOTIFICATIONS_REQUEST_LIST_GET]: async ({commit}, params) => {
    try {
      commit(NOTIFICATIONS_LIST_LOADING, true);
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';
      const response = await $http.get(`notifications?per_page=${state.notifications.per_page}${query}`);
      commit(NOTIFICATIONS_LIST, response.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_LIST_LOADING, false);
    }
  },

  [NOTIFICATIONS_REQUEST_LIST_NEWEST_GET]: async ({commit}) => {
    try {
      commit(NOTIFICATIONS_LIST_NEWEST_LOADING, true);
      const response = await $http.get(`notifications?per_page=${state.notificationsNewest.per_page}&page=${state.notificationsNewest.page}`);
      commit(NOTIFICATIONS_LIST_NEWEST, response.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_LIST_NEWEST_LOADING, false);
    }
  },

  [NOTIFICATIONS_REQUEST_DATA_GET]: async ({commit}, notificationId) => {
    try {
      commit(NOTIFICATIONS_DATA_LOADING, true);
      const response = await $http.get(`notifications/${notificationId}`);
      commit(NOTIFICATIONS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_DATA_LOADING, false);
    }
  },

  [NOTIFICATIONS_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, false);
      commit(NOTIFICATIONS_DATA_UPDATE_LOADING, true);
      const response = await $http.put(`notifications/${data.notificationId}`, data.payload);
      commit(NOTIFICATIONS_DATA_UPDATE, response.data.data);
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, true);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_DATA_UPDATE_LOADING, false);
    }
  },

  [NOTIFICATIONS_REQUEST_CONFIRM]: async ({commit}, data) => {
    try {
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, false);
      const response = await $http.put(`notifications/${data.notificationId}`, data.payload);
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, true);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [NOTIFICATIONS_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, false);
      commit(NOTIFICATIONS_DATA_CREATE_LOADING, true);
      const response = await $http.post(`notifications`, payload);
      commit(NOTIFICATIONS_DATA_CREATE, response.data.data);
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, true);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_DATA_CREATE_LOADING, false);
    }
  },

  [NOTIFICATIONS_REQUEST_DELETE]: async ({commit}, notificationId) => {
    try {
      commit(NOTIFICATIONS_LIST_LOADING, true);
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, false);
      const response = await $http.delete(`notifications/${notificationId}`);
      commit(NOTIFICATIONS_LIST_CUSTOM_CHANGED, true);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(NOTIFICATIONS_LIST_LOADING, false);
    }
  },

};

const mutations = {
  [NOTIFICATIONS_LIST](state, data) {
    state.notifications.list = data.data ? data.data : [];
    state.notifications.pageLength = data.meta ? data.meta.last_page : 1;
  },

  [NOTIFICATIONS_LIST_LOADING](state, flag) {
    state.notifications.isLoading = flag;
  },

  [NOTIFICATIONS_LIST_NEWEST](state, data) {
    state.notificationsNewest.list = data.data ? data.data : [];
    state.notificationsNewest.meta = data.meta ? data.meta : null;
  },
  [NOTIFICATIONS_LIST_NEWEST_LOADING](state, flag) {
    state.notificationsNewest.isLoading = flag;
  },

  [NOTIFICATIONS_LIST_CUSTOM_CHANGED](state, flag) {
    state.notifications.isCustomChanged = flag;
  },

  [NOTIFICATIONS_DATA](state, data) {
    state.notification.data = data;
  },
  [NOTIFICATIONS_DATA_LOADING](state, flag) {
    state.notification.isLoading = flag;
  },

  [NOTIFICATIONS_DATA_UPDATE](state, data) {
    state.notification.data = data;
  },
  [NOTIFICATIONS_DATA_UPDATE_LOADING](state, flag) {
    state.notification.isUpdating = flag;
  },

  [NOTIFICATIONS_DATA_CREATE_LOADING](state, flag) {
    state.notification.isCreating = flag;
  },

  [NOTIFICATIONS_DATA_CREATE](state, data) {
    state.notification.data = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
