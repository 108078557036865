import {$http} from '@/utils/http'

import {
  CATEGORIES_LIST,
  CATEGORIES_LIST_LOADING,
  CATEGORIES_DATA,
  CATEGORIES_DATA_LOADING
} from '../mutation-types';

import {
  CATEGORIES_REQUEST_LIST_GET,
  CATEGORIES_REQUEST_DATA_GET,
} from '../action-types';

const state = {
  categories: {
    list: null,
    isLoading: false,
  },
  category: {
    data: null,
    isLoading: false,
  },
};

const getters = {
  categories: state => state.categories.list,
  isCategoriesLoading: state => state.categories.isLoading,
  category: state => state.category.data,
  isCategoryLoading: state => state.category.isLoading,
};

const actions = {
  [CATEGORIES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(CATEGORIES_LIST_LOADING, true);
      const response = await $http.get(`categories`);
      commit(CATEGORIES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CATEGORIES_LIST_LOADING, false);
    }
  },

  [CATEGORIES_REQUEST_DATA_GET]: async ({commit}, categoryId) => {
    try {
      commit(CATEGORIES_DATA_LOADING, true);
      const response = await $http.get(`categories/${categoryId}`);
      commit(CATEGORIES_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(CATEGORIES_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [CATEGORIES_LIST](state, list) {
    state.categories.list = list;
  },
  [CATEGORIES_LIST_LOADING](state, flag) {
    state.categories.isLoading = flag;
  },
  [CATEGORIES_DATA](state, data) {
    state.category.data = data;
  },
  [CATEGORIES_DATA_LOADING](state, flag) {
    state.category.isLoading = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};