import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import locales from '@/locales'

const proxy = {
  getTranslation: async () => {
    return await store.dispatch(`setting/GET_TRANSLATIONS`);
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'en-US',
    languages: [
      {
        name: 'Russian',
        title: 'Руc',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      },
      {
        name: 'English',
        title: 'Eng',
        key: 'eng',
        code: 'en-US',
        urlPrefix: 'en',
        translationKey: 'en'
      },
    ],
    // translations: proxy,
    translations: locales
  },
});
