import {mapGetters, mapActions, mapMutations} from 'vuex';

import notifications from './notifications/index.vue';

export default {
  name: 'app-header',
  data() {
    return {}
  },
  components: {
    notifications
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'profile/user',
      dashboardSidebarStatus: 'system/dashboardSidebarStatus',
      appSize: 'system/appSize',
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    isDashboard() {
      if (this.$route.matched.length > 0 && this.$route.matched.some(item => {return item.name === 'dashboard'})) {
        return true
      } else {
        this.setSidebarToggler(false);
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/AUTH_REQUEST_LOGOUT',
    }),
    ...mapMutations({
      setSidebarToggler: 'system/SYSTEM_DASHBOARD_SIDEBAR_TOGGLE'
    }),
    logout() {
      this.logoutAction().then(()=>{
        this.$router.push({name: 'login'}).catch(()=> {console.log()})
      });
    },
    changeLanguage(code){
      this.$setLanguage(code);
      setTimeout(()=>{
        window.location.reload();
      },100)
    },
    toggleSidebar(){
      this.setSidebarToggler(!this.dashboardSidebarStatus);
    },
  },
  destroyed() {},
}
