//---auth
export const AUTH_REQUEST_LOGIN = 'AUTH_REQUEST_LOGIN';
export const AUTH_REQUEST_LOGOUT = 'AUTH_REQUEST_LOGOUT';
export const AUTH_REQUEST_PASSWORD_RESET = 'AUTH_REQUEST_PASSWORD_RESET';
export const AUTH_REQUEST_PASSWORD_RESTORE = 'AUTH_REQUEST_PASSWORD_RESTORE';
export const AUTH_REQUEST_INVITE = 'AUTH_REQUEST_INVITE';
export const AUTH_REQUEST_INVITE_LOGIN = 'AUTH_REQUEST_INVITE_LOGIN';
export const AUTH_REQUEST_INVITE_PASSWORD_SET = 'AUTH_REQUEST_INVITE_PASSWORD_SET';

//---profile
export const PROFILE_REQUEST_USER_DATA_GET = 'PROFILE_REQUEST_USER_DATA_GET';

//---settings
export const SETTINGS_REQUEST_LIST_GET = 'SETTINGS_REQUEST_LIST_GET';
export const SETTINGS_REQUEST_DATA_GET = 'SETTINGS_REQUEST_DATA_GET';
export const SETTINGS_REQUEST_UPDATE = 'SETTINGS_REQUEST_UPDATE';

//---administration
export const ADMINISTRATION_REQUEST_WORKER_LIST_GET = 'ADMINISTRATION_REQUEST_WORKER_LIST_GET';
export const ADMINISTRATION_REQUEST_WORKER_GET = 'ADMINISTRATION_REQUEST_WORKER_GET';
export const ADMINISTRATION_REQUEST_WORKER_UPDATE = 'ADMINISTRATION_REQUEST_WORKER_UPDATE';
export const ADMINISTRATION_REQUEST_WORKER_CREATE = 'ADMINISTRATION_REQUEST_WORKER_CREATE';
export const ADMINISTRATION_REQUEST_WORKER_DELETE = 'ADMINISTRATION_REQUEST_WORKER_DELETE';

//---permissions
export const PERMISSIONS_REQUEST_LIST_GET = 'PERMISSIONS_REQUEST_LIST_GET';
export const PERMISSIONS_REQUEST_DATA_GET = 'PERMISSIONS_REQUEST_DATA_GET';

//---roles
export const ROLES_REQUEST_LIST_GET = 'ROLES_REQUEST_LIST_GET';
export const ROLES_REQUEST_DATA_GET = 'ROLES_REQUEST_DATA_GET';
export const ROLES_REQUEST_UPDATE = 'ROLES_REQUEST_UPDATE';
export const ROLES_REQUEST_CREATE = 'ROLES_REQUEST_CREATE';
export const ROLES_REQUEST_DELETE = 'ROLES_REQUEST_DELETE';

//---stores
export const STORE_REQUEST_LIST_GET = 'STORE_REQUEST_LIST_GET';
export const STORE_REQUEST_DATA_GET = 'STORE_REQUEST_DATA_GET';
export const STORE_REQUEST_UPDATE = 'STORE_REQUEST_UPDATE';
export const STORE_REQUEST_CREATE = 'STORE_REQUEST_CREATE';
export const STORE_REQUEST_DELETE = 'STORE_REQUEST_DELETE';

//---racks
export const RACKS_REQUEST_LIST_GET = 'RACKS_REQUEST_LIST_GET';
export const RACKS_REQUEST_DATA_GET = 'RACKS_REQUEST_DATA_GET';
export const RACKS_REQUEST_UPDATE = 'RACKS_REQUEST_UPDATE';
export const RACKS_REQUEST_LIST_CREATE = 'RACKS_REQUEST_LIST_CREATE';
export const RACKS_REQUEST_DELETE = 'RACKS_REQUEST_DELETE';

//---shelves
export const SHELVES_REQUEST_LIST_GET = 'SHELVES_REQUEST_LIST_GET';
export const SHELVES_REQUEST_DATA_GET = 'SHELVES_REQUEST_DATA_GET';
export const SHELVES_REQUEST_UPDATE = 'SHELVES_REQUEST_UPDATE';
export const SHELVES_REQUEST_CREATE = 'SHELVES_REQUEST_CREATE';
export const SHELVES_REQUEST_DELETE = 'SHELVES_REQUEST_DELETE';

//---places
export const PLACES_REQUEST_LIST_GET = 'PLACES_REQUEST_LIST_GET';
export const PLACES_REQUEST_DATA_GET = 'PLACES_REQUEST_DATA_GET';
export const PLACES_REQUEST_UPDATE = 'PLACES_REQUEST_UPDATE';
export const PLACES_REQUEST_CREATE = 'PLACES_REQUEST_CREATE';
export const PLACES_REQUEST_DELETE = 'PLACES_REQUEST_DELETE';

//---orders
export const ORDERS_REQUEST_NEW_LIST_GET = 'ORDERS_REQUEST_NEW_LIST_GET';
export const ORDERS_REQUEST_NEW_ITEM_DELETE = 'ORDERS_REQUEST_NEW_ITEM_DELETE';
export const ORDERS_REQUEST_COLLECTING_LIST_GET = 'ORDERS_REQUEST_COLLECTING_LIST_GET';
export const ORDERS_REQUEST_AWAITING_LIST_GET = 'ORDERS_REQUEST_AWAITING_LIST_GET';
export const ORDERS_REQUEST_FINISHED_LIST_GET = 'ORDERS_REQUEST_FINISHED_LIST_GET';
export const ORDERS_REQUEST_DATA_GET = 'ORDERS_REQUEST_DATA_GET';
export const ORDERS_REQUEST_DATA_CONFIRM = 'ORDERS_REQUEST_DATA_CONFIRM';
export const ORDERS_REQUEST_PARCELS_CREATE = 'ORDERS_REQUEST_PARCELS_CREATE';

//---supply orders
export const SUPPLY_ORDERS_REQUEST_NEW_LIST_GET = 'SUPPLY_ORDERS_REQUEST_NEW_LIST_GET';
export const SUPPLY_ORDERS_REQUEST_IN_PROGRESS_LIST_GET = 'SUPPLY_ORDERS_REQUEST_IN_PROGRESS_LIST_GET';
export const SUPPLY_ORDERS_REQUEST_FINISHED_LIST_GET = 'SUPPLY_ORDERS_REQUEST_FINISHED_LIST_GET';
export const SUPPLY_ORDERS_REQUEST_DATA_GET = 'SUPPLY_ORDERS_REQUEST_DATA_GET';
export const SUPPLY_ORDERS_REQUEST_UPDATE = 'SUPPLY_ORDERS_REQUEST_UPDATE';
export const SUPPLY_ORDERS_REQUEST_CONFIRM  = 'SUPPLY_ORDERS_REQUEST_CONFIRM';

export const SUPPLY_ORDERS_REQUEST_PRODUCT_UPDATE = 'SUPPLY_ORDERS_REQUEST_PRODUCT_UPDATE';
export const SUPPLY_ORDERS_REQUEST_PRODUCT_CREATE  = 'SUPPLY_ORDERS_REQUEST_PRODUCT_CREATE';
export const SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_UPDATE  = 'SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_UPDATE';
export const SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_DELETE  = 'SUPPLY_ORDERS_REQUEST_NEW_PRODUCT_DELETE';

//---vendors
export const VENDORS_REQUEST_LIST_GET = 'VENDORS_REQUEST_LIST_GET';
export const VENDORS_REQUEST_LIST_ALL_GET = 'VENDORS_REQUEST_LIST_ALL_GET';
export const VENDORS_REQUEST_PRODUCT_LIST_GET = 'VENDORS_REQUEST_PRODUCT_LIST_GET';
export const VENDORS_REQUEST_DATA_GET = 'VENDORS_REQUEST_DATA_GET';
export const VENDORS_REQUEST_UPDATE = 'VENDORS_REQUEST_UPDATE';
export const VENDORS_REQUEST_CREATE = 'VENDORS_REQUEST_CREATE';
export const VENDORS_REQUEST_DELETE = 'VENDORS_REQUEST_DELETE';

//---categories
export const CATEGORIES_REQUEST_LIST_GET = 'CATEGORIES_REQUEST_LIST_GET';
export const CATEGORIES_REQUEST_DATA_GET = 'CATEGORIES_REQUEST_DATA_GET';

//---products
export const PRODUCTS_REQUEST_ALL_LIST_GET = 'PRODUCTS_REQUEST_ALL_LIST_GET';
export const PRODUCTS_REQUEST_LEFTOVER_LIST_GET = 'PRODUCTS_REQUEST_LEFTOVER_LIST_GET';
export const PRODUCTS_REQUEST_OVER_LIST_GET = 'PRODUCTS_REQUEST_OVER_LIST_GET';
export const PRODUCTS_REQUEST_DATA_GET = 'PRODUCTS_REQUEST_DATA_GET';
export const PRODUCTS_REQUEST_DATA_UPDATE = 'PRODUCTS_REQUEST_DATA_UPDATE';
export const PRODUCTS_REQUEST_CONSIGNMENT_UPDATE = 'PRODUCTS_REQUEST_CONSIGNMENT_UPDATE';
export const PRODUCTS_REQUEST_PLACE_DATA_GET = 'PRODUCTS_REQUEST_PLACE_DATA_GET';
export const PRODUCTS_REQUEST_DATA_DELETE = 'PRODUCTS_REQUEST_DATA_DELETE';

//---consignments
export const CONSIGNMENTS_REQUEST_EXPIRATING_LIST_GET = 'CONSIGNMENTS_REQUEST_EXPIRATING_LIST_GET';
export const CONSIGNMENTS_REQUEST_EXPIRATED_LIST_GET = 'CONSIGNMENTS_REQUEST_EXPIRATED_LIST_GET';
export const CONSIGNMENTS_REQUEST_DATA_GET = 'CONSIGNMENTS_REQUEST_DATA_GET';
export const CONSIGNMENTS_REQUEST_DATA_UPDATE = 'CONSIGNMENTS_REQUEST_DATA_UPDATE';

//---leftover-types
export const LEFTOVER_TYPES_REQUEST_LIST_GET = 'LEFTOVER_TYPES_REQUEST_LIST_GET';
export const LEFTOVER_TYPES_REQUEST_DATA_GET = 'LEFTOVER_TYPES_REQUEST_DATA_GET';
export const LEFTOVER_TYPES_REQUEST_UPDATE = 'LEFTOVER_TYPES_REQUEST_UPDATE';
export const LEFTOVER_TYPES_REQUEST_CREATE = 'LEFTOVER_TYPES_REQUEST_CREATE';
export const LEFTOVER_TYPES_REQUEST_DELETE = 'LEFTOVER_TYPES_REQUEST_DELETE';

//---pack-types
export const PACK_TYPES_REQUEST_LIST_GET = 'PACK_TYPES_REQUEST_LIST_GET';

//---box-types
export const BOX_TYPES_REQUEST_LIST_GET = 'BOX_TYPES_REQUEST_LIST_GET';
export const BOX_TYPES_REQUEST_DATA_GET = 'BOX_TYPES_REQUEST_DATA_GET';
export const BOX_TYPES_REQUEST_UPDATE = 'BOX_TYPES_REQUEST_UPDATE';
export const BOX_TYPES_REQUEST_CREATE = 'BOX_TYPES_REQUEST_CREATE';
export const BOX_TYPES_REQUEST_DELETE = 'BOX_TYPES_REQUEST_DELETE';

//---box
export const BOX_REQUEST_UPDATE = 'BOX_REQUEST_UPDATE';

//---parcels
export const PARCELS_REQUEST_NEW_LIST_GET = 'PARCELS_REQUEST_NEW_LIST_GET';
export const PARCELS_REQUEST_COLLECTING_LIST_GET = 'PARCELS_REQUEST_COLLECTING_LIST_GET';
export const PARCELS_REQUEST_READY_LIST_GET = 'PARCELS_REQUEST_READY_LIST_GET';
export const PARCELS_REQUEST_SENT_LIST_GET = 'PARCELS_REQUEST_SENT_LIST_GET';
export const PARCELS_REQUEST_DATA_GET = 'PARCELS_REQUEST_DATA_GET';
export const PARCELS_REQUEST_DATA_UPDATE = 'PARCELS_REQUEST_DATA_UPDATE';

//---delivery
export const DELIVERY_REQUEST_LIST_SIMPLE_GET = 'DELIVERY_REQUEST_LIST_SIMPLE_GET';
export const DELIVERY_REQUEST_DATA_GET = 'DELIVERY_REQUEST_DATA_GET';
export const DELIVERY_REQUEST_DATA_UPDATE = 'DELIVERY_REQUEST_DATA_UPDATE';

//---fuel-surcharge
export const FUEL_SURCHARGE_REQUEST_LIST_GET = 'FUEL_SURCHARGE_REQUEST_LIST_GET';
export const FUEL_SURCHARGE_REQUEST_DATA_GET = 'FUEL_SURCHARGE_REQUEST_DATA_GET';
export const FUEL_SURCHARGE_REQUEST_UPDATE = 'FUEL_SURCHARGE_REQUEST_UPDATE';

//---country-group
export const COUNTRY_GROUP_REQUEST_LIST_GET = 'COUNTRY_GROUP_REQUEST_LIST_GET';
export const COUNTRY_GROUP_REQUEST_DATA_GET = 'COUNTRY_GROUP_REQUEST_DATA_GET';
export const COUNTRY_GROUP_REQUEST_UPDATE = 'COUNTRY_GROUP_REQUEST_UPDATE';
export const COUNTRY_GROUP_REQUEST_CREATE = 'COUNTRY_GROUP_REQUEST_CREATE';
export const COUNTRY_GROUP_REQUEST_DELETE = 'COUNTRY_GROUP_REQUEST_DELETE';

//---countries
export const COUNTRIES_REQUEST_LIST_GET = 'COUNTRIES_REQUEST_LIST_GET';
export const COUNTRIES_REQUEST_DATA_GET = 'COUNTRIES_REQUEST_DATA_GET';
export const COUNTRIES_REQUEST_UPDATE = 'COUNTRIES_REQUEST_UPDATE';
export const COUNTRIES_REQUEST_CREATE = 'COUNTRIES_REQUEST_CREATE';
export const COUNTRIES_REQUEST_DELETE = 'COUNTRIES_REQUEST_DELETE';

//---delivery-regions
export const DELIVERY_REGIONS_REQUEST_LIST_GET = 'DELIVERY_REGIONS_REQUEST_LIST_GET';
export const DELIVERY_REGIONS_REQUEST_DATA_GET = 'DELIVERY_REGIONS_REQUEST_DATA_GET';
export const DELIVERY_REGIONS_REQUEST_UPDATE = 'DELIVERY_REGIONS_REQUEST_UPDATE';
export const DELIVERY_REGIONS_REQUEST_CREATE = 'DELIVERY_REGIONS_REQUEST_CREATE';
export const DELIVERY_REGIONS_REQUEST_DELETE = 'DELIVERY_REGIONS_REQUEST_DELETE';

//---weights
export const WEIGHTS_REQUEST_LIST_GET = 'WEIGHTS_REQUEST_LIST_GET';
export const WEIGHTS_REQUEST_DATA_GET = 'WEIGHTS_REQUEST_DATA_GET';
export const WEIGHTS_REQUEST_UPDATE = 'WEIGHTS_REQUEST_UPDATE';
export const WEIGHTS_REQUEST_CREATE = 'WEIGHTS_REQUEST_CREATE';
export const WEIGHTS_REQUEST_DELETE = 'WEIGHTS_REQUEST_DELETE';

//---base-rate
export const BASE_RATE_REQUEST_CREATE = 'BASE_RATE_REQUEST_CREATE';
export const BASE_RATE_REQUEST_UPDATE = 'BASE_RATE_REQUEST_UPDATE';
export const BASE_RATE_REQUEST_DELETE = 'BASE_RATE_REQUEST_DELETE';

//---delivery-group
export const DELIVERY_GROUP_REQUEST_UPDATE = 'DELIVERY_GROUP_REQUEST_UPDATE';
export const DELIVERY_GROUP_REQUEST_DELETE = 'DELIVERY_GROUP_REQUEST_DELETE';

//---notifications
export const NOTIFICATIONS_REQUEST_LIST_GET = 'NOTIFICATIONS_REQUEST_LIST_GET';
export const NOTIFICATIONS_REQUEST_LIST_NEWEST_GET = 'NOTIFICATIONS_REQUEST_LIST_NEWEST_GET';
export const NOTIFICATIONS_REQUEST_DATA_GET = 'NOTIFICATIONS_REQUEST_DATA_GET';
export const NOTIFICATIONS_REQUEST_UPDATE = 'NOTIFICATIONS_REQUEST_UPDATE';
export const NOTIFICATIONS_REQUEST_CREATE = 'NOTIFICATIONS_REQUEST_CREATE';
export const NOTIFICATIONS_REQUEST_DELETE = 'NOTIFICATIONS_REQUEST_DELETE';
export const NOTIFICATIONS_REQUEST_CONFIRM = 'NOTIFICATIONS_REQUEST_CONFIRM';

//---logs
export const LOGS_REQUEST_LIST_GET = 'LOGS_REQUEST_LIST_GET';
export const LOGS_REQUEST_FILTERS_GET = 'LOGS_REQUEST_FILTERS_GET';

//---stats
export const STATS_REQUEST_ORDER_GET = 'STATS_REQUEST_ORDER_GET';
export const STATS_REQUEST_DELIVERY_GET = 'STATS_REQUEST_DELIVERY_GET';
export const STATS_REQUEST_PRODUCT_OVER_GET = 'STATS_REQUEST_PRODUCT_OVER_GET';
export const STATS_REQUEST_PRODUCT_PAY_GET = 'STATS_REQUEST_PRODUCT_PAY_GET';
export const STATS_REQUEST_SUPPLY_ORDER_GET = 'STATS_REQUEST_SUPPLY_ORDER_GET';
export const STATS_REQUEST_PACKER_GET = 'STATS_REQUEST_PACKER_GET';
export const STATS_REQUEST_PACKERS_GET = 'STATS_REQUEST_PACKERS_GET';

//---packing-requests
export const PACKING_REQUEST_UPDATE = 'PACKING_REQUEST_UPDATE';