import {$http} from '@/utils/http'
import {findIndex, orderBy} from "lodash";
import Vue from "vue";

import {
  DELIVERY_REGIONS_LIST,
  DELIVERY_REGIONS_LIST_LOADING,

  DELIVERY_REGIONS_DATA,
  DELIVERY_REGIONS_DATA_LOADING,

  DELIVERY_REGIONS_DATA_UPDATE_LOADING,
  DELIVERY_REGIONS_DATA_UPDATE,
  DELIVERY_REGIONS_DATA_CREATE_LOADING,
  DELIVERY_REGIONS_DATA_CREATE,
  DELIVERY_REGIONS_DATA_DELETE,
} from '../mutation-types';

import {
  DELIVERY_REGIONS_REQUEST_LIST_GET,
  DELIVERY_REGIONS_REQUEST_DATA_GET,
  DELIVERY_REGIONS_REQUEST_UPDATE,
  DELIVERY_REGIONS_REQUEST_CREATE,
  DELIVERY_REGIONS_REQUEST_DELETE,
} from '../action-types';


const state = {
  deliveryRegions: {
    list: [],
    isLoading: false,
  },

  deliveryRegion: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  }
};

const getters = {
  deliveryRegions: state => state.deliveryRegions.list,
  isDeliveryRegionsLoading: state => state.deliveryRegions.isLoading,

  deliveryRegion: state => state.deliveryRegion.data,
  isDeliveryRegionLoading: state => state.deliveryRegion.isLoading,
  isDeliveryRegionUpdating: state => state.deliveryRegion.isUpdating,
  isDeliveryRegionCreating: state => state.deliveryRegion.isCreating,
};

const actions = {
  [DELIVERY_REGIONS_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(DELIVERY_REGIONS_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`delivery_regions?include=${include}`);
      commit(DELIVERY_REGIONS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_REGIONS_LIST_LOADING, false);
    }
  },

  [DELIVERY_REGIONS_REQUEST_DATA_GET]: async ({commit}, deliveryRegionId) => {
    try {
      commit(DELIVERY_REGIONS_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`delivery_regions/${deliveryRegionId}?include=${include}`);
      commit(DELIVERY_REGIONS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_REGIONS_DATA_LOADING, false);
    }
  },

  [DELIVERY_REGIONS_REQUEST_UPDATE]: async ({commit}, {deliveryRegionId, payload}) => {
    try {
      commit(DELIVERY_REGIONS_DATA_UPDATE_LOADING, true);
      let include = 'translations';
      const response = await $http.put(`delivery_regions/${deliveryRegionId}?include=${include}`, payload);
      commit(DELIVERY_REGIONS_DATA_UPDATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_REGIONS_DATA_UPDATE_LOADING, false);
    }
  },

  [DELIVERY_REGIONS_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(DELIVERY_REGIONS_DATA_CREATE_LOADING, true);
      let include = 'translations';
      const response = await $http.post(`delivery_regions?include=${include}`, payload);
      commit(DELIVERY_REGIONS_DATA_CREATE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(DELIVERY_REGIONS_DATA_CREATE_LOADING, false);
    }
  },

  [DELIVERY_REGIONS_REQUEST_DELETE]: async ({commit}, deliveryRegionId) => {
    try {
      await $http.delete(`delivery_regions/${deliveryRegionId}`);
      commit(DELIVERY_REGIONS_DATA_DELETE, deliveryRegionId);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [DELIVERY_REGIONS_LIST](state, data) {
    state.deliveryRegions.list = data.length ? orderBy(data, [function(item) { return item.title.toLowerCase() }], ['asc']) : [];
  },
  [DELIVERY_REGIONS_LIST_LOADING](state, flag) {
    state.deliveryRegions.isLoading = flag;
  },

  [DELIVERY_REGIONS_DATA](state, data) {
    state.deliveryRegion.data = data;
  },
  [DELIVERY_REGIONS_DATA_LOADING](state, flag) {
    state.deliveryRegion.isLoading = flag;
  },
  [DELIVERY_REGIONS_DATA_UPDATE_LOADING](state, flag) {
    state.deliveryRegion.isUpdating = flag;
  },
  [DELIVERY_REGIONS_DATA_UPDATE](state, data) {
    state.deliveryRegion.data = data;
    let index = findIndex(state.deliveryRegions.list, item => {return item.id === data.id;});
    Vue.set(state.deliveryRegions.list, index, data);
  },
  [DELIVERY_REGIONS_DATA_CREATE_LOADING](state, flag) {
    state.deliveryRegion.isCreating = flag;
  },
  [DELIVERY_REGIONS_DATA_CREATE](state, data) {
    state.deliveryRegions.list.push(data);
  },
  [DELIVERY_REGIONS_DATA_DELETE](state, id) {
    let index = findIndex(state.deliveryRegions.list, item => {return item.id === id;});
    state.deliveryRegions.list.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
