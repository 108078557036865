import {$http} from '@/utils/http'
import {find, isEmpty, uniqBy} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  STATS_ORDER_DATA,
  STATS_ORDER_LOADING,
  STATS_ORDER_FETCHED,

  STATS_DELIVERY_DATA,
  STATS_DELIVERY_LOADING,
  STATS_DELIVERY_FETCHED,

  STATS_PRODUCT_OVER_DATA,
  STATS_PRODUCT_OVER_LOADING,
  STATS_PRODUCT_OVER_FETCHED,

  STATS_PRODUCT_PAY_DATA,
  STATS_PRODUCT_PAY_LOADING,
  STATS_PRODUCT_PAY_FETCHED,

  STATS_SUPPLY_ORDER_DATA,
  STATS_SUPPLY_ORDER_LOADING,
  STATS_SUPPLY_ORDER_FETCHED,

  STATS_PACKER_LIST,
  STATS_PACKER_LIST_LOADING,
  STATS_PACKER_DATA,
  STATS_PACKER_LOADING,
  STATS_PACKER_FETCHED,
} from '../mutation-types';

import {
  STATS_REQUEST_ORDER_GET,
  STATS_REQUEST_DELIVERY_GET,
  STATS_REQUEST_PRODUCT_OVER_GET,
  STATS_REQUEST_PRODUCT_PAY_GET,
  STATS_REQUEST_SUPPLY_ORDER_GET,
  STATS_REQUEST_PACKER_GET,
  STATS_REQUEST_PACKERS_GET,
} from '../action-types';

const state = {
  stats: {
    order: {
      data: [],
      isLoading: false,
      isFetched: false
    },
    delivery: {
      data: [],
      isLoading: false,
      isFetched: false
    },
    productOver: {
      data: [],
      isLoading: false,
      isFetched: false
    },
    productPay: {
      data: [],
      isLoading: false,
      isFetched: false
    },
    supplyOrder: {
      data: [],
      isLoading: false,
      isFetched: false
    },
    packer: {
      list: [],
      isListLoading: false,
      data: [],
      isLoading: false,
      isFetched: true
    },
    packers: {
      list: [],
      isLoading: false,
    },
  }
};

const getters = {
  statsOrder: state => state.stats.order.data,
  isStatsOrderLoading: state => state.stats.order.isLoading,
  isStatsOrderFetched: state => state.stats.order.isFetched,

  statsDelivery: state => state.stats.delivery.data,
  isStatsDeliveryLoading: state => state.stats.delivery.isLoading,
  isStatsDeliveryFetched: state => state.stats.delivery.isFetched,

  statsProductOver: state => state.stats.productOver.data,
  isStatsProductOverLoading: state => state.stats.productOver.isLoading,
  isStatsProductOverFetched: state => state.stats.delivery.isFetched,

  statsProductPay: state => state.stats.productPay.data,
  isStatsProductPayLoading: state => state.stats.productPay.isLoading,
  isStatsProductPayFetched: state => state.stats.productPay.isFetched,

  statsSupplyOrder: state => state.stats.supplyOrder.data,
  isStatsSupplyOrderLoading: state => state.stats.supplyOrder.isLoading,
  isStatsSupplyOrderFetched: state => state.stats.supplyOrder.isFetched,

  statsPackers: state => state.stats.packers.list,
  isStatsPackersLoading: state => state.stats.packers.isLoading,
  statsPacker: state => state.stats.packer.data,
  isStatsPackerLoading: state => state.stats.packer.isLoading,
  isStatsPackerFetched: state => state.stats.packer.isFetched,
};

const actions = {
  [STATS_REQUEST_ORDER_GET]: async ({commit}, params) => {
    try {
      commit(STATS_ORDER_LOADING, true);
      const response = await $http.get(`statistics/orders?${objectToEncodedQueryString(params)}`);
      commit(STATS_ORDER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_ORDER_LOADING, false);
      commit(STATS_ORDER_FETCHED, true);
    }
  },

  [STATS_REQUEST_DELIVERY_GET]: async ({commit}, params) => {
    try {
      commit(STATS_DELIVERY_LOADING, true);
      const response = await $http.get(`statistics/parcels?${objectToEncodedQueryString(params)}`);
      commit(STATS_DELIVERY_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_DELIVERY_LOADING, false);
      commit(STATS_DELIVERY_FETCHED, true);
    }
  },

  [STATS_REQUEST_PRODUCT_OVER_GET]: async ({commit}, params) => {
    try {
      commit(STATS_PRODUCT_OVER_LOADING, true);
      const response = await $http.get(`statistics/consignments?${objectToEncodedQueryString(params)}`);
      commit(STATS_PRODUCT_OVER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_PRODUCT_OVER_LOADING, false);
      commit(STATS_PRODUCT_OVER_FETCHED, true);
    }
  },

  [STATS_REQUEST_PRODUCT_PAY_GET]: async ({commit}, params) => {
    try {
      commit(STATS_PRODUCT_PAY_LOADING, true);
      const response = await $http.get(`statistics/orders/sum?${objectToEncodedQueryString(params)}`);
      commit(STATS_PRODUCT_PAY_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_PRODUCT_PAY_LOADING, false);
      commit(STATS_PRODUCT_PAY_FETCHED, true);
    }
  },

  [STATS_REQUEST_SUPPLY_ORDER_GET]: async ({commit}, params) => {
    try {
      commit(STATS_SUPPLY_ORDER_LOADING, true);
      const query = params && !isEmpty(params) ? objectToEncodedQueryString(params) : '';
      const response = await $http.get(`statistics/supply_orders?${query}`);
      commit(STATS_SUPPLY_ORDER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_SUPPLY_ORDER_LOADING, false);
      commit(STATS_SUPPLY_ORDER_FETCHED, true);
    }
  },

  [STATS_REQUEST_PACKERS_GET]: async ({commit}) => {
    try {
      commit(STATS_PACKER_LIST_LOADING, true);
      const response = await $http.get(`statistics/administrators`);
      commit(STATS_PACKER_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_PACKER_LIST_LOADING, false);
    }
  },

  [STATS_REQUEST_PACKER_GET]: async ({commit}, params) => {
    try {
      commit(STATS_PACKER_LOADING, true);

      let dates = ['from', 'to'].map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
      let ids = params.ids.map((item, index) => `ids[${index}]=${encodeURIComponent(item)}`).join('&');

      const response = await $http.get(`statistics/administrator/parcels?${dates}&${ids}`);
      commit(STATS_PACKER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(STATS_PACKER_LOADING, false);
      commit(STATS_PACKER_FETCHED, true);
    }
  },
};

const mutations = {
  [STATS_ORDER_DATA](state, data) {
    state.stats.order.data = data;
  },
  [STATS_ORDER_LOADING](state, flag) {
    state.stats.order.isLoading = flag;
  },
  [STATS_ORDER_FETCHED](state, flag) {
    state.stats.order.isFetched = flag;
  },

  [STATS_DELIVERY_DATA](state, data) {
    state.stats.delivery.data = data;
  },
  [STATS_DELIVERY_LOADING](state, flag) {
    state.stats.delivery.isLoading = flag;
  },
  [STATS_DELIVERY_FETCHED](state, flag) {
    state.stats.delivery.isFetched = flag;
  },

  [STATS_PRODUCT_OVER_DATA](state, data) {
    state.stats.productOver.data = data;
  },
  [STATS_PRODUCT_OVER_LOADING](state, flag) {
    state.stats.productOver.isLoading = flag;
  },
  [STATS_PRODUCT_OVER_FETCHED](state, flag) {
    state.stats.productOver.isFetched = flag;
  },

  [STATS_PRODUCT_PAY_DATA](state, data) {
    state.stats.productPay.data = data;
  },
  [STATS_PRODUCT_PAY_LOADING](state, flag) {
    state.stats.productPay.isLoading = flag;
  },
  [STATS_PRODUCT_PAY_FETCHED](state, flag) {
    state.stats.productPay.isFetched = flag;
  },

  [STATS_SUPPLY_ORDER_DATA](state, data) {
    state.stats.supplyOrder.data = data;
  },
  [STATS_SUPPLY_ORDER_LOADING](state, flag) {
    state.stats.supplyOrder.isLoading = flag;
  },
  [STATS_SUPPLY_ORDER_FETCHED](state, flag) {
    state.stats.supplyOrder.isFetched = flag;
  },

  [STATS_PACKER_LIST](state, list) {
    state.stats.packers.list = list;
  },
  [STATS_PACKER_LIST_LOADING](state, flag) {
    state.stats.packer.isLoading = flag;
  },
  [STATS_PACKER_DATA](state, unformattedData) {
    let decoratedData = [];
    if (unformattedData.length) {
      let dates = uniqBy(unformattedData, 'date');
      decoratedData = uniqBy(unformattedData, item => {
        return item.users[0].id
      }).map(item => {
        return {
          id: item.users[0].id,
          dates: [],
        }
      });
      decoratedData.forEach(user => {
        unformattedData.forEach(item => {
          if (user.id === item.users[0].id) {
            user.dates.push({
              date: item.date,
              count: item.users[0].count
            })
          }
        })
        if (user.dates.length < dates.length) {
          dates.forEach(dateItem => {
            if (!find(user.dates, function(item) {return item.date === dateItem.date})) {
              user.dates.push({
                date: dateItem.date,
                count: 0
              })
            }
          })
        }
      })
    }
    state.stats.packer.data = decoratedData;
  },
  [STATS_PACKER_LOADING](state, flag) {
    state.stats.packer.isLoading = flag;
  },
  [STATS_PACKER_FETCHED](state, flag) {
    state.stats.packer.isFetched = flag;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
