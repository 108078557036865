import {mapGetters} from "vuex";

export default {
  name: 'paginatorRequest',
  //---components
  components: {},
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
  },
  //---data
  data () {
    return {
      currentPageId: 1,
    }
  },
  created() {},
  //---hooks
  computed: {
    pageCount () {
      return Math.ceil(this.list.length/this.perPage)
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.currentPageId = val;
          this.changeCurrentPage();
        }
      }
    },
    list (val) {
      if (val) {
        this.changeCurrentPage();
      }
    }
  },
  //---methods
  methods: {
    changeCurrentPage(){
      let pageArray = this.list.slice((this.currentPageId - 1) * this.perPage, this.currentPageId * this.perPage);
      if (pageArray.length === 0 && this.currentPageId > 1) {
        this.currentPageId--;
        pageArray = this.list.slice((this.currentPageId - 1) * this.perPage, this.currentPageId * this.perPage);
      }
      this.$emit('page', pageArray, this.currentPageId)
    }
  }
}