import {$http} from '@/utils/http'

import {
  BOX_DATA,
  BOX_DATA_UPDATING,
} from '../mutation-types';

import {
  BOX_REQUEST_UPDATE,
} from '../action-types';

const state = {
  box: {
    data: null,
    isUpdating: false
  }
};

const getters = {
  box: state => state.box.data,
  isBoxUpdating: state => state.box.isUpdating
};

const actions = {
  [BOX_REQUEST_UPDATE]: async ({commit}, {boxId, payload}) => {
    try {
      commit(BOX_DATA_UPDATING, true);
      let include = 'place,administrator,status,order,box_type,box_product,packing_requests,shelf,rack,store,product';
      const response = await $http.put(`boxes/${boxId}?include=${include}`, payload);
      commit(BOX_DATA, response.data.data);
      commit('parcels/PARCELS_BOX_UPDATE_DATA', response.data.data, { root: true })
      return response
    } catch (e) {
      throw e;
    } finally {
      commit(BOX_DATA_UPDATING, false);
    }
  },
};

const mutations = {
  [BOX_DATA](state, data) {
    state.box.data = data;
  },
  [BOX_DATA_UPDATING](state, flag) {
    state.box.isUpdating = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
