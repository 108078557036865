export default {
  toMain: 'Main',
  toEnter: 'Login',
  toExit: 'Logout',
  //---auth
  authorization: 'Authorization',
  successAuth: 'Login successful',
  resetPassword: 'Password recovery',
  successMessageAfterResetPassword: 'Restore link has been sent to the specified email',
  restorePassword: 'Change Password',
  restorePasswordNote: 'you can change your password to enter your personal account',
  successMessageAfterRestorePassword: 'Password changed successfully',
  successInviteAuthTitle: 'You are successfully logged',
  successInviteAuthText: 'Set a password for further work',
  successInvitePasswordTitle: 'Password set successfully',
  failInviteAuthTitle: 'Authorization unsuccessful',
  failInviteQueryText: 'Use the link in the letter',
  failInviteAuthText: 'Contact the administrator',
  forgotPassword: 'Forgot your password?',
  //---dashboard nav
  administration: 'Administration',
  workers: 'Users',
  roles: 'Roles',
  permissions: 'Permissions',
  logs: 'Logs',
  type: 'Type',
  order: 'Order',
  orders: 'Orders',
  products: 'Products',
  client: 'Client',
  recipient: 'Recipient',
  suppliersRequests: 'Orders to suppliers',
  parcels: 'Parcels',
  statistics: 'Statistics',
  settings: 'Settings',
  leftoverType: 'Minimum stock',
  accessDenied: 'Authorization required',
  //---workers
  worker: 'User',
  workerListInfo: 'Phone/E-mail',
  workerDeleted: 'User deleted',
  workerCreated: 'User created',
  workerUpdated: 'User updated',
  workerCreateTitle: 'Create user',
  workerUpdateTitle: 'Edit user',
  workerSendInviteTitle: 'Invite user',
  workerSendInviteText: 'Send invite on email',
  workerInviteSendSuccess: 'Invitation sent',
  //---permissions
  permissionShowTitle: 'View data',
  //---roles
  rolesTitle: 'Title',
  rolesDescription: 'Description',
  rolesPermissions: 'Permissions',
  rolesCreateTitle: 'Create role',
  rolesUpdateTitle: 'Edit role',
  rolesDeleted: 'Role deleted',
  rolesNotFoundText: 'Not found roles, please create a new',
  rolesNotFoundLink: 'role',
  //---settings
  settingUpdateTitle: 'Edit data',
  //---stores
  storeDeleted: 'Store deleted',
  racksCount: 'Number of racks',
  storeCreateTitle: 'Add store',
  storeUpdateTitle: 'Редактировать склад',
  //---racks
  rackDeleted: 'Rack deleted',
  shelfCount: 'Number of shelves',
  rackCreateTitle: 'Create racks',
  rackCreateSuccessMessage: 'Racks created',
  rackUpdateTitle: 'Edit rack',
  //---shelves
  shelfDeleted: 'Shelf deleted',
  placeCount: 'Number of places',
  shelfCreateTitle: 'Create shelves',
  shelfCreateSuccessMessage: 'Shelves created',
  shelfUpdateTitle: 'Edit shelf',
  //---places
  placeDeleted: 'Place deleted',
  placeCreateTitle: 'Create places',
  placeCreateSuccessMessage: 'Places created',
  placeUpdateTitle: 'Edit Place',
  placeEmpty: 'Empty place',
  //---orders
  orderPrice: 'Order amount',
  orderDate: 'Order date',
  orderPaymentStatus: 'Payment Status',
  orderAvailabilityStatus: 'Availability Status',
  orderAll: 'All orders',
  orderAvailabilityReady: 'Has all products',
  orderAvailabilityNeedOrder: 'Not enough products',
  orderAwaiting: 'Awaiting delivery',
  orderAwaitingPayment: 'Awaiting payment',
  orderPaid: 'Paid',
  orderNumber: 'Order No.',
  orderShopNumber: 'Order Shop No.',
  orderShopNumberShort: 'Shop No.',
  orderProductReserved: 'Reserved',
  orderProductEmpty: 'Not available',
  orderProductOrdered: 'Ordered',
  orderProductReady: 'Ready',
  orderProductAmount: 'Product amount',
  orderConfirmSuccessMessage: 'Order is processed',
  orderCreateParcelsSuccessMessage: 'Parcels for order have been created',
  orderDeleted: 'Order deleted',
  orderParcelAmount: 'Parcel amount',
  orderSaveAddress: 'Save address',
  orderSaveProducts: 'Save products list',
  orderEditDeliveryCode: 'Set delivery code',
  orderDeliveryCodeSuccessMessage: 'Delivery code updated',
  //---supplyOrders
  supplierRequest: 'Requests',
  supplyOrderConfirmSuccessMessage: 'Vendor orders created',
  supplyOrderArchivedSuccessMessage: 'Order archived',
  supplyOrderCreate: 'Create order',
  supplyOrderArrange: 'Arrange order',
  placeProducts: 'Place products',
  //---products
  product: 'Product',
  productsAll: 'All products',
  productsLeftovered: 'Ending',
  productsOvered: 'Overed',
  productsConsignmentsExpirating: 'By expirating',
  productsConsignmentsExpirated: 'Expirated',
  productSupplyTitle: 'Order product from the vendor',
  productSupplyTitleNotFoundText: 'Аdd vendor for current',
  productSupplyTitleNotFoundLink: 'product',
  productSupplyAddSuccessMessage: 'Product added to order',
  productSupplyDeleteSuccessMessage: 'Product deleted from order',
  productVendorAddTitle: 'Add vendor',
  productVendorAddSuccessMessage: 'Vendor added',
  productVendorEditTitle: 'Change vendor price',
  productVendorDeleteSuccessMessage: 'Vendor deleted',
  productVendorDefault: 'By default',
  productLeftOverTypeUndefined: 'Choose leftover type',
  productLeftOverTypeEditTitle: 'Change minimum stock',
  productStatusEditTitle: 'Change product status',
  productOvering: 'Product overing',
  productOvered: 'Product overed',
  productDeletedInStore: 'Product deleted in store',
  productStatus: 'Product status',
  productStatusNew: 'Do not display in store',
  productStatusVerified: 'Display in store',
  productStatusSale: 'Disable supply order',
  productDeleteTitle: 'Delete product',
  productDeleteText: 'Product will be deleted',
  productDeleteSuccessMessage: 'Product deleted',
  productEmptyVendorTitle: 'No vendors поставщиков',
  productEmptyVendorText: 'Add vendors for the following products',
  productUnavailableTitle: 'Unavailable products',
  productUnavailableText: 'Unable to collect the parcel due to shortage of the following products in store',
  //---consignments
  consignmentDeleteSuccessMessage: 'Consignment deleted',
  //---leftoverTypes
  leftoverTypes: 'Minimum stock',
  leftoverTypesCreateTitle: 'Create minimum stock',
  leftoverTypesUpdateTitle: 'Edit minimum stock',
  leftoverTypesDeleted: 'Minimum stock removed',
  //---box
  boxEditType: 'Choose edit type',
  boxReplace: 'Replace boxes',
  boxAdd: 'Add boxes',
  boxDelete: 'Delete boxes',
  //---boxTypes
  boxTypes: 'Pack type',
  boxTypesCreateTitle: 'Create pack',
  boxTypesUpdateTitle: 'Edit pack',
  boxTypesDeleted: 'Pack deleted',
  //---parcel
  parcelGetInWork: 'Parcel get in work',
  parcelCollected: 'Parcel collected',
  parcelSent: 'Parcel sent',
  parcelDeliveryCodeTitle: 'Enter delivery code',
  parcelDeliveryCodeMessage: 'Boxes delivery codes are updated',
  parcel: 'Parcel',
  parcelBoxCount: 'Box count',
  parcelProductCount: 'Product count',
  parcelBoxesPlaceBtn: 'Place on store',
  parcelBoxesPlaceTitle: 'Place boxes',
  parcelBoxesPlaceMessageTitle: 'Box placed',
  parcelBoxesPlaceMessageBtn: 'Place another',
  parcelPicker: 'Packer',
  parcelProductAdded: 'Added in box',
  parcelBoxType: 'Box type',
  parcelBoxes: 'Boxes',
  parcelProduct: 'Product',
  parcelBoxCollected: 'Box collected',
  parcelBoxNotCollected: 'Waiting collect',
  parcelOrderNum: 'Order №',
  parcelOrderDate: 'Order date',
  parcelSentDate: 'Sent date',
  parcelNum: 'Parcel №',
  parcelAmount: 'Parcel price',
  parcelDeliveryAmount: 'Delivery price',
  parcelDelivery: 'Delivery',
  parcelStatusSent: 'Sent',
  parcelStatusReady: 'To send',
  parcelBarcodeLabel: 'Scan boxes of the parcel № ',
  parcelSomeBoxesNoDeliveryCode: 'Some boxes has no delivery code',
  parcelSomeBoxesNoPlace: 'Some boxes not placed',
  //---vendors
  vendorsDeleted: 'Vendor deleted',
  vendorsCreated: 'Vendor created',
  vendorsUpdated: 'Vendor updated',
  vendorsCreateTitle: 'Create vendor',
  vendorsUpdateTitle: 'Edit vendor',
  //---barcode
  barcodeScan: 'Scan',
  barcodeNotRecognized: 'Barcode Not Recognized',
  barcodeRecognized: 'Barcode Recognized',
  barcodeInvalidType: 'Invalid barcode type',
  barcodeProductLabel: 'Scan the product',
  barcodeBoxLabel: 'Scan the box',
  barcodePlace: 'Scan the place',
  barcodeNewPlace: 'New Place',
  barcodeProductNotFoundInSystem: 'Product not found in system',
  barcodeProductNotFoundInOrder: 'Product not found in order',
  barcodeProductFoundInOrder: 'Product found in order',
  barcodePlaceAlready: 'Product already place success',
  barcodePlaced: 'Product placed success',
  barcodeAllProductsPlaced: 'All products are placed',
  barcodePlaceCancel: 'Product place cancel',
  barcodePlaceNotMatched: 'Product place not matched',
  barcodePlaceNotFound: 'Place not found',
  barcodeBoxPlaceNotFoundInParcel: 'There are no boxes from the parcel on place',
  barcodeBoxNotFoundInParcel: 'Box not found in parcel',
  barcodeBoxFoundInParcel: 'Box found in parcel',
  barcodeAllBoxesPlaced: 'All boxes are placed',
  barcodeAllParcelSent: 'All parcels are sent',
  barcodeBoxPlace: 'Place barcode',
  barcodeBoxParcel: 'Box barcode',
  //---parcelCollect
  parcelCollectTitle: 'Collect parcel',
  parcelCollectTemplateTitle: 'Pack storage',
  parcelCollectScanPlace: 'Scan place',
  parcelCollectScanProduct: 'Scan pack',
  parcelCollectConfirmProduct: 'Confirm pack',
  parcelCollectBarcodeNotMatched: 'Barcode not matched',
  parcelCollectPackInvalid: 'Invalid pack',
  parcelCollectPlaceBarcode: 'Place barcode',
  parcelCollectProductBarcode: 'Product barcode',
  parcelCollectProductInvalid: 'Invalid product',
  parcelCollectPlaceInvalid: 'Invalid place for product',
  parcelCollectProductAdded: 'Product added',
  parcelCollectBox: 'Box collected',
  //---delivery
  deliveryGeneral: 'General settings',
  deliveryEmptyWeights: 'Add weight',
  deliveryEmptyCountryGroup: 'Add a group of countries',
  deliveryEmptyDeliveryRegions: 'Add delivery regions',
  //---fuelSurcharge
  fuelSurcharge: 'Fuel surcharge',
  fuelSurchargeUpdateTitle: 'Edit fuel surcharge',
  //base-rate
  baseRate: 'Base rate',
  baseRateCreateTitle: 'Create base rate',
  baseRateEditTitle: 'Edit base rate',
  baseRateCreateSuccessMessage: 'Base rate created',
  baseRateDeleteSuccessMessage: 'Base rate deleted',
  //---weights
  weights: 'Weights',
  weightsCreateTitle: 'Create weight',
  weightsUpdateTitle: 'Edit weight',
  weightsDeleted: 'Weight deleted',
  //delivery-groups
  deliveryGroups: 'Delivery groups',
  //country-groups
  countryGroups: 'Country groups',
  countryGroupsCreateTitle: 'Create country group',
  countryGroupsUpdateTitle: 'Edit country group',
  countryGroupsDeleted: 'Country group deleted',
  //countries
  country: 'Country',
  countries: 'Countries',
  countryCode: 'Country code',
  countryCreateTitle: 'Create country',
  countryUpdateTitle: 'Edit country',
  countryDeleted: 'Country deleted',
  //delivery-regions
  deliveryRegions: 'Delivery regions',
  deliveryRegionsCreateTitle: 'Create delivery region',
  deliveryRegionsUpdateTitle: 'Edit delivery region',
  deliveryRegionsDeleted: 'Delivery region deleted',
  //notifications
  notifications: 'Notifications',
  notificationsCreateTitle: 'Create notification',
  notificationsUpdateTitle: 'Edit notification',
  notificationsCreated: 'Notification created',
  notificationsDeleted: 'Notification deleted',
  notificationsConfirmed: 'Notification confirmed',
  notificationsFullList: 'All notifications',
  //---stats
  statsProductOver: 'Decommissioned products',
  statsProductBuy: 'Paid orders amount',
  //---btns
  btnSend: 'Send',
  btnBackToMain: 'Back to main',
  btnBackToList: 'Back to list',
  btnBack: 'Back',
  btnRoleCreate: 'Create role',
  btnWorkerCreate: 'Create user',
  btnVendorCreate: 'Create vendor',
  btnStoreCreate: 'Create store',
  btnRacksCreate: 'Create racks',
  btnShelvesCreate: 'Create shelves',
  btnPlacesCreate: 'Create places',
  btnCreate: 'Create',
  btnSave: 'Save',
  btnUpdate: 'Update',
  btnArrange: 'Order products',
  btnOrder: 'Order',
  btnAdd: 'Add',
  btnCancel: 'Cancel',
  btnClose: 'Закрыть',
  btnDelete: 'Delete',
  btnCollectOrder: 'Collect order',
  btnSendToArchive: 'Send to archive',
  btnCollectParcel: 'Collect parcel',
  btnShowParcel: 'Show parcel',
  btnSentParcels: 'Sent parcels',
  btnPlace: 'Place',
  btnEdit: 'Edit',
  btnAddProduct: 'Add product',
  btnMore: 'Load more',
  btnPrint: 'Print',
  //---system
  noData: 'No data',
  dataUpdated: 'Data updated',
  pageNotFound: 'Page Not Found',
  serverNotFound: 'Server Error',
  priceSymbol: '¥',
  weightSymbol: 'gr',
  sizeSymbol: 'cm',
  sizeSymbolSmall: 'mm',
  //---simple
  fullName: 'Name',
  name: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  passwordConfirmation: 'Confirm password',
  email: 'Email',
  phone: 'Phone',
  value: 'Value',
  prefix: 'Prefix',
  title: 'Title',
  description: 'Description',
  from: 'From',
  to: 'To',
  date: 'Date',
  dueDate: 'Due date',
  status: 'Status',
  count: 'Count',
  expectedCount: 'Expected count',
  productsCount: 'Product count',
  packedCount: 'Packed count',
  orderCount: 'Count for order',
  orderAvailabilityCount: 'Available for order',
  availability: 'Availability',
  amount: 'Amount',
  price: 'Price',
  vendor: 'Vendor',
  vendors: 'Vendors',
  new: 'New',
  collecting: 'Collecting',
  awaiting: 'Awaiting',
  finished: 'Finished',
  ready: 'Ready',
  readyMany: 'Ready',
  sentMany: 'Sent',
  inProgress: 'In progress',
  administrator: 'Administrator',
  dataChanged: 'Data changed',
  characteristics: 'Characteristics',
  consignments: 'Consignments',
  categories: 'Categories',
  priority: 'Minimum stock',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  weight: 'Weight',
  capacity: 'Capacity',
  thickness: 'Thickness',
  place: 'Place',
  places: 'Places',
  shelf: 'Shelf',
  shelves: 'Shelves',
  rack: 'Rack',
  racks: 'Racks',
  store: 'Store',
  stores: 'Stores',
  storage: 'Storage',
  placement: 'Placement',
  onStoreCount: 'On store',
  reservedCount: 'Reserved',
  address: 'Address',
  search: 'Search',
  delivered: 'Delivered',
  delivery: 'Delivery',
  all: 'All',
  yes: 'Yes',
  no: 'No',
  percent: 'Percent',
  priceRange: 'Price (min - max)',
  packType: 'Pack type',
  deliveryCode: 'Delivery code',
  showInStore: 'Show in the store',
  expirationDate: 'Expiration date',
  expirationLeft: 'Expiration left',
  expirationMessageLeft: 'Days to expiration:',
  expirationMessageOver: 'Expired',
  addressesCSV: 'Addresses',
  productsCSV: 'Products',
  changeStorage: 'Change storage',
  diffExpirationDate: `Сan't move the product. Expiration dates do not match`,
  placeStorage: 'Place product',
  //---tooltip
  delete: 'Delete',
  sendInvite: 'Send Invite',
  edit: 'Edit',
  getBarcode: 'Get barcode',
  show: 'Show',
  orderProduct: 'Order product',
  confirm: 'Confirm',
  showParcel: 'Show parcel',
  getInWork: 'Get in work',
  add: 'Add',
  update: 'Update',
  box: 'Box',
  //---validation
  validationRequired: 'Required field is empty',
  validationLogin: 'Incorrect email or password',
  validationEmail: 'Incorrect email',
  validationSameAs: 'Fields don\'t match',
  validationMin: 'Min count "{count}"',
  validationMax: 'Max count "{count}"',
  validationPhone: 'Incorrect phone',
  validationDate: 'Incorrect date',
  validationNumeric: 'Enter numeric',
  validationMinValue: 'Min value "{count}"',
  validationMaxValue: 'Max value "{count}"',
  validationInteger: 'Enter integer',
  validationDecimal: 'Enter decimal',
  validationCurrency: 'Enter currency',
  validationAlpha: 'Enter letters',
}