import {$http} from '@/utils/http'

import {
  BASE_RATE_CHANGE_LOADING
} from '../mutation-types';

import {
  BASE_RATE_REQUEST_CREATE,
  BASE_RATE_REQUEST_UPDATE,
  BASE_RATE_REQUEST_DELETE,
} from '../action-types';

const state = {
  isBaseRateChangeLoading: false
};

const getters = {
  isBaseRateChangeLoading: state => state.isBaseRateChangeLoading,
};

const actions = {
  [BASE_RATE_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(BASE_RATE_CHANGE_LOADING, true);
      let include = 'country_group,weight,delivery_method';
      return await $http.post(`base_rates?include=${include}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(BASE_RATE_CHANGE_LOADING, false);
    }
  },

  [BASE_RATE_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(BASE_RATE_CHANGE_LOADING, true);
      let include = 'country_group,weight,delivery_method';
      return await $http.put(`base_rates/${data.base_rate_id}?include=${include}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(BASE_RATE_CHANGE_LOADING, false);
    }
  },

  [BASE_RATE_REQUEST_DELETE]: async ({commit}, base_rate_id) => {
    try {
      commit(BASE_RATE_CHANGE_LOADING, true);
      return await $http.delete(`base_rates/${base_rate_id}`);
    } catch (e) {
      throw e;
    } finally {
      commit(BASE_RATE_CHANGE_LOADING, false);
    }
  },
};

const mutations = {
  [BASE_RATE_CHANGE_LOADING](state, flag) {
    state.isBaseRateChangeLoading = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
