import {$http} from '@/utils/http'

import {
  PACK_TYPES_LIST,
  PACK_TYPES_LIST_LOADING,
} from '../mutation-types';

import {
  PACK_TYPES_REQUEST_LIST_GET,
} from '../action-types';

const state = {
  packTypes: {
    list: null,
    isLoading: false,
  },
};

const getters = {
  packTypes: state => state.packTypes.list,
  isPackTypesLoading: state => state.packTypes.isLoading,
};

const actions = {
  [PACK_TYPES_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(PACK_TYPES_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`pack_types?include=${include}`);
      commit(PACK_TYPES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(PACK_TYPES_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [PACK_TYPES_LIST](state, data) {
    state.packTypes.list = data;
  },
  [PACK_TYPES_LIST_LOADING](state, flag) {
    state.packTypes.isLoading = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
