import {$http} from '@/utils/http'
import {
  VENDORS_LIST,
  VENDORS_LIST_LOADING,
  VENDORS_LIST_PAGE,
  VENDORS_LIST_PAGE_LENGTH,
  VENDORS_ALL_LIST,
  VENDORS_ALL_LIST_LOADING,
  VENDORS_ALL_LIST_DELETE,
  VENDORS_PRODUCT_LIST,
  VENDORS_PRODUCT_LIST_LOADING,
  VENDORS_DATA,
  VENDORS_DATA_LOADING,
  VENDORS_DATA_UPDATE_LOADING,
  VENDORS_DATA_CREATE_LOADING,
} from '../mutation-types';

import {
  VENDORS_REQUEST_LIST_GET,
  VENDORS_REQUEST_LIST_ALL_GET,
  VENDORS_REQUEST_PRODUCT_LIST_GET,
  VENDORS_REQUEST_DATA_GET,
  VENDORS_REQUEST_UPDATE,
  VENDORS_REQUEST_CREATE,
  VENDORS_REQUEST_DELETE,
} from '../action-types';
import {findIndex} from "lodash";

const state = {
  vendors: {
    list: null,
    isLoading: false,
    page: 1,
    per_page: 10,
    pageLength: 1,
  },
  vendorsAll: {
    list: null,
    isLoading: false,
  },
  productVendors: {
    list: null,
    isLoading: false,
  },
  vendor: {
    data: null,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
  },
};

const getters = {
  vendorsAll: state => state.vendorsAll.list,
  isVendorsAllLoading: state => state.vendorsAll.isLoading,
  vendors: state => state.vendors.list,
  isVendorsLoading: state => state.vendors.isLoading,
  vendorsPage: state => state.vendors.page,
  vendorsPageLength: state => state.vendors.pageLength,

  vendor: state => state.vendor.data,
  isVendorLoading: state => state.vendor.isLoading,
  isVendorUpdating: state => state.vendor.isUpdating,
  isVendorCreating: state => state.vendor.isCreating,
};

const actions = {
  [VENDORS_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(VENDORS_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`vendors?include=${include}&per_page=${state.vendors.per_page}&page=${state.vendors.page}`);
      commit(VENDORS_LIST, response.data.data);
      commit(VENDORS_LIST_PAGE_LENGTH, response.data.meta.last_page);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_LIST_LOADING, false);
    }
  },

  [VENDORS_REQUEST_LIST_ALL_GET]: async ({commit}) => {
    try {
      commit(VENDORS_ALL_LIST_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`vendors?include=${include}`);
      commit(VENDORS_ALL_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_ALL_LIST_LOADING, false);
    }
  },

  [VENDORS_REQUEST_PRODUCT_LIST_GET]: async ({commit}, productId) => {
    try {
      commit(VENDORS_PRODUCT_LIST_LOADING, true);
      const response = await $http.get(`products/${productId}/vendors`);
      commit(VENDORS_PRODUCT_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_PRODUCT_LIST_LOADING, false);
    }
  },

  [VENDORS_REQUEST_DATA_GET]: async ({commit}, vendorId) => {
    try {
      commit(VENDORS_DATA_LOADING, true);
      let include = 'translations';
      const response = await $http.get(`vendors/${vendorId}?include=${include}`);
      commit(VENDORS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_DATA_LOADING, false);
    }
  },

  [VENDORS_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(VENDORS_DATA_UPDATE_LOADING, true);
      let include = 'translations';
      return await $http.put(`vendors/${data.vendorId}?include=${include}`, data.payload);
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_DATA_UPDATE_LOADING, false);
    }
  },

  [VENDORS_REQUEST_CREATE]: async ({commit}, payload) => {
    try {
      commit(VENDORS_DATA_CREATE_LOADING, true);
      let include = 'translations';
      return await $http.post(`vendors?include=${include}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(VENDORS_DATA_CREATE_LOADING, false);
    }
  },

  [VENDORS_REQUEST_DELETE]: async ({commit}, vendorId) => {
    try {
      const response = await $http.delete(`vendors/${vendorId}`);
      commit(VENDORS_ALL_LIST_DELETE, vendorId);
      return response
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [VENDORS_LIST](state, data) {
    state.vendors.list = data;
  },
  [VENDORS_LIST_LOADING](state, flag) {
    state.vendors.isLoading = flag;
  },

  [VENDORS_ALL_LIST](state, data) {
    state.vendorsAll.list = data;
  },
  [VENDORS_ALL_LIST_LOADING](state, flag) {
    state.vendorsAll.isLoading = flag;
  },
  [VENDORS_ALL_LIST_DELETE](state, id) {
    let index = findIndex(state.vendorsAll.list, item => {return item.id === id;});
    state.vendorsAll.list.splice(index, 1);
  },

  [VENDORS_PRODUCT_LIST](state, data) {
    state.productVendors.list = data;
  },
  [VENDORS_PRODUCT_LIST_LOADING](state, flag) {
    state.productVendors.isLoading = flag;
  },

  [VENDORS_LIST_PAGE](state, number) {
    state.vendors.page = parseInt(number);
  },
  [VENDORS_LIST_PAGE_LENGTH](state, count) {
    state.vendors.pageLength = count;
  },

  [VENDORS_DATA](state, data) {
    state.vendor.data = data;
  },
  [VENDORS_DATA_LOADING](state, flag) {
    state.vendor.isLoading = flag;
  },
  [VENDORS_DATA_UPDATE_LOADING](state, flag) {
    state.vendor.isUpdating = flag;
  },
  [VENDORS_DATA_CREATE_LOADING](state, flag) {
    state.vendor.isCreating = flag;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
