import {$http} from '@/utils/http'

import {isEmpty} from "lodash";
import {objectToEncodedQueryString} from "@/utils/utils";

import {
  RACKS_LIST,
  RACKS_DATA,
  RACKS_DATA_LOADING,
  RACKS_DATA_UPDATE_LOADING,
  RACKS_CREATE_LOADING,
  RACKS_CREATE_LIST,
} from '../mutation-types';

import {
  RACKS_REQUEST_LIST_GET,
  RACKS_REQUEST_LIST_CREATE,
  RACKS_REQUEST_DATA_GET,
  RACKS_REQUEST_UPDATE,
  RACKS_REQUEST_DELETE
} from '../action-types';

const state = {
  racks: {
    list: null,
    isCreating: false,
    skip: 10,
  },
  rack: {
    data: null,
    isLoading: false,
    isUpdating: false,
  },
  racksCreate: {
    isLoading: false,
    list: null
  }
};

const getters = {
  racks: state => state.racks.list,
  racksSkip: state => state.racks.skip,
  rack: state => state.rack.data,
  isRackLoading: state => state.rack.isLoading,
  isRackUpdating: state => state.rack.isUpdating,
  isRacksCreateLoading: state => state.racksCreate.isLoading,
  racksCreated: state => state.racksCreate.list
};

const actions = {
  [RACKS_REQUEST_LIST_GET]: async ({commit}, params) => {
    try {
      const query = params && !isEmpty(params) ? `&${objectToEncodedQueryString(params)}` : '';

      let include = 'translations,child_count,store';
      const response = await $http.get(`racks?include=${include}${query}`);
      commit(RACKS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    }
  },

  [RACKS_REQUEST_DATA_GET]: async ({commit}, rackId) => {
    try {
      commit(RACKS_DATA_LOADING, true);
      let include = 'translations,child_count,store';
      const response = await $http.get(`racks/${rackId}?include=${include}`);
      commit(RACKS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(RACKS_DATA_LOADING, false);
    }
  },

  [RACKS_REQUEST_UPDATE]: async ({commit}, data) => {
    try {
      commit(RACKS_DATA_UPDATE_LOADING, true);
      let include = 'translations,child_count,store';
      const response = await $http.put(`racks/${data.rackId}?include=${include}`, data.payload);
      commit(RACKS_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(RACKS_DATA_UPDATE_LOADING, false);
    }
  },

  [RACKS_REQUEST_LIST_CREATE]: async ({commit}, payload) => {
    try {
      commit(RACKS_CREATE_LOADING, true);
      let include = 'translations,child_count,store';
      const response = await $http.post(`racks?include=${include}`, payload);
      commit(RACKS_CREATE_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(RACKS_CREATE_LOADING, false);
    }
  },

  [RACKS_REQUEST_DELETE]: async ({commit}, rackId) => {
    try {
      return await $http.delete(`racks/${rackId}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [RACKS_LIST](state, data) {
    state.racks.list = data;
  },
  [RACKS_DATA](state, data) {
    state.rack.data = data;
  },
  [RACKS_DATA_LOADING](state, flag) {
    state.rack.isLoading = flag;
  },
  [RACKS_DATA_UPDATE_LOADING](state, flag) {
    state.rack.isUpdating = flag;
  },
  [RACKS_CREATE_LOADING](state, flag) {
    state.racksCreate.isLoading = flag;
  },
  [RACKS_CREATE_LIST](state, list) {
    state.racksCreate.list = list;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
