import {$http} from '@/utils/http'

import {
  FUEL_SURCHARGE_LIST,
  FUEL_SURCHARGE_LIST_LOADING,

  FUEL_SURCHARGE_DATA,
  FUEL_SURCHARGE_DATA_LOADING,

  FUEL_SURCHARGE_DATA_UPDATE_LOADING,
} from '../mutation-types';

import {
  FUEL_SURCHARGE_REQUEST_LIST_GET,
  FUEL_SURCHARGE_REQUEST_DATA_GET,
  FUEL_SURCHARGE_REQUEST_UPDATE,
} from '../action-types';

const state = {
  fuelSurchargeList: {
    list: null,
    isLoading: false,
  },
  fuelSurcharge: {
    data: null,
    isLoading: false,
    isUpdating: false,
  }
};

const getters = {
  fuelSurchargeList: state => state.fuelSurchargeList.list,
  isFuelSurchargeListLoading: state => state.fuelSurchargeList.isLoading,

  fuelSurcharge: state => state.fuelSurcharge.data,
  isFuelSurchargeLoading: state => state.fuelSurcharge.isLoading,
  isFuelSurchargeUpdating: state => state.fuelSurcharge.isUpdating,
};

const actions = {
  [FUEL_SURCHARGE_REQUEST_LIST_GET]: async ({commit}) => {
    try {
      commit(FUEL_SURCHARGE_LIST_LOADING, true);
      const response = await $http.get(`fuel_surcharges`);
      commit(FUEL_SURCHARGE_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(FUEL_SURCHARGE_LIST_LOADING, false);
    }
  },

  [FUEL_SURCHARGE_REQUEST_DATA_GET]: async ({commit}, fuelSurchargeId) => {
    try {
      commit(FUEL_SURCHARGE_DATA_LOADING, true);
      const response = await $http.get(`fuel_surcharges/${fuelSurchargeId}`);
      commit(FUEL_SURCHARGE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(FUEL_SURCHARGE_DATA_LOADING, false);
    }
  },

  [FUEL_SURCHARGE_REQUEST_UPDATE]: async ({commit}, {fuelSurchargeId, payload}) => {
    try {
      commit(FUEL_SURCHARGE_DATA_UPDATE_LOADING, true);
      const response = await $http.put(`fuel_surcharges/${fuelSurchargeId}`, payload);
      commit(FUEL_SURCHARGE_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(FUEL_SURCHARGE_DATA_UPDATE_LOADING, false);
    }
  },
};

const mutations = {
  [FUEL_SURCHARGE_LIST](state, data) {
    state.fuelSurchargeList.list = data;
  },
  [FUEL_SURCHARGE_LIST_LOADING](state, flag) {
    state.fuelSurchargeList.isLoading = flag;
  },

  [FUEL_SURCHARGE_DATA](state, data) {
    state.fuelSurcharge.data = data;
  },
  [FUEL_SURCHARGE_DATA_LOADING](state, flag) {
    state.fuelSurcharge.isLoading = flag;
  },
  [FUEL_SURCHARGE_DATA_UPDATE_LOADING](state, flag) {
    state.fuelSurcharge.isUpdating = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
